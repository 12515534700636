import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    display: 'flex',
    alignItem: 'center',
    gap: '1rem',

    max768: {
        marginTop: '1.5rem',
    },

    min768: {
        position: 'absolute',
        top: '2rem',
        right: '6.5rem',
    },
});
