import { useEffect } from 'react';
import type { ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { config } from 'config/config';
import { IS_DEMO } from 'constants/index';

import { actions as messagesActions } from 'modules/messages';

export const NonDemoFirewall = ({ children }: { children: ReactNode }) => {
    const dispatch = useDispatch();
    const intl = useIntl();

    useEffect(() => {
        if (IS_DEMO) {
            dispatch(
                messagesActions.displayWarningMessage({
                    message: intl.formatMessage({ id: 'demo.featureNotAvailable' }),
                }),
            );
        }
    }, [intl, dispatch]);

    if (IS_DEMO) {
        return <Redirect to={config.routes.home} />;
    }

    return <>{children}</>;
};
