import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'center',
});

export const text: TRuleWithTheme = () => ({
    marginRight: '0.75rem',

    overflow: 'auto',

    whiteSpace: 'nowrap',

    textDecoration: 'underline',
    textOverflow: 'ellipsis',
});
