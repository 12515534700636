import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    position: 'relative',

    paddingTop: '0.75rem',
    paddingRight: '1rem',
    paddingBottom: '1rem',
    paddingLeft: '1rem',

    marginBottom: '1.5rem',

    borderWidth: '0.0625rem',
    borderStyle: 'solid',
    borderColor: theme.colors.lightGrey3,
    borderRadius: '0.25rem',
});

export const options: TRuleWithTheme = () => ({
    display: 'flex',
    justifyContent: 'space-between',
});
