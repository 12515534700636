import type { Secret } from '../types';

export const updatedAtLookup = (secret: Secret) => secret.passwordUpdatedAt ?? secret.updatedAt ?? secret.createdAt;

export const isSecretShareable = (share: Secret['share']) =>
    Boolean(share?.until && new Date(share.until) >= new Date());

export * from './files';
export * from './validate';
export * from './guards';
export * from './search';
