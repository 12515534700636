import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ConfirmationModal, StaticModal, useModal } from 'modules/modals';
import { Heading, Text } from 'modules/ui';
import { TextInput } from 'modules/forms';

import { useUpdateTag } from '../../hooks/useUpdateTag';

import * as felaRules from './UpdateTagModal.rules';

export const UpdateTagModal = () => {
    const { modal, updateModal } = useModal<{
        tag: string;
        count: number;
    }>(StaticModal.UPDATE_TAG, {
        autoClose: true,
    });

    const [tag, setTag] = useState<string>(modal?.data?.tag ?? '');

    useEffect(() => {
        setTag(prevTag => modal?.data?.tag ?? prevTag);
    }, [modal?.data?.tag]);

    const { api, update } = useUpdateTag(modal?.data?.tag);

    return (
        <ConfirmationModal
            open={modal?.visibility}
            icon={null}
            cancelButton={{
                onClick: () => {
                    updateModal(false);
                },
                disabled: api.inProgress,
                children: <FormattedMessage id="tags.update.cancel" />,
            }}
            okButton={{
                onClick: () => {
                    update(tag);
                },
                disabled: !tag || tag === modal?.data?.tag || api.inProgress,
                loading: api.inProgress,
                children: <FormattedMessage id="tags.update.ok" />,
            }}
            width={474}
        >
            <Heading color="black">
                <FormattedMessage id="tags.update.title" />
            </Heading>
            <Text size="small" color="brownGrey" customStyle={felaRules.info}>
                <FormattedMessage
                    id="tags.update.info"
                    values={{
                        count: modal?.data?.count,
                    }}
                />
            </Text>
            <TextInput
                value={tag}
                onChange={e => {
                    setTag(e.target.value);
                }}
            />
        </ConfirmationModal>
    );
};
