import { useFela } from 'react-fela';
import { FormattedMessage, useIntl } from 'react-intl';
import useToggle from 'react-use-toggle';

import { ConfirmationModal } from 'modules/modals';
import { Icon, IconButton, Text } from 'modules/ui';

import type { Base64File } from '../../types';
import { downloadBase64File } from '../../utilities';

import * as felaRules from './SecretFile.rules';

export interface SecretFileProps {
    file: Base64File;
    onRemove?: () => void;
}

export const SecretFile = ({ file, onRemove }: SecretFileProps) => {
    const intl = useIntl();
    const { css } = useFela();
    const [open, toggle] = useToggle();

    return (
        <div className={css(felaRules.container)}>
            <Text customStyle={felaRules.text}>{file.name}</Text>
            {onRemove ? (
                <>
                    <IconButton
                        type="ghost"
                        aria-label={intl.formatMessage({
                            id: 'remove.file',
                        })}
                        icon={{
                            type: 'trash',
                            color: 'brightMagenta',
                        }}
                        onClick={toggle}
                    />
                    <ConfirmationModal
                        open={open}
                        width={474}
                        icon={<Icon type="trash" color="brightMagenta" />}
                        okButton={{
                            onClick: () => {
                                onRemove();
                                toggle();
                            },
                            children: <FormattedMessage id="secret.modal.removeFile.ok" />,
                        }}
                        cancelButton={{
                            onClick: toggle,
                            children: <FormattedMessage id="secret.modal.removeFile.cancel" />,
                        }}
                        closable={false}
                    >
                        <FormattedMessage id="secret.modal.removeFile.title" />
                    </ConfirmationModal>
                </>
            ) : (
                <IconButton
                    type="ghost"
                    icon="download"
                    aria-label={intl.formatMessage({
                        id: 'download.file',
                    })}
                    onClick={() => {
                        downloadBase64File(file);
                    }}
                />
            )}
        </div>
    );
};
