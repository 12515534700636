import { useRef } from 'react';
import { useFela } from 'react-fela';

import { useBreakpoint } from 'hooks/useBreakpoint';

import { SecretGroups } from '../SecretGroups';
import type { SecretsListHandle } from '../SecretsList';
import { SecretsWithFilter } from '../SecretWithFilter';

import * as felaRules from './SecretsPage.rules';

export const SecretsPage = () => {
    const { css } = useFela();

    const listRef = useRef<SecretsListHandle>();

    const handleActiveGroup = (group: string) => {
        listRef.current.scrollToGroup(group);
    };

    const isMin1440 = useBreakpoint('lg');

    return (
        <div className={css(felaRules.container)}>
            {isMin1440 && <SecretGroups handleActiveGroup={handleActiveGroup} />}
            <SecretsWithFilter ref={listRef} />
        </div>
    );
};
