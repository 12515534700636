import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';

import { Icon, Text } from 'modules/ui';

import { container } from './AllUsersGroup.rules';

export const AllUsersGroup = () => {
    const { css } = useFela();
    return (
        <div className={css(container)}>
            <Icon type="group-visible" />
            <Text size="small">
                <FormattedMessage id="secret.allVisible.label" />
            </Text>
        </div>
    );
};
