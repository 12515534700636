import { useFela } from 'react-fela';

import { useSecretGroups } from 'modules/secrets/hooks/useSecretGroups';

import { SecretGroupsItem } from './SecretGroupsItem';
import type { SecretGroupsItemProps } from './SecretGroupsItem';

import { SECRET_GROUPS } from '../../constants';

import * as felaRules from './SecretGroups.rules';

export interface SecretGroupsProps {
    handleActiveGroup: SecretGroupsItemProps['handleActiveGroup'];
}

export const SecretGroups = ({ handleActiveGroup }: SecretGroupsProps) => {
    const { css } = useFela();

    const { groups, activeSecretGroups } = useSecretGroups();

    return (
        <div className={css(felaRules.container)}>
            {SECRET_GROUPS.map(group => (
                <SecretGroupsItem
                    key={group}
                    group={group}
                    isActive={activeSecretGroups.includes(group)}
                    disabled={!groups.includes(group)}
                    handleActiveGroup={handleActiveGroup}
                />
            ))}
        </div>
    );
};
