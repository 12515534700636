import type { TRuleWithTheme, Theme } from 'styles/theme';

import type { LabelProps } from './Label';

export enum LabelType {
    DEFAULT = 'default',
    DARK = 'dark',
}

export enum LabelSize {
    SMALL = 'small',
    REGULAR = 'regular',
    LARGE = 'large  ',
}

const colorMap: Record<LabelType, keyof Theme['colors']> = {
    [LabelType.DARK]: 'black',
    [LabelType.DEFAULT]: 'foregroundsTertiary',
};

export const container: TRuleWithTheme<Pick<LabelProps, 'size' | 'type' | 'color'>> = ({
    theme,
    type,
    size,
    color: colorKey,
}) => {
    const color = theme.colors[colorKey ?? colorMap[type]];

    return {
        display: 'block',

        fontWeight: '500',

        color: typeof color === 'string' ? color : theme.colors.black,

        extend: [
            {
                condition: size === LabelSize.SMALL,
                style: {
                    fontSize: '0.625rem',
                    lineHeight: '1.2em',
                },
            },
            {
                condition: size === LabelSize.REGULAR,
                style: {
                    fontSize: '0.75rem',
                    lineHeight: '1.5rem',
                    letterSpacing: '0.025em',
                },
            },
        ],
    };
};
