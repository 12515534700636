import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';

import { Button, Icon } from 'modules/ui';

import * as felaRules from './GeneratePasswordRevertButton.rules';

export interface GeneratePasswordRevertButtonProps {
    onClick: () => void;
}

export const GeneratePasswordRevertButton = ({ onClick }: GeneratePasswordRevertButtonProps) => {
    const { css } = useFela();

    return (
        <Button type="link" size="small" onClick={onClick} className={css(felaRules.button)}>
            <FormattedMessage id="secret.generate.undo" />
            <Icon width="16" height="16" type="revert-changes" customStyle={felaRules.icon} />
        </Button>
    );
};
