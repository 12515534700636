import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useAppSelector } from 'hooks/useAppSelector';

import type { Secret, TOTP } from '../types';

import { selectSecretTOTPS, selectActiveTOTP } from '../services/selectors';
import { getSecretTOTP, getSecretTOTPReset, removeSecretTOTP } from '../services/actions';

export const useTOTP = (id: Secret['id'], autofetch: boolean = true) => {
    const totps = useAppSelector(state =>
        selectSecretTOTPS(state, {
            id,
        }),
    );

    const activeTotp = useAppSelector(state =>
        selectActiveTOTP(state, {
            id,
        }),
    );

    const dispatch = useDispatch();

    const getTOTP = () => {
        dispatch(getSecretTOTP(id));
    };

    const removeTOTP = (code: TOTP['code']) => {
        dispatch(removeSecretTOTP(id, code));
    };

    useEffect(() => {
        if ((!activeTotp || totps.length === 1) && autofetch) {
            dispatch(getSecretTOTP(id));
        }
    }, [activeTotp, totps, id, dispatch, autofetch]);

    useEffect(
        () => () => {
            dispatch(getSecretTOTPReset(id));
        },
        [id, dispatch],
    );

    return { activeTotp, getTOTP, removeTOTP };
};
