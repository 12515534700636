import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'max-content',
    gap: '1rem',
    alignItems: 'center',

    marginTop: '0.5rem',
    marginBottom: '0.5rem',

    ':last-child': {
        marginBottom: '0',
    },
});
