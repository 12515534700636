import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFela } from 'react-fela';

import { Button, Icon } from 'modules/ui';

import type { CopyableSecretKeys, Secret } from '../../types';
import { useCopySecretValue } from '../../hooks/useCopySecretValue';

import * as felaRules from './CopySecretValue.rules';

export interface CopySecretValueProps<V> {
    id: Secret['id'];
    property: CopyableSecretKeys;
    disabled?: boolean;
    copied?: (props: { value: V }) => ReactNode;
}

export function CopySecretValue<V>({ id, property, copied, disabled = false }: CopySecretValueProps<V>) {
    const { css } = useFela();

    const { inProgress, success, handleCopy, value } = useCopySecretValue<V>(id, property);

    if (inProgress) {
        return (
            <span className={css(felaRules.container)}>
                <Icon type="reloading" customStyle={felaRules.icon} />
            </span>
        );
    }

    if (success) {
        return (
            <span className={css(felaRules.container)}>
                {copied ? copied({ value }) : <FormattedMessage id="copied" />}
            </span>
        );
    }

    return (
        <span className={css(felaRules.container)}>
            <Button
                type="link"
                customStyle={felaRules.button}
                disabled={disabled}
                onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();

                    handleCopy();
                }}
            >
                <FormattedMessage id="copy" />
            </Button>
        </span>
    );
}
