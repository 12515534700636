import { takeEvery, put } from 'redux-saga/effects';
import { push } from 'redux-first-history';

import { FORM_ERROR } from 'final-form';

import { api } from 'config/antonio';
import { config } from 'config/config';
import { logger } from 'config/logger';

import { isSaasLimitReachedError } from 'services/utilities/isApiError';

import { actions as modalsActions } from 'modules/modals';
import { actions as messagesActions } from 'modules/messages';

import { MAX_SAAS_SECRETS_COUNT, NEW_SECRET_MODAL_KEY } from '../../constants';

import {
    createSecretSuccess,
    createSecretFailure,
    setSecrets,
    createSecret,
    syncSecrets,
    setSecretsTotalCount,
} from '../actions';
import { normalizeSecret } from '../normalizers';
import { prepareSecretPayload } from '../selectors/utilities';

function* handler({ payload, meta }: ReturnType<typeof createSecret>) {
    try {
        const { data } = yield* api.post<any>(config.api.secrets, prepareSecretPayload(payload));

        const { entities, result } = normalizeSecret(data);

        yield put(createSecretSuccess());
        yield put(setSecrets(entities.secrets, [result]));

        meta.success();

        yield put(push(config.routes.secret.replace(':id', result)));

        yield put(
            messagesActions.displaySuccessMessage({
                message: { id: 'success.secrets.create' },
            }),
        );
    } catch (e) {
        logger.error(e);

        yield put(createSecretFailure(e));

        if (isSaasLimitReachedError(e)) {
            meta.failure({
                [FORM_ERROR]: {
                    id: 'error.secrets.create.saas-limit',
                    values: {
                        maxSecrets: MAX_SAAS_SECRETS_COUNT,
                    },
                },
            });

            // Show the count alert and trigger sync
            yield put(setSecretsTotalCount(MAX_SAAS_SECRETS_COUNT));
            yield put(syncSecrets(true));

            return;
        }

        meta.failure({
            [FORM_ERROR]: { id: 'error.secrets.create' },
        });
    } finally {
        yield put(modalsActions.setModalVisibility(NEW_SECRET_MODAL_KEY, false));
    }
}

export default function* () {
    yield takeEvery(createSecret.toString(), handler);
}
