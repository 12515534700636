import { useState } from 'react';
import { useFela } from 'react-fela';

import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';

import { TagManager } from 'services/utilities';

import { Empty, Button, CheckableTag } from 'modules/ui';
import { Label } from 'modules/forms';

import { SearchSecretAttributeType } from 'modules/secrets';

import { useSecretsAttributesFilter } from '../../../../hooks/useSecretsAttributesFilter';
import { useTagsList } from '../../hooks/useTagsList';

import * as felaRules from './TagsFilter.rules';

const CLOSED_COUNT = 5;

export const TagsFilter = () => {
    const { css } = useFela();

    const [opened, setOpened] = useState(false);

    const tags = useTagsList();
    const { filter, toggleFilter, clearFilter } = useSecretsAttributesFilter();

    return (
        <>
            <div className={css(felaRules.header)}>
                <Label>
                    <FormattedMessage id="tags.list.title" />
                </Label>
                {isEmpty(filter) ? null : (
                    <Button type="link" size="small" onClick={clearFilter}>
                        <FormattedMessage id="tags.list.clear" />
                    </Button>
                )}
            </div>
            {isEmpty(tags) && (
                <Empty
                    size="extra-small"
                    text={<FormattedMessage id="tags.list.empty" />}
                    customStyle={felaRules.empty}
                />
            )}
            {(opened ? tags : tags.slice(0, CLOSED_COUNT)).map(tag => (
                <CheckableTag
                    onChange={checked => {
                        TagManager.push('used_tags');
                        toggleFilter(tag.name, tag.name, SearchSecretAttributeType.TAG, checked);
                    }}
                    checked={filter.some(
                        attribute =>
                            attribute.type === SearchSecretAttributeType.TAG && attribute.value.includes(tag.name),
                    )}
                    key={tag.name}
                    customStyle={felaRules.tag}
                >
                    {tag.name}
                </CheckableTag>
            ))}
            {tags.length > CLOSED_COUNT ? (
                <Button
                    type="link"
                    size="small"
                    onClick={() => {
                        setOpened(o => !o);
                    }}
                >
                    <FormattedMessage id={opened ? 'tags.list.less' : 'tags.list.more'} />
                </Button>
            ) : null}
        </>
    );
};
