import { call, put } from 'redux-saga/effects';

import config from 'config';
import { logger } from 'config/logger';

import { SECRETS_TOTAL_COUNT_HEADER } from '../constants';
import { setSecretsTotalCount } from '../services/actions';

export function* totalCountIncerceptor(response: Response) {
    try {
        const totalCount = response.headers.get(SECRETS_TOTAL_COUNT_HEADER);

        if (totalCount) {
            yield put(setSecretsTotalCount(Number.parseInt(totalCount, 10)));
        } else if (response.url.includes(config.api.secrets)) {
            const clonedReponse = response.clone();

            const secrets = yield call([clonedReponse, clonedReponse.json]);

            yield put(setSecretsTotalCount(secrets.length));
        }
    } catch (e) {
        logger.error(e);
    }

    return response;
}
