import { useParams } from 'react-router-dom';

import { ClosablePage, Loadable } from 'modules/ui';

import type { Secret } from '../../types';

import { useFetchSecret } from '../../hooks/useFetchSecret';

import { SecretDetail } from '../SecretDetail';

export const SecretDetailPage = () => {
    const { id } = useParams<{
        id: Secret['id'];
    }>();
    const { showLoader } = useFetchSecret(id);

    return (
        <ClosablePage>
            <Loadable showLoader={showLoader}>
                <SecretDetail id={id} />
            </Loadable>
        </ClosablePage>
    );
};
