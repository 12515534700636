import { useFela } from 'react-fela';
import { useIntl } from 'react-intl';
import useToggle from 'react-use-toggle';

import { IconButton } from 'modules/ui';
import { useSharingAvailablility } from 'modules/settings';

import type { Secret } from '../../types';
import { isSecretShareable } from '../../utilities';

import { ShareSecretModal } from '../ShareSecretModal';

import * as felaRules from './ShareSecretButton.rules';

export interface ShareSecretButtonProps {
    id: Secret['id'];
    share?: Secret['share'];
}

export const ShareSecretButton = ({ id, share }: ShareSecretButtonProps) => {
    const [open, toggle] = useToggle();

    const sharingAvailable = useSharingAvailablility();
    const shareable = isSecretShareable(share);

    const { css } = useFela({
        shareable,
    });

    const intl = useIntl();

    if (sharingAvailable) {
        return (
            <>
                <div className={css(felaRules.container)}>
                    <span className={css(felaRules.indicator)} />
                    <IconButton
                        icon="share"
                        aria-label={intl.formatMessage({
                            id: 'secret.share.button',
                        })}
                        onClick={toggle}
                    />
                </div>
                <ShareSecretModal id={id} share={share} open={open} onClose={toggle} />
            </>
        );
    }

    return null;
};
