import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';

import { permissionByRole, permissionToRole } from 'utilities/permission';

import { Button } from 'modules/ui';
import { RoleDropdown } from 'modules/forms';

import type { WhitelistUser } from '../../types';

import * as felaRules from './WhitelistItem.rules';

export interface WhitelistItemProps extends WhitelistUser {
    onRemove?: (id: WhitelistUser['id']) => void;
    onUpdate?: (item: WhitelistUser) => void;
    editable?: boolean;
}

export const WhitelistItem = ({
    id,
    name,
    email,
    accessPermissions,
    onUpdate,
    onRemove,
    editable = false,
}: WhitelistItemProps) => {
    const { css } = useFela();

    const accessRole = permissionToRole(accessPermissions);

    return (
        <li className={css(felaRules.container)}>
            <span className={css(felaRules.desktopUserItem)}>{`${name} (${email})`}</span>
            <span className={css(felaRules.mobileUserItem)}>{email}</span>
            {editable ? (
                <RoleDropdown
                    value={accessRole}
                    onChange={newRole => {
                        onUpdate({
                            id,
                            name,
                            email,
                            accessPermissions: permissionByRole[newRole],
                        });
                    }}
                />
            ) : null}
            {editable ? (
                <Button
                    color="brightMagenta"
                    type="link"
                    size="small"
                    onClick={() => {
                        onRemove(id);
                    }}
                >
                    <FormattedMessage id="remove" />
                </Button>
            ) : null}
        </li>
    );
};
