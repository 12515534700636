import type { TRuleWithTheme } from 'styles/theme';

import type { ClosablePageProps } from './ClosablePage';

export const container: TRuleWithTheme<Pick<ClosablePageProps, 'header'>> = ({ theme, header }) => ({
    flex: 1,

    paddingTop: header ? '1rem' : '1.5rem',
    paddingRight: '1.5rem',
    paddingBottom: '1.5rem',
    paddingLeft: '1.5rem',

    backgroundColor: theme.colors.white,

    min768: {
        paddingTop: header ? 0 : '2.5rem',
        paddingRight: '2.5rem',
        paddingBottom: '2.5rem',
        paddingLeft: '2.5rem',
    },
});
