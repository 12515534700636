import type { TRuleWithTheme } from 'styles/theme';

const size = 3;

export const container: TRuleWithTheme = () => ({
    cursor: 'pointer',

    position: 'absolute',
    right: `${size / 2}rem`,
    bottom: '-1.5rem',
    zIndex: 1,

    width: `${size}rem`,
    height: `${size}rem`,

    lineHeight: `${size}rem`,
});
