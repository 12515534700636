import type { ReactNode } from 'react';
import { useParams } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';

import config from 'config';

import { Link } from 'modules/ui';
import { Label, InfoLabel } from 'modules/forms';
import { FormFieldWhitelist, useWhitelistUsers } from 'modules/whitelist';
import type { WhitelistUser } from 'modules/whitelist';

import { Fields } from '../../../constants';

import { useCreatedBy } from '../../../hooks/useCreatedBy';

import { SelectField } from '../SelectField';
import type { SelectFieldProps } from '../SelectField';

export const WhitelistField = ({ onChange }: Pick<SelectFieldProps<WhitelistUser>, 'onChange'>) => {
    const { id } = useParams<{
        id: string;
    }>();

    const createdById = useCreatedBy(id);

    const name = Fields.WHITELIST_USERS;
    const label = (
        <>
            <Label>
                <FormattedMessage id="secret.whitelistUsers.label" />
            </Label>
            <InfoLabel>
                <FormattedMessage
                    id="secret.help.roles"
                    values={{
                        a: (parts: ReactNode[]) => (
                            <Link type="native" href={config.links.roles}>
                                {parts}
                            </Link>
                        ),
                    }}
                />
            </InfoLabel>
        </>
    );

    const intl = useIntl();

    const placeholder = intl.formatMessage({ id: 'secret.whitelistUsers.placeholder' });

    const { api, data, handleLoadMore, handleSearch } = useWhitelistUsers();

    return (
        <SelectField<WhitelistUser>
            id={name}
            name={name}
            label={label}
            placeholder={placeholder}
            options={data
                .filter(user => user.id !== createdById)
                .map(user => ({
                    value: user.id,
                    key: user.id,
                    label: `${user.name} (${user.email})`,
                    entity: user,
                }))}
            onChange={onChange}
            loadingMore={api.inProgressNext}
            onSearch={handleSearch}
            onLoadMore={handleLoadMore}
            hasMore={Boolean(api.nextPageToken)}
        >
            {(value, actions) => (
                <FormFieldWhitelist value={value} onUpdate={actions.onUpdate} onRemove={actions.onRemove} />
            )}
        </SelectField>
    );
};
