import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useAppSelector } from 'hooks/useAppSelector';

import { selectSecretsWithGroups } from '../services/selectors';
import { setActiveSecretGroups } from '../services/actions';

export const useSecretsList = () => {
    const dispatch = useDispatch();

    const data = useAppSelector(selectSecretsWithGroups);

    const handleActiveGroup = useCallback(
        (groups: string[]) => {
            dispatch(setActiveSecretGroups(groups));
        },
        [dispatch],
    );

    return {
        data,
        handleActiveGroup,
    };
};
