import type { TRuleWithTheme } from 'styles/theme';

export const button: TRuleWithTheme = () => ({
    display: 'inline-flex',
    alignItems: 'center',

    marginLeft: '1rem',

    verticalAlign: 'baseline',
});

export const icon: TRuleWithTheme = () => ({
    marginLeft: '0.125rem',
});
