import type { TRuleWithTheme } from 'styles/theme';

export const button: TRuleWithTheme = () => ({
    fontSize: '0.875rem',
    lineHeight: 1.35,
});

export const popover: TRuleWithTheme = ({ theme }) => ({
    '& .ant-popover-inner': {
        overflow: 'hidden',
        position: 'relative',
    },
});
