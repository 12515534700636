import { useFela } from 'react-fela';

import { Radio as AntdRadio } from 'antd';
import type { RadioProps } from 'antd/es/radio';

import * as felaRules from './Radio.rules';

export type { RadioProps };

export function Radio(props: RadioProps) {
    const { css } = useFela();

    return <AntdRadio {...props} className={css(felaRules.container)} />;
}
