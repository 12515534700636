import type { TRuleWithTheme } from 'styles/theme';

export const heading: TRuleWithTheme = () => ({
    maxWidth: 227,

    marginBottom: '0.5rem',
});

export const headingSent: TRuleWithTheme = () => ({
    maxWidth: 290,

    marginBottom: '0.5rem',
});

export const text: TRuleWithTheme = () => ({
    marginBottom: '2rem',
});

export const buttons: TRuleWithTheme = () => ({
    display: 'flex',

    gap: '1rem',

    '& > .Button': {
        flex: 1,
    },
});
