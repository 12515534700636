import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    display: 'inline-flex',

    marginLeft: '16px',

    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: 'normal',

    textTransform: 'lowercase',

    color: theme.colors.blue,
});

export const button: TRuleWithTheme = () => ({
    textTransform: 'lowercase',
});

export const icon: TRuleWithTheme = () => ({
    animationName: {
        from: {
            transform: 'rotate(0deg)',
        },
        to: {
            transform: 'rotate(-360deg)',
        },
    },
    animationDuration: '2500ms',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
});
