import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    display: 'inline-block',

    marginRight: '1rem',

    fontSize: '.875rem', // 14px
    lineHeight: '1.1875rem', // 19px
    letterSpacing: '0.043em', // ~ 0.6px

    color: theme.colors.foregroundsTertiary,

    min768: {
        marginRight: '0.75rem',
    },
});
