import { FormattedMessage } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import { ClosablePage, Loadable, Empty } from 'modules/ui';
import type { ClosablePageProps } from 'modules/ui';

import { useFetchSecretUpdate } from '../../hooks/useFetchSecretUpdate';

import { UpdateSecretPageCloseButton } from '../UpdateSecretPageCloseButton';
import { UpdateSecretPageHeading } from '../UpdateSecretPageHeading';

import { UpdateSecretForm } from '../UpdateSecretForm';
import { UpdateSecretFormProvider } from '../UpdateSecretFormProvider';

import { RemoveSecretButton } from '../RemoveSecretButton';
import { RemoveSecretModal } from '../RemoveSecretModal';

const renderButton: ClosablePageProps['closeButton']['renderButton'] = (handleClick, customStyle) => (
    <UpdateSecretPageCloseButton onClick={handleClick} customStyle={customStyle} />
);

export const UpdateSecretPage = () => {
    const { id } = useParams<{
        id: string;
    }>();

    const history = useHistory();

    const { showLoader, hasSecret, hasPermissions } = useFetchSecretUpdate(id);

    if (showLoader) {
        return (
            <ClosablePage
                closeButton={{
                    close: history.goBack,
                }}
            >
                <Loadable showLoader />
            </ClosablePage>
        );
    }

    return (
        <UpdateSecretFormProvider>
            {() => (
                <ClosablePage
                    header={<UpdateSecretPageHeading />}
                    closeButton={{
                        renderButton,
                        close: history.goBack,
                    }}
                >
                    {hasSecret && hasPermissions ? (
                        <>
                            <UpdateSecretForm />
                            <RemoveSecretButton />
                            <RemoveSecretModal />
                        </>
                    ) : (
                        <Empty data-testid="update-secret-empty" text={<FormattedMessage id="secret.detail.empty" />} />
                    )}
                </ClosablePage>
            )}
        </UpdateSecretFormProvider>
    );
};
