import { FormattedMessage } from 'react-intl';

import { Icon, Text } from 'modules/ui';

import { icon } from './AutofillOnlyInfo.rules';

export const AutofillOnlyInfo = () => (
    <Text size="small">
        <Icon type="warning" color="red" customStyle={icon} />
        <FormattedMessage id="secret.autofillOnly.info" />
    </Text>
);
