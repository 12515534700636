import { useFela } from 'react-fela';

import { Text } from 'modules/ui';

import type { Group } from '../../types';

import { GroupItem } from '../GroupItem';
import type { GroupItemProps } from '../GroupItem';

import { container } from '../Groups/Groups.rules';
import { useGroupsWithLocalizedAllUsersGroup } from '../../hooks';

export interface FormFieldGroupsProps extends Pick<GroupItemProps, 'onRemove' | 'onUpdate'> {
    value: Group[];
}

export const FormFieldGroups = ({ value, onRemove, onUpdate }: FormFieldGroupsProps) => {
    const groups = useGroupsWithLocalizedAllUsersGroup(value);

    const { css } = useFela({
        removable: true,
    });

    return (
        <div className={css(container)}>
            <Text size="small" elementType="ul">
                {groups.map(group => (
                    <GroupItem key={group.id} {...group} editable onRemove={onRemove} onUpdate={onUpdate} />
                ))}
            </Text>
        </div>
    );
};
