import { useFela } from 'react-fela';

import { Button as AntButton } from 'antd';
import type { ButtonProps as AntButtonProps } from 'antd/es/button';

import type theme from 'styles/theme';

import { container, link, ButtonType } from './Button.rules';

enum ButtonHTMLType {
    BUTTON = 'button',
    SUBMIT = 'submit',
    RESET = 'reset',
}

export { ButtonType, ButtonHTMLType };

export interface ButtonProps extends AntButtonProps {
    color?: keyof typeof theme.colors;
    customStyle?: typeof container;
}

export const Button = ({
    type,
    htmlType = ButtonHTMLType.BUTTON,
    color = 'blue',
    className = '',
    customStyle,
    ...props
}: ButtonProps) => {
    const { css } = useFela({
        color,
    });

    const finalClassName = `Button Button-${type} ${css(
        type === ButtonType.LINK ? link : container,
        customStyle,
    )} ${className}`;

    return (
        <AntButton
            {...props}
            type={type}
            htmlType={htmlType}
            shape={type === ButtonType.LINK ? 'default' : 'round'}
            className={finalClassName}
        />
    );
};
