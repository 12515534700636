import type { ReactNode } from 'react';
import { Field } from 'react-final-form';
import { identity } from 'lodash';

import type { EntityWithPermissions } from 'types';

import { permissionByRole } from 'utilities/permission';

import { FormItem, SelectInput } from 'modules/forms';
import type { FormItemProps } from 'modules/forms';
import { useDefaultAccessRole } from 'modules/settings';

interface Option<Value extends EntityWithPermissions> {
    value: Value['id'];
    entity: Value;
    label: string;
    key: string;
}

interface RenderValueFn<Value extends EntityWithPermissions> {
    (
        value: Value[],
        actions: {
            onUpdate: (value: Value) => void;
            onRemove: (value: Value['id']) => void;
        },
    ): ReactNode;
}

export interface SelectFieldProps<Value extends EntityWithPermissions> {
    id: string;
    name: string;
    placeholder: string;
    label: FormItemProps<Value[]>['label'];
    children: RenderValueFn<Value>;
    loadingMore: boolean;
    onLoadMore: () => void;
    hasMore: boolean;
    onSearch: (filter: string) => void;
    onChange?: () => void;
    options: Option<Value>[];
}

const dummyValue = [];

export function SelectField<Value extends EntityWithPermissions>({
    id,
    placeholder,
    name,
    children,
    label,
    options,
    onChange,
    loadingMore,
    onLoadMore,
    onSearch,
    hasMore,
}: SelectFieldProps<Value>) {
    const defaultAccessRole = useDefaultAccessRole();

    return (
        <Field<Value[]> name={name} format={identity} parse={identity}>
            {({ input, meta }) => {
                const value = Boolean(input.value) ? input.value : dummyValue;

                const handleChange = (val: Value[]) => {
                    input.onChange(val);
                    onChange?.();
                };

                return (
                    <FormItem<Value[]> label={label} meta={meta}>
                        <SelectInput
                            id={id}
                            value={dummyValue}
                            placeholder={placeholder}
                            onBlur={input.onBlur}
                            onFocus={input.onFocus}
                            onChange={(val: string) => {
                                const matchingOption = options.find(option => val === option.value);

                                handleChange([
                                    ...value,
                                    {
                                        ...matchingOption.entity,
                                        accessPermissions: permissionByRole[defaultAccessRole],
                                    },
                                ]);

                                onSearch('');
                            }}
                            options={options
                                .filter(option => !value.find(v => v.id === option.value))
                                .map(({ value, label, key }) => ({ value, label, key }))}
                            showSearch
                            onSearch={onSearch}
                            optionFilterProp="children"
                            loadingMore={loadingMore}
                            loadMore={onLoadMore}
                            hasMore={hasMore}
                        />
                        {children(value, {
                            onUpdate: (val: Value) => {
                                handleChange(value.map(v => (v.id === val.id ? val : v)));
                            },
                            onRemove: (id: Value['id']) => {
                                handleChange(value.filter(v => v.id !== id));
                            },
                        })}
                    </FormItem>
                );
            }}
        </Field>
    );
}
