import { FormattedMessage } from 'react-intl';

import { Radio, RadioGroup } from 'modules/forms';

import { Readability } from '../../../constants';

export interface GeneratePasswordReadabilityProps {
    value: Readability;
    onChange: (value: Readability) => void;
}

export const GeneratePasswordReadability = ({ value, onChange }: GeneratePasswordReadabilityProps) => (
    <RadioGroup
        value={value}
        onChange={e => {
            onChange(e.target.value);
        }}
    >
        <Radio value={Readability.EASY_TO_SAY}>
            <FormattedMessage id={`secret.generate.radios.${Readability.EASY_TO_SAY}`} />
        </Radio>
        <Radio value={Readability.EASY_TO_READ}>
            <FormattedMessage id={`secret.generate.radios.${Readability.EASY_TO_READ}`} />
        </Radio>
        <Radio value={Readability.ALL_CHARACTERS}>
            <FormattedMessage id={`secret.generate.radios.${Readability.ALL_CHARACTERS}`} />
        </Radio>
    </RadioGroup>
);
