import { FormattedMessage } from 'react-intl';

import { Checkbox, CheckboxGroup } from 'modules/forms';

import { Options, Readability } from '../../../constants';

export interface GeneratePasswordOptionsProps {
    value: Options[];
    onChange: (value: Options[]) => void;
    readability: Readability;
}

export const GeneratePasswordOptions = ({ onChange, readability, value }: GeneratePasswordOptionsProps) => {
    const lastValue = value.length === 1 ? value[0] : undefined;

    return (
        <CheckboxGroup value={value} onChange={onChange}>
            <Checkbox value={Options.UPPERCASE} disabled={lastValue === Options.UPPERCASE}>
                <FormattedMessage id={`secret.generate.checkboxes.${Options.UPPERCASE}`} />
            </Checkbox>
            <Checkbox value={Options.LOWERCASE} disabled={lastValue === Options.LOWERCASE}>
                <FormattedMessage id={`secret.generate.checkboxes.${Options.LOWERCASE}`} />
            </Checkbox>
            <Checkbox
                value={Options.NUMBERS}
                disabled={readability === Readability.EASY_TO_SAY || lastValue === Options.NUMBERS}
            >
                <FormattedMessage id={`secret.generate.checkboxes.${Options.NUMBERS}`} />
            </Checkbox>
            <Checkbox
                value={Options.SYMBOLS}
                disabled={readability === Readability.EASY_TO_SAY || lastValue === Options.SYMBOLS}
            >
                <FormattedMessage id={`secret.generate.checkboxes.${Options.SYMBOLS}`} />
            </Checkbox>
        </CheckboxGroup>
    );
};
