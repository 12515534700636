import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    display: 'flex',
    lineHeight: '0',
    alignItems: 'center',
    '& .Icon': {
        marginRight: '0.3rem',
    },
});
