import type { TRuleWithTheme } from 'styles/theme';

export const labelMarginLeft = 0.625;

export const container: TRuleWithTheme = ({ theme }) => ({
    '&.ant-form-item': {
        marginBottom: '1.25rem',

        min768: {
            marginBottom: '1.5rem',
        },
    },

    '& .ant-form-item-label': {
        overflow: 'unset',
        whiteSpace: 'unset',
        textAlign: 'left',

        paddingBottom: '0.25rem',

        min768: {
            paddingBottom: 0,
        },
    },

    '& .ant-form-item-label label': {
        display: 'block',

        height: 'auto',
    },

    '& .ant-form-item-label label::after': {
        display: 'none',
    },

    '& .ant-form-item-control': {
        width: '100%',

        lineHeight: '1rem',
    },

    '& .ant-form-item-explain': {
        marginTop: '0.125rem',
        fontSize: '0.75rem',
        lineHeight: '1.75em',
    },
});
