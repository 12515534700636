import tinycolor from 'tinycolor2';

import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    '&.ant-alert': {
        alignItems: 'flex-start',

        paddingTop: '1rem',
        paddingLeft: '0.75rem',
        paddingBottom: '1rem',
        paddingRight: '0.75rem',

        marginBottom: '2rem',

        fontFamily: theme.fontFamily,

        borderRadius: '0.25rem',
    },

    '&.ant-alert .ant-alert-content': {
        alignSelf: 'center',
    },

    '&.ant-alert .ant-alert-message': {
        fontSize: '0.8125rem', // 13px
        lineHeight: '1.38em', // ~ 18px
        letterSpacing: '0.0154em', // ~ 0.2px
    },

    '&.ant-alert.ant-alert-warning': {
        borderColor: theme.colors.alertsWarning,
        backgroundColor: tinycolor(theme.colors.alertsWarning).setAlpha(0.05).toString(),
    },

    '&.ant-alert.ant-alert-warning .ant-alert-icon': {
        color: theme.colors.alertsWarning,
    },

    '&.ant-alert.ant-alert-error': {
        borderColor: theme.colors.alertsError,
        backgroundColor: tinycolor(theme.colors.alertsError).setAlpha(0.05).toString(),
    },

    '&.ant-alert.ant-alert-error .ant-alert-icon': {
        color: theme.colors.alertsError,
    },
});
