import { useFela } from 'react-fela';

import { Radio as AntdRadio } from 'antd';
import type { RadioGroupProps } from 'antd/es/radio';

import * as felaRules from './RadioGroup.rules';

export type { RadioGroupProps };

export const RadioGroup = (props: RadioGroupProps) => {
    const { css } = useFela();

    return <AntdRadio.Group {...props} className={css(felaRules.container)} />;
};
