import type { ReactNode } from 'react';

import { Router } from 'react-router-dom';
import { Provider as StoreProvider } from 'react-redux';
import { RendererProvider, ThemeProvider } from 'react-fela';
import { PersistGate } from 'redux-persist/integration/react';

import { initSentry } from 'config/sentry';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { LocalizationsProvider } from 'modules/localizations';

import { createStore } from '../modules/redux';
import { createRenderer } from '../modules/fela';

initSentry();

const { history, store, persistor } = createStore();
const { renderer, theme } = createRenderer();

export interface CoreProps {
    children: ReactNode;
}

export const Core = ({ children }: CoreProps) => (
    <StoreProvider store={store}>
        <PersistGate persistor={persistor}>
            <RendererProvider renderer={renderer}>
                <ThemeProvider theme={theme}>
                    <Router history={history}>
                        <LocalizationsProvider>
                            <ErrorBoundary>{children}</ErrorBoundary>
                        </LocalizationsProvider>
                    </Router>
                </ThemeProvider>
            </RendererProvider>
        </PersistGate>
    </StoreProvider>
);
