import type { TRuleWithTheme } from 'styles/theme';

const size = 1.5;

export const container: TRuleWithTheme<{
    isActive: boolean;
}> = ({ theme, isActive }) => {
    const activeStyle = {
        backgroundColor: isActive ? theme.colors.brightMagenta : theme.colors.paleMauve,
    };
    const disabledActiveStyle = {
        backgroundColor: theme.colors.white,
    };
    const activeSmallHeightStyle = {
        backgroundColor: theme.colors.paleMauve,
    };
    return {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        fontSize: '.625rem',
        fontWeight: isActive ? '700' : '500',
        textTransform: 'uppercase',

        color: isActive ? theme.colors.brightMagenta : theme.colors.black,

        border: 'none',
        borderRadius: `${size / 2}rem`,

        cursor: 'pointer',

        ':hover': activeSmallHeightStyle,
        ':focus': activeSmallHeightStyle,

        '[disabled]': {
            color: theme.colors.lightGrey3,
            cursor: 'unset',
            backgroundColor: theme.colors.white,
            ':hover': disabledActiveStyle,
            ':focus': disabledActiveStyle,
        },
        '> span': {
            position: 'relative',
            top: '1px',
        },

        '@media (min-height: 700px)': {
            height: `${size}rem`,
            width: `${size}rem`,

            fontWeight: '500',

            color: isActive ? theme.colors.white : theme.colors.black,
            backgroundColor: isActive ? theme.colors.brightMagenta : 'unset',

            ':hover': activeStyle,
            ':focus': activeStyle,
        },
    };
};
