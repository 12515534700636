import type { TRuleWithTheme } from 'styles/theme';

export const heading: TRuleWithTheme = () => ({
    textOverflow: 'ellipsis',
    overflow: 'hidden',

    marginBottom: '0.5rem',

    min768: {
        marginRight: '15rem',
    },
});
