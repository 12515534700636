import { combineRules } from 'fela';

import type { TRuleWithTheme } from 'styles/theme';

export { container, text } from '../Text/Text.rules';

const iconSize = 1.5;
const iconOffset = 1.375;

export const button: TRuleWithTheme = () => ({
    '&.ant-btn': {
        flex: 1,

        max768: {
            width: `calc(100% - ${iconOffset + iconSize}rem)`,

            justifyContent: 'space-between',
        },
        min768: {
            maxWidth: `calc(100% - ${iconOffset + iconSize}rem)`,
        },
    },
});

export const disabledButton: TRuleWithTheme = combineRules(button, () => ({
    '&.ant-btn': {
        max768: {
            width: '100%',
        },
        min768: {
            maxWidth: '100%',
        },
    },
}));

export const icon: TRuleWithTheme = () => ({
    cursor: 'pointer',

    flexShrink: 1,

    width: `${iconSize}rem`,
    height: `${iconSize}rem`,

    marginLeft: `${iconOffset}rem`,
});
