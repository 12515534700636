import type { Theme } from 'styles/theme';

const letters = /[a-zA-Z]/;
const numbers = /[0-9]/;

export const getPasswordCharColor = (char: string, theme: Theme) => {
    if (char.match(letters)) {
        return theme.colors.black;
    } else if (char.match(numbers)) {
        return theme.colors.blue;
    } else {
        return theme.colors.brightMagenta;
    }
};

export const isBottomReached = (element: HTMLDivElement) =>
    element.scrollTop + element.offsetHeight === element.scrollHeight;
