import { useFela } from 'react-fela';
import { Switch, Route, Redirect } from 'react-router';

import config from 'config';

import { AdminFirewall } from 'modules/auth';

import { SecurityAuditSecretsTabs } from '../SecurityAuditSecretsTabs';

import { SecurityAuditReportOverview } from '../SecurityAuditReportOverview';
import { IgnoreSecurityAuditReportModal } from '../IgnoreSecurityAuditReportModal';

import { SecurityAuditReportSecrets } from '../SecurityAuditReportSecrets';
import { SecurityAuditLevelSecrets } from '../SecurityAuditLevelSecrets';
import { SecurityAuditAgeSecrets } from '../SecurityAuditAgeSecrets';
import { SecurityAuditLeaksSecrets } from '../SecurityAuditLeaksSecrets';

import * as felaRules from './SecurityAuditSecretsPage.rules';

export const SecurityAuditSecretsPage = () => {
    const { css } = useFela();

    return (
        <AdminFirewall firewall={<Redirect to={config.routes.home} />}>
            <div className={css(felaRules.container)}>
                <SecurityAuditSecretsTabs />
                <Route
                    path={config.routes.securityAudit}
                    render={() => (
                        <>
                            <IgnoreSecurityAuditReportModal />
                            <SecurityAuditReportOverview />
                        </>
                    )}
                />
                <div className={css(felaRules.listContainer)}>
                    <Switch>
                        <Route path={config.routes.securityAudit} render={() => <SecurityAuditReportSecrets />} />
                        <Route path={config.routes.securityAuditLevel} render={() => <SecurityAuditLevelSecrets />} />
                        <Route path={config.routes.securityAuditAge} render={() => <SecurityAuditAgeSecrets />} />
                        <Route path={config.routes.securityAuditLeaks} render={() => <SecurityAuditLeaksSecrets />} />
                    </Switch>
                </div>
            </div>
        </AdminFirewall>
    );
};
