import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    display: 'flex',
    flexDirection: 'column',

    height: '100%',

    paddingTop: '2.625rem',
    paddingRight: '1.5rem',
    paddingBottom: '2.65rem',
    paddingLeft: '1.5rem',
});

export const logo: TRuleWithTheme = () => ({
    flexShrink: 0,

    marginBottom: '2.625rem',
});

export const wrapper: TRuleWithTheme = ({ theme }) => ({
    overflow: 'auto',

    alignSelf: 'center',

    width: '100%',

    maxWidth: '37.875rem',

    paddingTop: '2.5rem',
    paddingRight: '2rem',
    paddingBottom: '2.5rem',
    paddingLeft: '2rem',

    backgroundColor: theme.colors.white,

    borderRadius: '1.25rem',
});
