import { useFela } from 'react-fela';

import { Text } from 'modules/ui';

import type { WhitelistUser } from '../../types';

import { WhitelistItem } from '../WhitelistItem';
import type { WhitelistItemProps } from '../WhitelistItem';

import { container } from '../Whitelist/Whitelist.rules';

export interface FormFieldWhitelistProps extends Pick<WhitelistItemProps, 'onRemove' | 'onUpdate'> {
    value: WhitelistUser[];
}

export const FormFieldWhitelist = ({ value, onRemove, onUpdate }: FormFieldWhitelistProps) => {
    const { css } = useFela({
        removable: true,
    });

    return (
        <div className={css(container)}>
            <Text size="small" elementType="ul">
                {value.map(item => (
                    <WhitelistItem key={item.id} {...item} editable onRemove={onRemove} onUpdate={onUpdate} />
                ))}
            </Text>
        </div>
    );
};
