import type { ReactNode } from 'react';
import { useFela } from 'react-fela';

import { container } from './ContentClosePage.rules';

export const ContentClosePage = ({ children }: { children: ReactNode }) => {
    const { css } = useFela();

    return <div className={css(container)}>{children}</div>;
};
