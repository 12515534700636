import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    position: 'relative',
    flex: 'auto',

    display: 'flex',
    flexDirection: 'column',

    height: '100%',
});

export const metaWrapper: TRuleWithTheme = ({ theme }) => ({
    position: 'relative',

    paddingTop: 0,
    paddingRight: '1rem',
    paddingLeft: '1rem',

    borderBottom: `1px solid ${theme.colors.lightGrey}`,

    min768: {
        paddingRight: '2.5rem',
        paddingLeft: '2.5rem',
    },
});
