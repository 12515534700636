import { combineRules } from 'fela';

import { Heading } from 'modules/ui';

import type { Secret } from '../../types';

import { heading } from './SecretName.rules';

export interface SecretNameProps {
    name: Secret['name'];
    customStyle?: typeof heading;
}

export const SecretName = ({ name, customStyle }: SecretNameProps) => (
    <Heading size="big" elementType="h1" customStyle={combineRules(heading, customStyle)}>
        {name}
    </Heading>
);
