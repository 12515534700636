import { useFormState } from 'react-final-form';
import { useFela } from 'react-fela';
import { noop } from 'lodash';

import { Icon } from 'modules/ui';

import type { SecretFormValues } from '../../types';

import { style } from './UpdateSecretPageCloseButton.rules';

export interface UpdateSecretPageCloseButtonProps {
    onClick: () => void;
    customStyle?: typeof style;
}

const subscription = {
    submitting: true,
};

export const UpdateSecretPageCloseButton = ({ onClick, customStyle }: UpdateSecretPageCloseButtonProps) => {
    const { submitting } = useFormState<SecretFormValues>({
        subscription,
    });

    const { css } = useFela({
        sticky: true,
    });

    return (
        <button
            type="button"
            className={css(customStyle, submitting ? style : undefined)}
            onClick={submitting ? noop : onClick}
        >
            {submitting ? <Icon type="spinner" /> : <Icon type="close" />}
        </button>
    );
};
