import { forwardRef, useCallback, useEffect, useImperativeHandle, useLayoutEffect, useRef } from 'react';
import { VariableSizeList } from 'react-window';
import { useRouteMatch } from 'react-router';

import { config } from 'config/config';

import { useBreakpoint } from 'hooks/useBreakpoint';

import { ListItemType, SecretListId } from '../../constants';

import type { ListSecret, ListGroup } from '../../types';

import { useSecretsList } from '../../hooks/useSecretsList';
import { useSecretsListOffset } from '../../hooks/useSecretsListOffset';

import { ItemRenderer } from './ItemRenderer';

const isSecret = (item: ListSecret | ListGroup): item is ListSecret => item.type === ListItemType.SECRET;
const isGroup = (item: ListSecret | ListGroup): item is ListGroup => item.type === ListItemType.GROUP;

const ITEM_SIZE = {
    [ListItemType.SECRET]: {
        desktop: 155,
        mobile: 195,
    },
    [ListItemType.GROUP]: {
        desktop: 75,
        mobile: 56,
    },
};

export interface SecretsListHandle {
    scrollToGroup: (group: string) => void;
}

export interface SecretsListProps {
    height: number;
    width: number;
}

export const SecretsList = forwardRef<SecretsListHandle, SecretsListProps>(({ height, width }, ref) => {
    const { data, handleActiveGroup } = useSecretsList();

    const isMin768 = useBreakpoint('sm');
    const listRef = useRef<VariableSizeList<typeof data>>(null);

    const handleOffset = useSecretsListOffset(SecretListId.MAIN_LIST, listRef);

    useLayoutEffect(() => {
        listRef.current.resetAfterIndex(0);
    }, [data]);

    useImperativeHandle(
        ref,
        () => ({
            scrollToGroup: group => {
                const index = data.findIndex(item => isGroup(item) && item.name === group);

                if (listRef.current && index >= 0) {
                    listRef.current.scrollToItem(index, 'start');
                }
            },
        }),
        [data],
    );

    const gatherActiveGroups = useCallback(
        (startIndex: number, endIndex: number) => {
            const activeGroups: string[] = [];

            for (let i = startIndex; i <= endIndex; i++) {
                const item = data[i];

                if (!item) {
                    break;
                }

                const group = isGroup(item) ? item.name : item.group;

                if (!activeGroups.includes(group)) {
                    activeGroups.push(group);
                }
            }

            return activeGroups;
        },
        [data],
    );

    const match = useRouteMatch<{ id: string }>(config.routes.secret);

    const id = match?.params?.id;

    useEffect(() => {
        const index = data.findIndex(item => isSecret(item) && item.id === id);

        if (index >= 0) {
            listRef.current.scrollToItem(index);
        }
    }, [id, data]);

    return (
        <VariableSizeList<typeof data>
            ref={listRef}
            height={height}
            width={width}
            itemData={data}
            itemCount={data.length}
            estimatedItemSize={
                isMin768 ? ITEM_SIZE[ListItemType.SECRET].desktop : ITEM_SIZE[ListItemType.SECRET].mobile
            }
            itemSize={index => {
                const item = data[index];
                return isMin768 ? ITEM_SIZE[item.type].desktop : ITEM_SIZE[item.type].mobile;
            }}
            itemKey={index => {
                const item = data[index];
                return item.key;
            }}
            onItemsRendered={({ visibleStartIndex, visibleStopIndex }) => {
                handleActiveGroup(gatherActiveGroups(visibleStartIndex, visibleStopIndex));
            }}
            onScroll={({ scrollOffset }) => {
                handleOffset(scrollOffset);
            }}
        >
            {ItemRenderer}
        </VariableSizeList>
    );
});
