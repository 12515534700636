import { useEffect, useState } from 'react';
import generatePassword from 'generate-password';

import { useSyncRef } from 'hooks/useSyncRef';

import { GeneratePassword, Readability, Options } from '../constants';

const defaultData = {
    [GeneratePassword.LENGTH]: 16,
    [GeneratePassword.READABILITY]: Readability.ALL_CHARACTERS,
    [GeneratePassword.OPTIONS]: [Options.LOWERCASE, Options.UPPERCASE, Options.SYMBOLS, Options.NUMBERS],
};

export const useGeneratePassword = (onSave: (password: string) => void) => {
    const [data, setData] = useState<{
        [GeneratePassword.LENGTH]: number;
        [GeneratePassword.READABILITY]: Readability;
        [GeneratePassword.OPTIONS]: Options[];
    }>(defaultData);

    const onSaveRef = useSyncRef(onSave);

    useEffect(() => {
        onSaveRef.current(
            generatePassword.generate({
                length: data[GeneratePassword.LENGTH],
                uppercase: data[GeneratePassword.OPTIONS].includes(Options.UPPERCASE),
                lowercase: data[GeneratePassword.OPTIONS].includes(Options.LOWERCASE),
                symbols:
                    data[GeneratePassword.OPTIONS].includes(Options.SYMBOLS) &&
                    data[GeneratePassword.READABILITY] !== Readability.EASY_TO_SAY
                        ? '!@#$%^&*'
                        : false,
                numbers:
                    data[GeneratePassword.OPTIONS].includes(Options.NUMBERS) &&
                    data[GeneratePassword.READABILITY] !== Readability.EASY_TO_SAY,
                excludeSimilarCharacters: data[GeneratePassword.READABILITY] === Readability.EASY_TO_READ,
                strict: true,
            }),
        );
    }, [data, onSaveRef]);

    return {
        data,
        setData,
    };
};
