import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    position: 'sticky',
    top: 0,
    zIndex: 10,

    display: 'flex',
    alignItems: 'center',

    width: '100%',

    paddingTop: '1.5rem',
    paddingRight: '1.5rem',
    paddingBottom: '1.5rem',
    paddingLeft: '1.5rem',

    backgroundColor: theme.colors.white,

    min768: {
        paddingTop: '2.5rem',
        paddingRight: '2.5rem',
        paddingBottom: '2.5rem',
        paddingLeft: '2.5rem',
    },
});
