import { combineRules } from 'fela';

import type { TRuleWithTheme } from 'styles/theme';

export const menuDefault: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    height: '100%',
    overflow: 'auto',

    paddingTop: '1.5rem',
    paddingRight: '1.5rem',
    paddingBottom: '1.5rem',
    paddingLeft: '1.5rem',

    backgroundColor: theme.colors.white,

    borderRightWidth: 1,
    borderRightStyle: 'solid',
    borderRightColor: theme.colors.lightGrey,

    MsOverflowStyle: 'none',
    scrollbarWidth: 'none',

    '&::-webkit-scrollbar': {
        display: 'block',

        width: 0,
    },
});

export const menuDesktop: TRuleWithTheme = ({ theme }) => ({
    flexBasis: theme.widths.menu,
    flexGrow: 0,
    position: 'relative',
    flexShrink: 0,

    paddingTop: '1.625rem',
    paddingBottom: '2rem',
});

export const menu = combineRules(menuDefault, menuDesktop);

export const logoWrapper: TRuleWithTheme = () => ({
    marginBottom: '0.75rem',
});
