import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useFela } from 'react-fela';

import { Button } from '../../Button';
import type { ButtonProps } from '../../Button';
import { Icon } from '../../Icon';

import type { TextProps } from '../Text';

import * as felaRules from './Secret.rules';

export interface SecretProps extends TextProps {
    onClick: ButtonProps['onClick'];
    disabled?: ButtonProps['disabled'];
}

export const Secret = ({ children, text, onCopy, disabled = false, onClick }: SecretProps) => {
    const { css } = useFela({ disabled });

    const [show, setShow] = useState(false);

    const toggleShowText = useCallback(() => {
        setShow(s => !s);
    }, []);

    return (
        <div className={css(felaRules.container)}>
            <CopyToClipboard
                text={text}
                onCopy={onCopy}
                options={{
                    format: 'text/plain',
                }}
            >
                <Button
                    data-testid="copy-button-password"
                    type="default"
                    disabled={disabled}
                    onClick={onClick}
                    customStyle={disabled ? felaRules.disabledButton : felaRules.button}
                >
                    <span className={css(felaRules.text)}>
                        {show ? children ?? text : <FormattedMessage id="crypted" />}
                    </span>
                    <Icon type="copy" />
                </Button>
            </CopyToClipboard>
            {disabled ? null : (
                <Icon
                    onClick={toggleShowText}
                    type={!show ? 'eye' : 'eye-close'}
                    color={!show ? 'blue' : 'brightMagenta'}
                    width={24}
                    height={24}
                    customStyle={felaRules.icon}
                />
            )}
        </div>
    );
};
