import { CopyToClipboard } from 'react-copy-to-clipboard';
import type { Props as CopyToClipboardProps } from 'react-copy-to-clipboard';
import { useFela } from 'react-fela';

import { Button } from '../../Button';
import type { ButtonProps } from '../../Button';
import { Icon } from '../../Icon';

import * as felaRules from './Text.rules';

export interface TextProps extends Omit<CopyToClipboardProps, 'options'> {
    onClick: ButtonProps['onClick'];
    disabled?: ButtonProps['disabled'];
}

export const Text = ({ text, children, onCopy, onClick, disabled = false }: TextProps) => {
    const { css } = useFela();

    return (
        <div className={css(felaRules.container)}>
            <CopyToClipboard
                text={text}
                onCopy={onCopy}
                options={{
                    format: 'text/plain',
                }}
            >
                <Button
                    data-testid="copy-button"
                    type="default"
                    disabled={disabled}
                    onClick={onClick}
                    customStyle={felaRules.button}
                >
                    <span className={css(felaRules.text)}>{children ?? text}</span>
                    <Icon type="copy" />
                </Button>
            </CopyToClipboard>
        </div>
    );
};
