import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';

import { hasReadPermission } from 'utilities/permission';

import { useSharingAvailablility } from 'modules/settings';
import { Heading, Icon, Text } from 'modules/ui';

import type { Secret } from '../../../../types';
import { isSecretShareable } from '../../../../utilities';

import { CopySecretTOTP } from '../../../CopySecretTOTP';
import { CopySecretValue } from '../../../CopySecretValue';

import * as felaRules from './SecretItemInfo.rules';

export interface SecretItemInfoProps extends Secret {
    isActive: boolean;
}

export const SecretItemInfo = ({
    id,
    name,
    hasTOTP,
    share,
    userPermissions,
    isActive,
    hasPassword,
    username,
    favicon,
}: SecretItemInfoProps) => {
    const { css } = useFela();

    const sharingAvailable = useSharingAvailablility();
    const shareable = isSecretShareable(share);

    const hasRead = hasReadPermission(userPermissions);

    return (
        <div className={css(felaRules.container)}>
            <div className={css(felaRules.titleWithFavicon)}>
                {favicon && (
                    <img
                        className={css(felaRules.secretFavicon)}
                        src={`data:image/png;base64,${favicon}`}
                        alt={`${name} favicon`}
                    />
                )}
                <Heading color={isActive ? 'brightMagenta' : 'blue'}>
                    {name}
                    {shareable && sharingAvailable ? (
                        <span className={css(felaRules.share)}>
                            <Icon width="20" height="20" type="share" />
                        </span>
                    ) : null}
                </Heading>
            </div>

            {Boolean(username) ? (
                <Text>
                    <Icon type="user" />
                    <FormattedMessage id="crypted" />
                    <CopySecretValue id={id} property="username" />
                </Text>
            ) : null}
            <div className={css(felaRules.secretsWrapper)}>
                {hasPassword ? (
                    <Text color={hasRead ? 'black' : 'brownGrey3'}>
                        <Icon type="password" />
                        ••••••••
                        <CopySecretValue id={id} property="password" disabled={!hasRead} />
                    </Text>
                ) : null}
                {hasTOTP ? (
                    <Text color={hasRead ? 'black' : 'brownGrey3'}>
                        <Icon type="totp" />
                        ••••••
                        <CopySecretTOTP id={id} disabled={!hasRead} />
                    </Text>
                ) : null}
            </div>
        </div>
    );
};
