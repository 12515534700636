import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    '&.ant-form-item': {
        marginBottom: 0,

        min768: {
            marginBottom: 0,
        },
    },

    '& .ant-row.ant-form-item-row': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    '& .ant-form-item-label': {
        paddingLeft: 0,
        paddingRight: 0,
    },

    '& .ant-form-item-control': {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 'fit-content',
    },
});

export const label: TRuleWithTheme = ({ theme }) => ({
    fontSize: '0.875rem',
    lineHeight: '1.43em',
    letterSpacing: '0.0143em',

    color: theme.colors.black,
});
