import { useState, useRef, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { actions as messagesActions } from 'modules/messages';

const options = {
    duration: 2,
};

export const useCopyToClipboard = () => {
    const dispatch = useDispatch();

    const intl = useIntl();

    const [copied, setCopied] = useState(false);

    const timeout = useRef<number>();

    const removeTimeout = useCallback(() => {
        if (timeout.current) {
            window.clearTimeout(timeout.current);
        }
    }, []);

    const addTimeout = useCallback(() => {
        removeTimeout();
        timeout.current = window.setTimeout(() => {
            setCopied(false);
        }, 1000);
    }, [removeTimeout]);

    const handleCopy = useCallback(
        (e, success) => {
            if (success) {
                setCopied(true);
                addTimeout();
            }
        },
        [addTimeout],
    );

    useEffect(
        () => () => {
            removeTimeout();
        },
        [removeTimeout],
    );

    useEffect(() => {
        if (copied) {
            dispatch(
                messagesActions.displaySuccessMessage({
                    message: intl.formatMessage({ id: 'copied' }),
                    options,
                }),
            );
        }
    }, [copied, dispatch, intl]);

    return { copied, handleCopy };
};
