import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';

import { Heading, Icon, Text } from 'modules/ui';
import { FormItem, Label, TextInput } from 'modules/forms';
import { Modal } from 'modules/modals';

// To fix circular deps between secrets and settings modules
import { useDeleteSecrets } from 'modules/secrets/hooks/useDeleteSecrets';

import * as felaRules from './DeleteSecretsModal.rules';

export interface DeleteSecretsModalProps {
    open: boolean;
    onClose: () => void;
}

const validate = (intl: IntlShape, value: string) =>
    value ===
    intl.formatMessage({
        id: 'settings.secrets.delete.keyword',
    });

export const DeleteSecretsModal = ({ open, onClose }: DeleteSecretsModalProps) => {
    const { api, handleDelete } = useDeleteSecrets();

    const intl = useIntl();

    const [value, setValue] = useState('');

    const valid = validate(intl, value);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
    };

    const handleSubmit = () => {
        handleDelete();
    };

    useEffect(() => {
        if (!open) {
            setValue('');
        }
    }, [open]);

    return (
        <Modal
            open={open}
            width={474}
            cancelButton={{
                onClick: onClose,
                disabled: api.inProgress,
                children: <FormattedMessage id="settings.secrets.delete.cancel" />,
            }}
            okButton={{
                type: 'primary',
                disabled: !valid || api.inProgress,
                loading: api.inProgress,
                onClick: handleSubmit,
                children: <FormattedMessage id="settings.secrets.delete.submit" />,
            }}
            alignButtonsRight
        >
            <Heading elementType="h2" color="black" customStyle={felaRules.title}>
                <Icon type="trash" color="alertsError" />
                <FormattedMessage id="settings.secrets.delete.title" />
            </Heading>
            <Text size="small" color="foregroundsTertiary" customStyle={felaRules.text}>
                <FormattedMessage id="settings.secrets.delete.text" />
            </Text>
            <FormItem
                label={
                    <Label type="dark">
                        <FormattedMessage id="settings.secrets.delete.label" />
                    </Label>
                }
                customStyle={felaRules.formItem}
            >
                <TextInput value={value} onChange={handleChange} />
            </FormItem>
        </Modal>
    );
};
