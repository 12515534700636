import { FormattedMessage } from 'react-intl';

import { Label, Slider } from 'modules/forms';
import type { SliderProps } from 'modules/forms';

import * as felaRules from './GeneratePasswordLength.rules';

export interface GeneratePasswordLengthProps {
    value: number;
    onChange: SliderProps['onChange'];
}

export const GeneratePasswordLength = ({ onChange, value }: GeneratePasswordLengthProps) => (
    <>
        <Label type="dark" customStyle={felaRules.label}>
            <FormattedMessage id="secret.generate.length" values={{ length: value }} />
        </Label>
        <Slider min={4} max={50} value={value} onChange={onChange} />
    </>
);
