import { all } from 'redux-saga/effects';

import createSecret from './createSecret';
import readSecret from './readSecret';
import updateSecret from './updateSecret';
import removeSecret from './removeSecret';

import loadSecretsFlow from './loadSecretsFlow';
import deleteSecrets from './deleteSecrets';

import copySecretProperty from './copySecretProperty';
import ignoreReportSecret from './ignoreReportSecret';
import shareSecret from './shareSecret';
import readShareSecret from './readShareSecret';

import getSecretTOTP from './getSecretTOTP';

import fetchViewFilter from './fetchViewFilter';

export function* saga() {
    yield all([
        loadSecretsFlow(),
        deleteSecrets(),
        createSecret(),
        readSecret(),
        updateSecret(),
        removeSecret(),
        copySecretProperty(),
        ignoreReportSecret(),
        shareSecret(),
        readShareSecret(),
        getSecretTOTP(),
        fetchViewFilter(),
    ]);
}
