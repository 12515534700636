import { useBreakpoint } from 'hooks/useBreakpoint';

import { SidebarDesktop } from '../SidebarDesktop';
import { SidebarMobile } from '../SidebarMobile';

export const Sidebar = () => {
    const isMin1440 = useBreakpoint('lg');

    if (isMin1440) {
        return <SidebarDesktop />;
    }

    return <SidebarMobile />;
};
