import { useCallback } from 'react';

import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';

import { Secret } from './Secret';
import { Text } from './Text';
import type { TextProps } from './Text';

export enum CopyButtonType {
    TEXT = 'text',
    SECRET = 'secret',
}

export interface CopyButtonProps extends Pick<TextProps, 'text' | 'disabled' | 'children'> {
    type?: `${CopyButtonType}`;
}

export const CopyButton = ({ type = CopyButtonType.TEXT, ...props }: CopyButtonProps) => {
    const { handleCopy } = useCopyToClipboard();

    const handleButton = useCallback(e => {
        e.stopPropagation();
    }, []);

    let Component = Text;

    if (type === CopyButtonType.SECRET) {
        Component = Secret;
    }

    return <Component onCopy={handleCopy} onClick={handleButton} {...props} />;
};
