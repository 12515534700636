import { combineRules } from 'fela';

import type { TRuleWithTheme } from 'styles/theme';

export const text: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',

    marginRight: '-1.5rem',
    marginLeft: '-1.5rem',

    paddingTop: '0.5rem',
    paddingRight: '1.5rem',
    paddingBottom: '0.5rem',
    paddingLeft: '1.5rem',
});

export const link = combineRules(text, (({ theme }) => ({
    '&.active': {
        backgroundColor: theme.colors.paleMauve,

        textDecoration: 'none',
    },
    '&.active:hover': {
        cursor: 'default',
    },
})) as TRuleWithTheme);

export const children: TRuleWithTheme = () => ({
    verticalAlign: 'middle',
});

export const icon: TRuleWithTheme = () => ({
    flexShrink: 0,
    marginRight: '.625rem',
});

export const linkIcon: TRuleWithTheme = () => ({
    marginLeft: '.25rem',

    verticalAlign: 'text-top',
});
