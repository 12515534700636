import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';
import type { ReactNode } from 'react';

import alertSrc from 'assets/images/subscription_alert.svg';
import { Heading, Text } from 'modules/ui';

import type { FormattedMessageProps } from 'modules/localizations/translations/types';

import * as felaRules from './SubscriptionAlert.rules';

export interface SubscriptionAlertProps {
    title: FormattedMessageProps;
    text: FormattedMessageProps;
    endContent: ReactNode;
}

export const SubscriptionAlert = ({ title, text, endContent }: SubscriptionAlertProps) => {
    const { css } = useFela();

    return (
        <div className={css(felaRules.alert)}>
            <img src={alertSrc} alt="" />
            <div className={css(felaRules.wrapper)}>
                <Heading elementType="h1" customStyle={felaRules.title}>
                    <FormattedMessage {...title} />
                </Heading>
                <Text customStyle={felaRules.text}>
                    <FormattedMessage {...text} />
                </Text>
                <div>{endContent}</div>
            </div>
        </div>
    );
};
