import { useState } from 'react';
import useToggle from 'react-use-toggle';

import { useBreakpoint } from 'hooks/useBreakpoint';

import { MenuMobile } from 'modules/layout';
import { useIsAdmin } from 'modules/auth';
import { AdminSecretsView, UserSecretsView } from 'modules/secrets';
import { UserGuideSecretsView } from 'modules/user-guide';

import { SidebarContent } from '../SidebarContent';

const mobileAlignOffset: [number, number] = [0, 20];
export const desktopAlignOffset: [number, number] = [40, 0];

export const SidebarMobile = () => {
    const [menuOpen, toggleMenu] = useToggle(false);
    // showGuide is almost in sync with menuOpen but it switches with animation
    const [showGuide, setShowGuide] = useState(menuOpen);

    const isAdmin = useIsAdmin();

    const isMin768 = useBreakpoint('sm');

    return (
        <MenuMobile
            open={menuOpen}
            openMenu={toggleMenu}
            closeMenu={toggleMenu}
            onAnimationFinised={open => {
                setShowGuide(open);
            }}
        >
            {isAdmin ? (
                <UserGuideSecretsView
                    show={showGuide}
                    placement={isMin768 ? 'rightTop' : 'bottomLeft'}
                    align={{
                        offset: isMin768 ? desktopAlignOffset : mobileAlignOffset,
                    }}
                >
                    <AdminSecretsView />
                </UserGuideSecretsView>
            ) : (
                <UserSecretsView />
            )}
            <SidebarContent />
        </MenuMobile>
    );
};
