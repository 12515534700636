import { useSelector } from 'react-redux';

import { useIsAdmin } from 'modules/auth';

import { selectGeneralSettings } from '../services/selectors';
import { SharingPermission } from '../constants';

export const useSharingAvailablility = () => {
    const isAdmin = useIsAdmin();

    const { sharingPermission } = useSelector(selectGeneralSettings);

    if (sharingPermission === SharingPermission.ADMIN_ONLY) {
        return isAdmin;
    }

    return sharingPermission === SharingPermission.EVERYONE;
};
