import type { ClosablePageProps } from 'modules/ui';

export const style: ClosablePageProps['closeButton']['customStyle'] = () => ({
    cursor: 'default',

    animationName: {
        from: {
            transform: 'rotate(0deg)',
        },
        to: {
            transform: 'rotate(-360deg)',
        },
    },
    animationDuration: '2500ms',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
});
