import { useIntl } from 'react-intl';

import { IconButton, Tooltip } from 'modules/ui';

import * as felaRules from './RevertChangesButton.rules';

export const RevertChangesButton = ({ onClick }) => {
    const intl = useIntl();

    return (
        <Tooltip title={intl.formatMessage({ id: 'secret.form.revert' })} placement="bottom">
            <IconButton
                aria-label={intl.formatMessage({ id: 'secret.form.revert' })}
                icon="revert-changes"
                onClick={onClick}
                customStyle={felaRules.button}
            />
        </Tooltip>
    );
};
