import { createAction } from '@reduxjs/toolkit';

import type { CopyableSecretKeys, Secret } from '../../types';
import types from '../actionTypes';

export const copySecret = createAction(types.COPY_SECRET, (id: Secret['id'], property: CopyableSecretKeys) => ({
    meta: {
        id,
        property,
    },
    payload: undefined,
}));

export const copySecretSuccess = createAction(
    types.COPY_SECRET_SUCCESS,
    (id: Secret['id'], property: CopyableSecretKeys, value: any = undefined) => ({
        meta: {
            id,
            property,
        },
        payload: value,
    }),
);

export const copySecretFailure = createAction(
    types.COPY_SECRET_FAILURE,
    (id: Secret['id'], property: CopyableSecretKeys, error: any) => ({
        meta: {
            id,
            property,
        },
        payload: undefined,
        error,
    }),
);

export const copySecretReset = createAction(
    types.COPY_SECRET_RESET,
    (id: Secret['id'], property: CopyableSecretKeys) => ({
        meta: {
            id,
            property,
        },
        payload: undefined,
    }),
);
