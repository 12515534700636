import { lazy, Suspense } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFela } from 'react-fela';
import { Field } from 'react-final-form';
import { identity } from 'lodash';
import useToggle from 'react-use-toggle';

import { Icon, Button } from 'modules/ui';
import { Label, FormItem, TextInput, PasswordInput } from 'modules/forms';

import { Fields } from '../../../constants';

import { GeneratePasswordWidget } from '../../GeneratePasswordWidget';

import * as felaRules from './PasswordField.rules';

const PasswordStrength = lazy(() =>
    import('../PasswordStrength').then(imports => ({
        default: imports.PasswordStrength,
    })),
);

export interface PasswordFieldProps {
    onChange?: () => void;
}

export const PasswordField = ({ onChange }: PasswordFieldProps) => {
    const { css } = useFela();

    const [hidden, toggle] = useToggle(true);
    const [display, toggleGeneratePassword] = useToggle(false);

    const name = Fields.PASSWORD;
    const label = (
        <Label>
            <FormattedMessage id={`secret.${name}`} />
        </Label>
    );

    return (
        <Field<string> name={name} format={identity} parse={identity}>
            {({ input, meta }) => {
                const { value, onFocus, onBlur } = input;

                const handleChange = (e: React.ChangeEvent<HTMLInputElement> | string) => {
                    const value = typeof e === 'string' ? e : e.target.value;

                    input.onChange(value);
                    onChange?.();
                };

                const handleGenerate = (password: string) => {
                    input.onFocus();
                    input.onChange(password);
                    input.onBlur();

                    onChange?.();
                };

                return (
                    <>
                        <div className={css(felaRules.wrapper)}>
                            <div className={css(felaRules.container)}>
                                <FormItem<string>
                                    htmlFor={name}
                                    label={label}
                                    customStyle={felaRules.formItem}
                                    meta={meta}
                                >
                                    {hidden ? (
                                        <TextInput
                                            id={name}
                                            value={value}
                                            onFocus={onFocus}
                                            onBlur={onBlur}
                                            onChange={handleChange}
                                            type="password"
                                            suffix={<Icon onClick={toggle} type="eye" color="blue" />}
                                            customStyle={felaRules.textInput}
                                        />
                                    ) : (
                                        <PasswordInput
                                            id={name}
                                            value={value}
                                            onFocus={onFocus}
                                            onBlur={onBlur}
                                            onChange={handleChange}
                                            suffix={<Icon onClick={toggle} type="eye-close" color="brightMagenta" />}
                                            customStyle={felaRules.textInput}
                                        />
                                    )}
                                    <Suspense fallback={null}>
                                        <PasswordStrength value={value} />
                                    </Suspense>
                                </FormItem>
                            </div>
                            <Button
                                type="link"
                                size="small"
                                htmlType="button"
                                onClick={toggleGeneratePassword}
                                customStyle={felaRules.button}
                            >
                                <FormattedMessage id="generatePassword" />
                            </Button>
                        </div>
                        {display ? (
                            <GeneratePasswordWidget
                                password={input.value}
                                onClose={toggleGeneratePassword}
                                onSave={handleGenerate}
                            />
                        ) : null}
                    </>
                );
            }}
        </Field>
    );
};
