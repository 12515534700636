import type { ReactNode } from 'react';
import { useFela } from 'react-fela';

import { Label } from 'modules/forms';

import { container, label } from './SecretSection.rules';

export interface SecretSectionProps {
    heading?: ReactNode;
    children: ReactNode;
}

export const SecretSection = ({ heading = null, children }: SecretSectionProps) => {
    const { css } = useFela();

    return (
        <div className={css(container)}>
            {heading && (
                <Label customStyle={label} elementType="h2">
                    {heading}
                </Label>
            )}
            {children}
        </div>
    );
};
