import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'minmax(0, max-content)',
    gap: '1rem',

    marginTop: '0.5rem',
    marginBottom: '0.5rem',

    ':last-child': {
        marginBottom: '0',
    },
});

export const desktopUserItem: TRuleWithTheme = () => ({
    display: 'none',

    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    min768: {
        display: 'block',
    },
});

export const mobileUserItem: TRuleWithTheme = () => ({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    min768: {
        display: 'none',
    },
});
