import tinyColor from 'tinycolor2';

const info = '#1890ff';
const red = '#f5222d';

const baseColors = {
    accentTertiary: '#2FD894',

    foregroundsSeparator: '#EBEBEB',

    backgroundsTertiary: '#F8F8FF',

    alertsWarning: '#ffb733',
    alertsError: '#f92525',

    iconsSecondary: '#9B9B9B',

    white: '#ffffff',
    black: '#000000',

    brownGrey: '#808080',
    brownGrey2: '#9b9b9b',
    brownGrey3: '#bfbfbf',
    brownGrey4: '#666666',

    lightGrey: ' #ebebeb',
    lightGrey2: '#f8f7f6',
    lightGrey3: '#d9d9d9',
    lightGrey4: '#e0e0e0',
    lightGrey5: '#ececec',

    blue: '#0000ff',
    hoverBlue: '#0000c2',
    overlayBlue: '#f4f4ff',

    brightMagenta: '#ff00eb',
    hoverBrightMagenta: tinyColor('#ff00eb').darken(5).toString(),
    paleMauve: '#fff0fe',

    lightGreen: '#70dcb1',
    lightPink: '#ffe5fc',
    green: '#2fd894',
    red,
    hoverRed: '#c70000',
    orange: '#ff9900',
    lime: '#e2ffd1',
    yellow: '#feff00',

    overlay: 'rgba(244, 244, 255, 0.7)',
    password: {
        0: `#f92525`,
        1: `#f95225`,
        2: `#ffb733`,
        3: `#3333ff`,
        4: `#2fd894`,
    },

    messageIcons: {
        success: '#2FD894',
        error: red,
        info,
        warning: '#faad14',
        loading: info,
    },

    report: {
        red: '#ff0000',
        yellow: '#ffd600',
        grey: '#9b9b9b',
    },

    bgGrey: `#F8F7F6`,
    columnGraphLabel: `#9B9B9B`,

    // New design system colors
    foregroundsTertiary: '#737580',
} as const;

const clientCustomColors: typeof baseColors = (() => {
    const colors = process.env.REACT_APP_THEME_COLORS;
    return JSON.parse(colors || '{}');
})();

const colors = {
    ...baseColors,
    ...clientCustomColors,
};

export default colors;
