import { forwardRef } from 'react';
import { useFela } from 'react-fela';

import { SecretsSearch } from '../SecretsSearch';
import { SecretsCountAlert } from '../SecretsCountAlert';
import { Secrets } from '../Secrets';
import { AddSecretButton } from '../AddSecretButton';

import type { SecretsListHandle } from '../SecretsList';

import { container, metaWrapper } from './SecretsWithFilter.rules';

export const SecretsWithFilter = forwardRef<SecretsListHandle>((_, ref) => {
    const { css } = useFela();

    return (
        <div className={css(container)}>
            <div className={css(metaWrapper)}>
                <SecretsSearch />
                <SecretsCountAlert />
                <AddSecretButton />
            </div>
            <Secrets ref={ref} />
        </div>
    );
});
