import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',

    marginTop: '0.875rem',
});

export const remainingTime: TRuleWithTheme = ({ theme }) => ({
    position: 'relative',
    top: -1,

    fontSize: '0.75rem',
    fontWeight: 600,
    lineHeight: 'normal',

    color: theme.colors.accentTertiary,
});

export const validity: TRuleWithTheme = () => ({
    marginLeft: '0.5rem',

    fontWeight: 500,
});
