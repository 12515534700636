import type { TRuleWithTheme } from 'styles/theme';

export const style: TRuleWithTheme = ({ theme }) => ({
    alignSelf: 'flex-end',
    flexShrink: 0,

    display: 'flex',
    alignItems: 'center',

    height: '1.5rem',

    paddingRight: '3rem',

    marginBottom: '0.895rem',

    fontSize: '0.875rem',

    color: theme.colors.brownGrey2,

    min768: {
        alignSelf: 'flex-start',

        minWidth: '10rem',

        paddingTop: 0,
        paddingLeft: '3rem',
        paddingBottom: 0,

        marginBottom: 0,
    },
});
