import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    '& > label': {
        marginTop: 10,
        color: theme.colors.black,
        fontSize: '0.8rem',
        min768: {
            fontSize: '0.9rem',
        },
    },
});
