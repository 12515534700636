import type { TRuleWithTheme } from 'styles/theme';

export const wrapper: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'center',
    alignContent: 'space-between',
    gap: '0.75rem',
});

export const input: TRuleWithTheme = ({ theme }) => ({
    overflow: 'hidden',
    whiteSpace: 'nowrap',

    flex: 1,

    width: '100%',

    marginTop: '1rem',
    marginBottom: '0.75rem',

    paddingTop: 'calc(0.5rem + 1px)',
    paddingRight: '0.75rem',
    paddingBottom: 'calc(0.5rem - 1px)',
    paddingLeft: '0.75rem',

    borderColor: theme.colors.lightGrey3,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: '0.25rem',

    backgroundColor: theme.colors.white,
});

export const validUntil: TRuleWithTheme = () => ({
    '::after': {
        content: '": "',
        display: 'inline',
    },
});

export const reset: TRuleWithTheme = () => ({
    display: 'block',

    marginTop: '0.25rem',
});
