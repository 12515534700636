import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { IconButton } from 'modules/ui';
import { actions as messagesActions } from 'modules/messages';

const options = {
    duration: 1,
};

export const CopyUrlSecretButton = () => {
    const dispatch = useDispatch();

    const intl = useIntl();

    return (
        <CopyToClipboard
            text={window.location.toString()}
            onCopy={(_, done) => {
                if (done) {
                    dispatch(
                        messagesActions.displaySuccessMessage({
                            message: intl.formatMessage({ id: 'success.secrets.share' }),
                            options,
                        }),
                    );
                } else {
                    dispatch(
                        messagesActions.displayErrorMessage({
                            message: intl.formatMessage({ id: 'error.secrets.share' }),
                            options,
                        }),
                    );
                }
            }}
            options={{
                format: 'text/plain',
            }}
        >
            <IconButton icon="url" />
        </CopyToClipboard>
    );
};
