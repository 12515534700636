import type { ReactNode } from 'react';
import { useCallback, useEffect } from 'react';
import { useFela } from 'react-fela';
import { useHistory } from 'react-router-dom';

import { config } from 'config/config';

import { Icon } from '../../Icon';

import { style } from './CloseButton.rules';

export interface CloseButtonProps {
    withBackground?: boolean;
    sticky?: boolean;
    close?: () => void;
    closeRoute?: string;
    renderButton?: (handleClick: () => void, customStyle: typeof style) => ReactNode;
    customStyle?: typeof style;
}

export const CloseButton = ({
    withBackground = false,
    sticky = false,
    close = null,
    closeRoute = config.routes.home,
    renderButton,
    customStyle,
}: CloseButtonProps) => {
    const { push } = useHistory();

    const { css } = useFela({
        withBackground,
        sticky,
    });

    const handleClick = useCallback(() => {
        if (close) {
            close();
        } else {
            push(closeRoute);
        }
    }, [close, closeRoute, push]);

    useEffect(() => {
        const handler = (e: KeyboardEvent) => {
            if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
                handleClick();
            }
        };

        document.addEventListener('keydown', handler);
        return () => document.removeEventListener('keydown', handler);
    }, [handleClick]);

    if (renderButton) {
        return <>{renderButton(handleClick, style)}</>;
    }

    return (
        <button className={css(style, customStyle)} type="button" onClick={handleClick}>
            <Icon type="close" />
        </button>
    );
};
