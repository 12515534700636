import { FormattedMessage } from 'react-intl';

import { ConfirmationModal } from 'modules/modals';

import { useNewSecretModal } from '../../hooks/useNewSecretModal';

export const NewSecretFormModal = () => {
    const { open, valid, submitting, handleSubmit, handleCloseModal, handleRedirect } = useNewSecretModal();

    return (
        <ConfirmationModal
            open={open}
            onClose={() => {
                handleCloseModal();
            }}
            okButton={{
                children: <FormattedMessage id="secret.modal.close.ok" />,
                onClick: () => {
                    handleSubmit();

                    if (!valid) {
                        handleCloseModal();
                    }
                },
                loading: submitting,
            }}
            cancelButton={{
                children: <FormattedMessage id="secret.modal.close.cancel" />,
                onClick: () => {
                    handleCloseModal();
                    handleRedirect();
                },
                disabled: submitting,
            }}
        >
            <FormattedMessage id="secret.modal.close.title" />
        </ConfirmationModal>
    );
};
