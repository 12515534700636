import { generatePath } from 'react-router';
import { useHistory } from 'react-router-dom';

import { config } from 'config/config';

import { IconButton } from 'modules/ui';

export const UpdateSecretButton = ({ id }: { id: string }) => {
    const history = useHistory();

    return (
        <IconButton
            icon="edit"
            type="primary"
            onClick={() => {
                history.push(
                    generatePath(config.routes.updateSecret, {
                        id,
                    }),
                );
            }}
        />
    );
};
