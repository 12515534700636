import { useForm, useFormState } from 'react-final-form';

import type { SecretFormValues } from 'modules/secrets/types';
import { useAutosaveForm } from 'hooks/useAutosaveForm';

const isDirty = (dirtyFields: Record<string, boolean>) => {
    if (dirtyFields.TOTP && dirtyFields.hasTOTP) {
        return Object.keys(dirtyFields).length > 2;
    }

    if (dirtyFields.TOTP) {
        return Object.keys(dirtyFields).length > 1;
    }

    return Object.keys(dirtyFields).length > 0;
};

export const useUpdateSecretForm = () => {
    const { initialize, submit } = useForm<SecretFormValues>();
    const { initialValues, values, dirty, dirtyFields, submitting, submitError } = useFormState<SecretFormValues>();

    const { delayedSubmit, handleSubmit } = useAutosaveForm(submit);

    const handleRevert = () => {
        delayedSubmit.cancel();

        initialize({
            ...initialValues,
            // Never revert TOTP as there is no previous value to revert to, keep the current value.
            TOTP: values.TOTP,
            hasTOTP: values.hasTOTP,
        });

        handleSubmit();
    };

    return {
        dirty: dirty && isDirty(dirtyFields),
        submitting,
        submitError,
        handleRevert,
        handleSubmit: delayedSubmit,
    };
};
