import { useFela } from 'react-fela';

import { hasReadPermission, hasWritePermission } from 'utilities/permission';

import type { Secret } from '../../../types';

import { ShareSecretButton } from '../../ShareSecretButton';
import { CopyUrlSecretButton } from '../../CopyUrlSecretButton';
import { UpdateSecretButton } from '../../UpdateSecretButton';

import { container } from './DetailButtons.rules';

export interface DetailButtonsProps extends Pick<Secret, 'id' | 'share' | 'userPermissions'> {}

export const DetailButtons = ({ id, share, userPermissions }: DetailButtonsProps) => {
    const { css } = useFela();

    return (
        <div className={css(container)}>
            {hasReadPermission(userPermissions) ? <ShareSecretButton id={id} share={share} /> : null}
            <CopyUrlSecretButton />
            {hasWritePermission(userPermissions) ? <UpdateSecretButton id={id} /> : null}
        </div>
    );
};
