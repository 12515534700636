import type { Theme, TRuleWithTheme } from 'styles/theme';

export enum HeadingSize {
    BIG = 'big',
    REGULAR = 'regular',
}

const SIZE_STYLE = {
    [HeadingSize.BIG]: {
        fontSize: '1.5em', // 24px
        lineHeight: '2rem', // 24px
        letterSpacing: '.0375em', // 0.6px
    },
    [HeadingSize.REGULAR]: {
        fontSize: '1.25rem', // 20px
        lineHeight: '1.625rem', // 26px
        letterSpacing: '.0375em', // 0.75px
    },
};

export const container: TRuleWithTheme<{
    size: HeadingSize;
    color: keyof Theme['colors'];
}> = ({ theme, size, color }) => ({
    ...SIZE_STYLE[size],
    color: theme.colors[color] || theme.colors.black,
    fontWeight: '700',

    min768: {
        color: theme.colors[color] || theme.colors.blue,
    },
});
