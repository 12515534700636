import type { ReactNode } from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Popover } from 'antd';
import { useFela } from 'react-fela';

import { Button } from 'modules/ui';

import type { Group } from '../../types';
import { GroupDetail } from '../GroupDetail';

import { button, popover } from './GroupDetailButton.rules';

// If we use name straight, ant-button adds a span we don't want
const Name = ({ children }: { children: ReactNode }) => <>{children}</>;

export interface GroupDetailButtonProps {
    id: Group['id'];
    name?: Group['name'];
}

export const GroupDetailButton = ({ id, name }) => {
    const [visible, setVisible] = useState(false);

    const { css } = useFela();

    return (
        <Popover
            content={<GroupDetail id={id} />}
            trigger="click"
            open={visible}
            onOpenChange={v => {
                setVisible(v);
            }}
            overlayClassName={css(popover)}
        >
            <Button type="link" color="blue" customStyle={name ? button : undefined}>
                {name ? <Name>{name}</Name> : <FormattedMessage id="group.detail" />}
            </Button>
        </Popover>
    );
};
