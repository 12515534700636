import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    marginBottom: '2.5rem',

    '&:last-child': {
        marginBottom: 0,
    },

    min768: {
        marginBottom: '2rem',

        '&:last-child': {
            marginBottom: 0,
        },
    },
});

export const label: TRuleWithTheme = () => ({
    display: 'block',
    marginBottom: '0.5rem',
});
