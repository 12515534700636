import { useFela } from 'react-fela';
import { FormattedMessage, useIntl } from 'react-intl';

import { Switch } from 'modules/forms';
import { Text } from 'modules/ui';

import { useShareSecret } from '../../hooks/useShareSecret';

import type { Secret } from '../../types';

import * as felaRules from './ShareSecretForm.rules';

export interface ShareSecretButtonProps {
    id: Secret['id'];
    share?: Secret['share'];
}

export const ShareSecretForm = ({ id, share }: ShareSecretButtonProps) => {
    const { css } = useFela();

    const intl = useIntl();

    const { inProgress, shared, handleShare } = useShareSecret(id, share);

    return (
        <div className={css(felaRules.form)}>
            <Text elementType="h3">
                <FormattedMessage id="secret.share.info" />
            </Text>
            <Text size="small" color="brownGrey" elementType="p" customStyle={felaRules.description}>
                <FormattedMessage id="secret.share.description" />
            </Text>
            <Switch
                customStyle={felaRules.input}
                aria-label={intl.formatMessage({
                    id: shared ? 'secret.share.disable' : 'secret.share.enable',
                })}
                disabled={inProgress}
                checked={shared}
                onChange={handleShare}
            />
        </div>
    );
};
