import type { CSSProperties } from 'react';

import type { ListSecret, ListGroup } from 'modules/secrets/types';

import { SecretGroupItem } from '../SecretGroupItem';
import { SecretItem } from '../SecretItem';

import { ListItemType } from '../../../constants';

export interface ItemRendererProps {
    className: string;
    style: CSSProperties;
    data: (ListSecret | ListGroup)[];
    index: number;
}

const defaultData = [];

export const ItemRenderer = ({ className, style, data = defaultData, index }: ItemRendererProps) => {
    const item = data[index];

    let inner = null;

    if (item.type === ListItemType.GROUP) {
        const { name } = item;

        inner = <SecretGroupItem name={name} />;
    } else {
        const { type, ...secret } = item;

        inner = <SecretItem {...secret} />;
    }

    return (
        <div className={className} style={style}>
            {inner}
        </div>
    );
};
