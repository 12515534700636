import { createSelector } from '@reduxjs/toolkit';
import { translateSelector } from '@ackee/jerome';
import { isEmpty } from 'lodash';

import latinise from 'voca/latinise';
import isAlpha from 'voca/is_alpha';

import { isValueMatchingSearchTerm } from '../../utilities';
import { GROUP_OTHER, ListItemType } from '../../constants';
import { type ListSecret, type ListGroup, SearchSecretAttributeType } from '../../types';

import { selectSecretsEntities } from './entities';
import { selectActiveSecretsAttributesFilters, selectSecretsNameFilter, selectViewFilterIds } from './secrets';
import { getSecretsWithGroups } from './utilities';

const selectSecretsList = createSelector([selectSecretsEntities], ({ list }) => list);

export const selectMappedSecrets = createSelector(selectSecretsList, ({ byIds, ids }) => ids.map(id => byIds[id]));

export const selectViewFilteredSecrets = createSelector(
    [selectMappedSecrets, selectViewFilterIds],
    (secrets, viewIds) => {
        if (viewIds === null) {
            return secrets;
        }

        return secrets.filter(({ id }) => viewIds.includes(id));
    },
);

const selectSortedSecrets = createSelector(selectViewFilteredSecrets, secrets =>
    secrets
        .map<ListSecret>(secret => {
            const latinisedName = latinise(secret.name);
            let group = latinisedName.toLowerCase().charAt(0);
            if (!isAlpha(group)) {
                group = GROUP_OTHER;
            }
            return {
                ...secret,
                group,
                latinisedName,
                type: ListItemType.SECRET,
                key: secret.id,
            };
        })
        .sort(function (secretA, secretB) {
            const nameA = secretA.latinisedName.toLowerCase();
            const nameB = secretB.latinisedName.toLowerCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        }),
);

const selectSecretsFilteredByAttributes = createSelector(
    selectSortedSecrets,
    selectActiveSecretsAttributesFilters,
    (secrets, activeAttributesFilters) => {
        if (!activeAttributesFilters.length) {
            return secrets;
        }

        return secrets.filter(secret =>
            activeAttributesFilters.every(filter => {
                switch (filter.type) {
                    case SearchSecretAttributeType.TAG:
                        return secret.tags.includes(filter.value);
                    case SearchSecretAttributeType.GROUP:
                        return secret.groups.some(group => group.id === filter.value);
                    default:
                        return false;
                }
            }),
        );
    },
);

const selectFilteredSecrets = createSelector(
    [selectSecretsFilteredByAttributes, selectSecretsNameFilter, translateSelector],
    (secrets, filter, { locale }) =>
        secrets.filter(
            secret =>
                isValueMatchingSearchTerm(secret.name, filter, locale) ||
                isValueMatchingSearchTerm(secret.username, filter, locale),
        ),
);

export const selectFilteredSecretsCount = createSelector(
    [selectFilteredSecrets, selectViewFilteredSecrets],
    (filteredSecrets, viewSecrets) => (filteredSecrets.length < viewSecrets.length ? filteredSecrets.length : null),
);

const createGroup = (group: string): ListGroup => ({
    key: group,
    name: group,
    type: ListItemType.GROUP,
});

export const selectSecretsWithGroups = createSelector(selectFilteredSecrets, secrets =>
    getSecretsWithGroups(
        secrets,
        (nextSecret, currentSecret) => nextSecret.group !== currentSecret.group,
        secret => createGroup(secret.group),
    ),
);

export const selectSecretGroups = createSelector(selectFilteredSecrets, secrets => {
    const secretGroups: string[] = [];
    for (let i = 0; i < secrets.length; i++) {
        if (i === 0 || secrets[i - 1].group !== secrets[i].group) {
            secretGroups.push(secrets[i].group);
        }
    }
    return secretGroups;
});

export const selectHasSecretsWithGroups = createSelector([selectSecretsWithGroups], secrets => !isEmpty(secrets));
