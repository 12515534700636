import { useLayoutEffect, useRef, useState } from 'react';
import type { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFela } from 'react-fela';

import Select from 'react-select/creatable';
import type { CreatableProps } from 'react-select/creatable';

import { Icon, Tag } from 'modules/ui';

import * as felaRules from './TagsInput.rules';

interface Option {
    value: string;
    label: ReactNode;
}

type SelectProps = CreatableProps<
    Option,
    true,
    {
        label?: string;
        options: Option[];
    }
>;

export interface TagsInputProps {
    value?: string[];
    options?: SelectProps['options'];
    onFocus: SelectProps['onFocus'];
    onBlur: SelectProps['onBlur'];
    onChange: (value: string[]) => void;
    onRemove: (value: string) => void;
}

export const mapValueToSelect = (value: TagsInputProps['value']): Option[] =>
    value.map(tag => ({
        value: tag,
        label: tag,
    }));

export const mapSelectValueToIds = (value: Parameters<SelectProps['onChange']>[0]) => value.map(tag => tag.value);

const defaultArray = [];

export const TagsInput = ({
    value = defaultArray,
    options = defaultArray,
    onFocus,
    onChange,
    onBlur,
    onRemove,
}: TagsInputProps) => {
    const intl = useIntl();
    const { css } = useFela();

    const [opened, setOpened] = useState(false);

    // onBlur occurs before onChange on touch devices, that is why
    // we need to modify values directly
    const selectValue = useRef<readonly Option[]>(mapValueToSelect(value));

    useLayoutEffect(() => {
        selectValue.current = mapValueToSelect(value);
    }, [value]);

    return (
        <div
            className={css(felaRules.container)}
            role="none"
            onKeyDown={e => {
                if (e.key === 'Escape') {
                    setOpened(false);
                }
            }}
        >
            {value.map(tag => (
                <Tag
                    closable
                    closeIcon={<Icon type="delete" width={14} height={14} />}
                    key={tag}
                    customStyle={felaRules.tag}
                    onClose={() => {
                        onRemove(tag);
                    }}
                >
                    {tag}
                </Tag>
            ))}
            {opened ? (
                <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    autoFocus
                    isMulti
                    isClearable={false}
                    controlShouldRenderValue={false}
                    value={selectValue.current}
                    onFocus={onFocus}
                    onBlur={e => {
                        onBlur(e);
                        setOpened(false);
                    }}
                    onChange={tempValue => {
                        selectValue.current = tempValue || [];
                        onChange(mapSelectValueToIds(selectValue.current));
                    }}
                    options={options}
                    formatCreateLabel={inputValue =>
                        intl.formatMessage(
                            {
                                id: 'form.field.tags.create',
                            },
                            {
                                value: inputValue,
                            },
                        )
                    }
                    placeholder=""
                />
            ) : (
                <Tag
                    tabIndex={0}
                    icon={<Icon type="new" customStyle={felaRules.newIcon} />}
                    customStyle={felaRules.newTag}
                    onFocus={() => {
                        setOpened(true);
                    }}
                    onClick={() => {
                        setOpened(true);
                    }}
                >
                    <FormattedMessage id="tags.new" />
                </Tag>
            )}
        </div>
    );
};
