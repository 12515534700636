import copy from 'copy-to-clipboard';
import { put, delay } from 'redux-saga/effects';

import { api } from 'config/antonio';
import { config } from 'config/config';
import { logger } from 'config/logger';

import { takeLatestRequest } from 'services/utilities/takeRequest';

import { actions as messagesActions } from 'modules/messages';

import type { Secret, TOTP } from '../../types';
import { COPY_RESET_DELAY } from '../../constants';
import { getTOTPDuration } from '../../utilities/totp';

import { copySecretSuccess, copySecretFailure, copySecretReset, copySecret } from '../actions';

function* handler(action: ReturnType<typeof copySecret>, signal: AbortSignal) {
    const { meta } = action;

    try {
        let value = '';
        let delayMs = COPY_RESET_DELAY;
        let fullValue: any;

        if (meta.property === 'TOTP') {
            const { data } = yield* api.get<TOTP>(config.api.totp, {
                uriParams: {
                    id: meta.id,
                },
                signal,
            });

            const totp = data[0];

            value = totp.code;
            delayMs = getTOTPDuration(totp) * 1000;
            fullValue = totp;
        } else {
            const { data } = yield* api.get<Secret>(config.api.secret, {
                uriParams: {
                    id: meta.id,
                },
                signal,
            });

            value = data[meta.property];
            fullValue = value;
        }

        if (value) {
            if (!copy(value)) {
                throw new Error('Not copied');
            }

            yield put(copySecretSuccess(meta.id, meta.property, fullValue));

            yield delay(delayMs);
        } else {
            yield put(
                messagesActions.displayWarningMessage({
                    message: { id: `warning.secrets.copy.${meta.property}` },
                }),
            );
        }

        yield put(copySecretReset(meta.id, meta.property));
    } catch (e) {
        logger.error(e);

        yield put(copySecretFailure(meta.id, meta.property, e));
    }
}

export default function* () {
    yield takeLatestRequest(
        {
            actionCreator: copySecret,
            cancelActionFunction: action => copySecretReset(action.meta.id, action.meta.property),
            idSelector: action => `${action.meta.id}-${action.meta.property}`,
        },
        handler,
    );
}
