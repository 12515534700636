import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { config } from 'config/config';

import { IconButton } from 'modules/ui';

import { useSaasCountReached } from '../../hooks/useSaasCountReached';

import { container } from './AddSecretButton.rules';

export const AddSecretButton = () => {
    const history = useHistory();
    const intl = useIntl();

    const { isError } = useSaasCountReached();

    return (
        <IconButton
            type="primary"
            icon="add"
            disabled={isError}
            customStyle={container}
            aria-label={intl.formatMessage({
                id: 'secret.list.add',
            })}
            onClick={() => {
                history.push(config.routes.newSecret);
            }}
        />
    );
};
