import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme<{
    isActive: boolean;
}> = ({ theme, isActive }) => ({
    display: 'block',
    height: '100%',

    paddingTop: '1.5rem',
    paddingRight: '1.5rem',
    paddingBottom: '1.5rem',
    paddingLeft: '1.5rem',

    backgroundColor: isActive ? theme.colors.paleMauve : theme.colors.white,
    borderBottomColor: theme.colors.lightGrey,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',

    min768: {
        display: 'flex',

        paddingTop: '1.5rem',
        paddingRight: '2.5rem',
        paddingBottom: '1.5rem',
        paddingLeft: '2.5rem',

        ':hover': {
            cursor: 'pointer',
            backgroundColor: theme.colors.paleMauve,
            '& .Heading': {
                color: theme.colors.brightMagenta,
            },
        },
    },
});
