import type { TRuleWithTheme } from 'styles/theme';

const borderSize = 1;

export const container: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',

    height: '100%',
    paddingRight: '2.5rem',
    paddingLeft: '2.5rem',

    borderBottom: `${borderSize}px solid ${theme.colors.lightGrey}`,

    '& .Heading': {
        fontSize: '1.5rem',
        fontWeight: '500',
        lineHeight: '1.5rem',

        color: theme.colors.brownGrey2,
    },

    min768: {
        paddingRight: '3.75rem',
        paddingLeft: '3.75rem',
    },
});
