import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';

import { Modal } from 'modules/modals';
import { Heading } from 'modules/ui';

import type { Secret } from '../../types';
import { isSecretShareable } from '../../utilities';

import { ShareSecretForm } from '../ShareSecretForm';
import { ShareSecretInfo } from '../ShareSecretInfo';

import * as felaRules from './ShareSecretModal.rules';

export interface ShareSecretButtonProps {
    id: Secret['id'];
    share?: Secret['share'];
    open: boolean;
    onClose: () => void;
}

export const ShareSecretModal = ({ id, share, open, onClose }: ShareSecretButtonProps) => {
    const { css } = useFela();

    return (
        <Modal
            open={open}
            closable
            width={474}
            cancelButton={{
                onClick: onClose,
                children: <FormattedMessage id="secret.modal.share.cancel" />,
            }}
            extend={{
                buttons: felaRules.buttons,
            }}
        >
            <Heading elementType="h2" color="black">
                <FormattedMessage id="secret.modal.share.title" />
            </Heading>
            <div className={css(felaRules.container)}>
                <ShareSecretForm id={id} share={share} />
                {share && isSecretShareable(share) ? <ShareSecretInfo id={id} share={share} /> : null}
            </div>
        </Modal>
    );
};
