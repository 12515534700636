import { useEffect, useRef, useState } from 'react';
import type { ReactNode } from 'react';
import { useFela } from 'react-fela';

import * as felaRules from './ClosablePage.rules';

import { CloseButton } from './CloseButton';
import type { CloseButtonProps } from './CloseButton';
import { ContentClosePage } from './ContentClosePage';
import { HeaderClosePage } from './HeaderClosePage';

const OFFSET_TOP = 0;

export interface ClosablePageProps {
    children: ReactNode;
    header?: ReactNode;
    closeButton?: CloseButtonProps;
}

export const ClosablePage = ({ children, closeButton, header }: ClosablePageProps) => {
    const { css } = useFela({ header });

    const [scrolled, setScrolled] = useState(false);

    const ref = useRef<HTMLDivElement>();
    const scrolledRef = useRef(scrolled);

    useEffect(() => {
        scrolledRef.current = scrolled;
    }, [scrolled]);

    useEffect(() => {
        const handleScroll = () => {
            const offsetTop = ref.current.scrollTop;

            const { current: scrolled } = scrolledRef;

            if (offsetTop > OFFSET_TOP && !scrolled) {
                setScrolled(true);
            } else if (offsetTop === OFFSET_TOP && scrolled) {
                setScrolled(false);
            }
        };

        const { current } = ref;

        current.addEventListener('scroll', handleScroll);

        return () => {
            current.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <ContentClosePage>
            {header ? <HeaderClosePage>{header}</HeaderClosePage> : null}
            <div ref={ref} className={css(felaRules.container)}>
                <CloseButton withBackground={scrolled} sticky={Boolean(header)} {...closeButton} />
                {children}
            </div>
        </ContentClosePage>
    );
};
