import { IS_DEMO, IS_SAAS } from 'constants/index';

import { config } from 'config/config';

import { useBreakpoint } from 'hooks/useBreakpoint';
import { useTidio } from 'modules/chat/hooks';

import { ExtensionPrompt, shouldDisplayExtensionPromo } from 'modules/extension';
import { UserFeedbackPrompt } from 'modules/user-feedback';

import { useFetchSettings } from 'modules/settings';

import { DesktopApplication } from '../DesktopApplication';
import { ResponsiveApplication } from '../ResponsiveApplication';

const isClientInstance = !IS_DEMO && !IS_SAAS;

export const AuthorizedApplication = () => {
    useFetchSettings();

    useTidio();

    const isMin1024 = useBreakpoint('md');

    return (
        <>
            {shouldDisplayExtensionPromo && isClientInstance ? <ExtensionPrompt /> : null}
            {config.demo.enable ? null : <UserFeedbackPrompt />}
            {isMin1024 ? <DesktopApplication /> : <ResponsiveApplication />}
        </>
    );
};
