import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    marginTop: '1.25rem',

    whiteSpace: 'nowrap',
    overflow: 'hidden',

    min768: {
        marginTop: 0,
        marginRight: 0,
        marginLeft: '1rem',

        textAlign: 'right',

        '> .TextTag': {
            display: 'block',
        },
    },
});
