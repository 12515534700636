import { forwardRef } from 'react';

import { useAppSelector } from 'hooks/useAppSelector';

import { selectHasSecretsWithGroups } from '../../services/selectors';

import { SecretsListContainer } from '../SecretsListContainer';

import { SecretsList } from '../SecretsList';
import type { SecretsListHandle } from '../SecretsList';
import { SecretsListEmpty } from '../SecretsListEmpty';

export const Secrets = forwardRef<SecretsListHandle>((_, ref) => {
    const hasSecrets = useAppSelector(selectHasSecretsWithGroups);

    return (
        <SecretsListContainer isEmpty={!hasSecrets} empty={<SecretsListEmpty />}>
            {({ height, width }) => <SecretsList ref={ref} height={height} width={width} />}
        </SecretsListContainer>
    );
});
