import { Redirect, Route, Switch } from 'react-router-dom';

import config from 'config';

import { AdminFirewall } from 'modules/auth';

import { NonDemoFirewall } from 'modules/auth';

import { Settings } from '../Settings';
import { TagsSettings } from '../TagsSettings';
import { GroupsVisibilitySettings } from '../GroupsVisibilitySettings';

export const SettingsPage = () => (
    <NonDemoFirewall>
        <AdminFirewall firewall={<Redirect to={config.routes.home} />}>
            <Switch>
                <Route exact path={config.routes.settings} render={() => <Settings />} />
                <Route exact path={config.routes.tagsSettings} render={() => <TagsSettings />} />
                <Route
                    exact
                    path={config.routes.groupsVisibilitySettings}
                    render={() => <GroupsVisibilitySettings />}
                />
            </Switch>
        </AdminFirewall>
    </NonDemoFirewall>
);
