import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'center',
});

export const totp: TRuleWithTheme = ({ theme }) => ({
    overflow: 'auto',

    paddingTop: '0.625rem',
    paddingRight: '1.25rem',
    paddingBottom: '0.625rem',
    paddingLeft: '1.25rem',

    marginRight: '0.625rem',

    borderColor: theme.colors.black,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: '1.25rem',

    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
});

export const title: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'center',

    gap: '0.5rem',

    marginBottom: '0.75rem',
});

export const text: TRuleWithTheme = () => ({
    marginBottom: '1.75rem',
});
