import type theme from 'styles/theme';
import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme<{
    closable: boolean;
    hasIcon: boolean;
    color?: keyof typeof theme.colors | 'currentColor';
}> = ({ theme, closable, hasIcon, color }) => ({
    '&.ant-tag': {
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: '0.25rem',

        height: '1.375rem',

        paddingLeft: '0.5rem',
        paddingRight: '0.5rem',

        marginRight: 0,

        fontSize: '.875rem', // 14px
        lineHeight: '1em', // 14px
        letterSpacing: '0.0357em',

        color: color === 'currentColor' ? color : theme.colors[color] ?? theme.colors.black,
        borderColor: 'currentColor',
        backgroundColor: 'transparent',
    },
    '&.ant-tag .ant-tag-close-icon': {
        display: 'flex',

        ...(hasIcon
            ? {
                  marginRight: 0,
                  marginLeft: '0.5rem',
              }
            : {
                  marginRight: '0.25rem',
                  marginLeft: 0,
              }),

        color: 'currentColor',
    },
    '&.ant-tag:hover': {
        opacity: '1',
    },
    '&.ant-tag-checkable': {
        backgroundColor: theme.colors.white,
        borderColor: 'currentcolor',
    },
    '&.ant-tag-checkable-checked': {
        color: theme.colors.white,
        borderColor: theme.colors.brightMagenta,
        backgroundColor: theme.colors.brightMagenta,
    },
    '&.ant-tag-checkable:not(.ant-tag-checkable-checked):hover': {
        color: 'currentColor',
    },

    extend: {
        condition: closable,
        style: {
            '&.ant-tag': {
                flexDirection: hasIcon ? 'row' : 'row-reverse',

                background: theme.colors.brightMagenta,
                borderColor: theme.colors.brightMagenta,

                color: theme.colors.white,
            },
            '&.ant-tag .ant-tag-close-icon': {
                color: theme.colors.white,
            },
        },
    },
});
