import type { TRuleWithTheme } from 'styles/theme';

export const button: TRuleWithTheme = ({ theme }) => ({
    position: 'fixed',
    top: '1rem',
    right: '4.5rem',

    zIndex: theme.zIndexes.updateFormButtons,

    min768: {
        top: '2rem',
        right: '6.5rem',
    },
});
