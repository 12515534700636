import { useFela } from 'react-fela';

import { Heading } from 'modules/ui';

import { container } from './SecretGroupItem.rules';

export const SecretGroupItem = ({ name }: { name: string }) => {
    const { css } = useFela();

    return (
        <div className={css(container)}>
            <Heading elementType="h2">{name.toUpperCase()}</Heading>
        </div>
    );
};
