export const container = () => ({
    max768: {
        '&.ant-btn': {
            display: 'block',
            marginTop: '4rem',
            marginRight: 'auto',
            marginBottom: '4rem',
            marginLeft: 'auto',

            textAlign: 'center',
        },
    },
    min768: {
        '&.ant-btn': {
            position: 'fixed',
            zIndex: '10',
            top: '2rem',
            right: '6rem',
        },
    },
});
