import { useDispatch } from 'react-redux';
import { useForm, useFormState } from 'react-final-form';

import { push } from 'redux-first-history';

import { config } from 'config/config';

import { useModal } from 'modules/modals';

import { NEW_SECRET_MODAL_KEY } from '../constants';
import type { SecretFormValues } from '../types';

const subscription = {
    valid: true,
    submitting: true,
};

export const useNewSecretModal = () => {
    const { modal, updateModal } = useModal(NEW_SECRET_MODAL_KEY);
    const { submit } = useForm();
    const { valid, submitting } = useFormState<SecretFormValues>({
        subscription,
    });

    const dispatch = useDispatch();

    const handleSubmit = () => {
        submit();
    };

    const handleCloseModal = () => {
        updateModal(false);
    };

    const handleRedirect = () => {
        dispatch(push(config.routes.home));
    };

    return {
        open: modal?.visibility,
        valid,
        submitting,
        handleCloseModal,
        handleRedirect,
        handleSubmit,
    };
};
