import { useAppSelector } from 'hooks/useAppSelector';

import { selectActiveSecretGroups, selectSecretGroups } from '../services/selectors';

export const useSecretGroups = () => {
    const groups = useAppSelector(selectSecretGroups);
    const activeSecretGroups = useAppSelector(selectActiveSecretGroups);

    return {
        groups,
        activeSecretGroups,
    };
};
