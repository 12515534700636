import { useFela } from 'react-fela';

import { TextTag } from 'modules/ui';

import { container } from './SecretItemTags.rules';

export interface SecretItemTagsProps {
    tags: string[];
    numberOfDisplayedTags?: number;
}

export const SecretItemTags = ({ tags, numberOfDisplayedTags = 5 }: SecretItemTagsProps) => {
    const { css } = useFela();

    return (
        <div className={css(container)}>
            {[...tags]
                .sort()
                .slice(0, numberOfDisplayedTags)
                .map(tag => (
                    <TextTag key={tag} elementType="div">
                        {tag}
                    </TextTag>
                ))}
        </div>
    );
};
