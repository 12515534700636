import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    position: 'relative',

    display: 'flex',
    flexDirection: 'column',

    overflow: 'auto',

    height: '100vh',

    backgroundColor: theme.colors.white,
});
