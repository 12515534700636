import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',

    position: 'relative',

    width: '100%',

    backgroundColor: theme.colors.white,

    min1024: {
        overflow: 'auto',
        flexShrink: 0,

        width: '50%',

        borderRightWidth: 1,
        borderRightStyle: 'solid',
        borderRightColor: theme.colors.lightGrey,
    },

    min1440: {
        flexDirection: 'row',

        width: `calc(50% + ${theme.widths.menu / 2}px)`,
    },
});
