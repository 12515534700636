import type { Theme, TRuleWithTheme } from 'styles/theme';

export enum TextSize {
    REGULAR = 'regular',
    MEDIUM = 'medium',
    SMALL = 'small',
}

const SIZE_STYLE = {
    [TextSize.REGULAR]: {
        fontSize: '1rem',
        lineHeight: '1.5rem',
        letterSpacing: '0.04375em',
    },
    [TextSize.MEDIUM]: {
        fontSize: '0.875rem', // 14px
        lineHeight: '1.5rem', // 24px
        letterSpacing: '0.05em', // 0.7px
    },
    [TextSize.SMALL]: {
        fontSize: '.875rem', // 14px
        lineHeight: '1.43rem', // ~ 20px
        letterSpacing: '0.0143em', // ~ 0.2px
    },
};

export const container: TRuleWithTheme<{
    size?: `${TextSize}`;
    color?: keyof Theme;
    preserveNewLines: boolean;
    bold?: boolean;
}> = ({ theme, size, color, bold, preserveNewLines }) => ({
    ...SIZE_STYLE[size],
    color: theme.colors[color] || theme.colors.black,
    ...(bold
        ? {
              fontWeight: 'bold',
          }
        : undefined),
    ...(preserveNewLines && {
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
    }),
});
