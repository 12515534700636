import { useField, useFormState } from 'react-final-form';
import { FormattedMessage } from 'react-intl';

import { FormItem, InfoLabel, Label, TextInput } from 'modules/forms';

import { Fields } from '../../../constants';
import type { SecretFormValues } from '../../../types';
import { SecretTOTP } from '../../SecretTOTP';
import { TOTPFromQRCodeButton, isTOTPError } from '../../TOTPFromQRCodeButton';
import type { TextFieldProps } from '../TextField';

const name = Fields.TOTP;

export const TOTPField = ({ onChange }: Pick<TextFieldProps, 'onChange'>) => {
    const { input, meta } = useField<string>(name, {});

    const { values } = useFormState<SecretFormValues>({
        subscription: {
            values: true,
        },
    });

    const { value, onFocus, onBlur } = input;

    return (
        <FormItem<string>
            htmlFor={name}
            label={
                <>
                    <Label>
                        <FormattedMessage id="secret.TOTP.label" />
                    </Label>
                    <InfoLabel>
                        {values.hasTOTP ? (
                            <FormattedMessage id="secret.TOTP.help.hasTOTP" />
                        ) : (
                            <FormattedMessage id="secret.TOTP.help" />
                        )}
                    </InfoLabel>
                </>
            }
            meta={meta}
        >
            {values.hasTOTP ? (
                <SecretTOTP
                    onRemove={() => {
                        input.onChange(null);
                        onChange?.();
                    }}
                />
            ) : (
                <>
                    <TextInput
                        data-testid="totp-input"
                        id={name}
                        value={isTOTPError(value) ? null : value}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        disabled={meta.submitting}
                        suffix={
                            <TOTPFromQRCodeButton
                                onChange={qrCodeContent => {
                                    input.onChange({ target: { value: qrCodeContent } });
                                    onChange?.();
                                }}
                            />
                        }
                        onChange={e => {
                            input.onChange(e);
                            onChange?.();
                        }}
                    />
                </>
            )}
        </FormItem>
    );
};
