import type { TRuleWithTheme } from 'styles/theme';

import type { CloseButtonProps } from './CloseButton';

export const style: TRuleWithTheme<Pick<CloseButtonProps, 'withBackground' | 'sticky'>> = ({
    withBackground,
    sticky,
    theme,
}) => ({
    position: sticky ? 'fixed' : 'absolute',
    top: '1.5rem',
    right: '1.5rem',
    zIndex: theme.zIndexes.updateFormButtons,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    width: '1.5rem',
    height: '1.5rem',

    padding: 0,
    border: 'none',
    background: 'none',
    cursor: 'pointer',

    backgroundColor: withBackground ? theme.colors.lightGrey3 : 'unset',
    transition: 'background-color 0.2s ease',

    min768: {
        top: '2.5rem',
        right: '2.5rem',
    },
});
