import type { FunctionComponent, SVGProps } from 'react';
import { useFela } from 'react-fela';

import type theme from 'styles/theme';

import { Add } from './Add';
import { Burger } from './Burger';
import { Caret } from './Caret';
import { Close } from './Close';
import { Copy } from './Copy';
import { CopyBig } from './CopyBig';
import { Delete } from './Delete';
import { Download } from './Download';
import { Dropdown } from './Dropdown';
import { Edit } from './Edit';
import { Export } from './Export';
import { Extension } from './Extension';
import { Eye } from './Eye';
import { EyeClose } from './EyeClose';
import { FAQ } from './FAQ';
import { GroupVisible } from './GroupVisible';
import { Guide } from './Guide';
import { Hash } from './Hash';
import { ImportSmall } from './ImportSmall';
import { Link } from './Link';
import { Lock } from './Lock';
import { Logout } from './Logout';
import { New } from './New';
import { News } from './News';
import { NoReport } from './NoReport';
import { Password } from './Password';
import { Reloading } from './Reloading';
import { Revert } from './Revert';
import { Search } from './Search';
import { SecurityAudit } from './SecurityAudit';
import { Settings } from './Settings';
import { Share } from './Share';
import { Spinner } from './Spinner';
import { Trash } from './Trash';
import { Url } from './Url';
import { User } from './User';
import { Warning } from './Warning';
import { WarningInverse } from './WarningInverse';
import { WarningTriangle } from './WarningTriangle';
import { X } from './X';

import { Admin } from './Admin';
import { Chat } from './Chat';
import { Group } from './Group';
import { Info } from './Info';
import { StatusAlert } from './StatusAlert';
import { StatusBolt } from './StatusBolt';
import { StatusNoPass } from './StatusNoPass';
import { StatusOk } from './StatusOk';
import { StatusWarning } from './StatusWarning';
import { TOTP } from './TOTP';

import { container, IconType } from './Icon.rules';
import { QRCode } from './QRCode';

const ICONS: Record<
    IconType,
    {
        className: string;
        src: FunctionComponent<SVGProps<SVGSVGElement>>;
    }
> = {
    [IconType.MENU]: { className: 'MenuIcon', src: Burger },
    [IconType.CLOSE]: { className: 'CloseIcon', src: Close },
    [IconType.USER]: { className: 'UserIcon', src: User },
    [IconType.LOGOUT]: { className: 'LogoutIcon', src: Logout },
    [IconType.COPY]: { className: 'CopyIcon', src: Copy },
    [IconType.COPY_BIG]: { className: 'CopyBigIcon', src: CopyBig },
    [IconType.ADD]: { className: 'AddIcon', src: Add },
    [IconType.PASSWORD]: { className: 'PasswordIcon', src: Password },
    [IconType.SEARCH]: { className: 'SearchIcon', src: Search },
    [IconType.EYE]: { className: 'EyeIcon', src: Eye },
    [IconType.EYE_CLOSE]: { className: 'EyeCloseIcon', src: EyeClose },
    [IconType.LINK]: { className: 'LinkIcon', src: Link },
    [IconType.EDIT]: { className: 'EditIcon', src: Edit },
    [IconType.SPINNER]: { className: 'SpinnerIcon', src: Spinner },
    [IconType.URL]: { className: 'UrlIcon', src: Url },
    [IconType.RELOADING]: { className: 'ReloadingIcon', src: Reloading },
    [IconType.EXPORT]: { className: 'ExportIcon', src: Export },
    [IconType.IMPORT_SMALL]: { className: 'ImportSmallIcon', src: ImportSmall },
    [IconType.DELETE]: { className: 'DeleteIcon', src: Delete },
    [IconType.SECURITY_AUDIT]: { className: 'SecurityAuditIcon', src: SecurityAudit },
    [IconType.GROUP_VISIBLE]: { className: 'GroupVisibleIcon', src: GroupVisible },
    [IconType.REVERT_CHANGES]: { className: 'RevertChangesIcon', src: Revert },
    [IconType.WARNING]: { className: 'WarningIcon', src: Warning },
    [IconType.WARNING_INVERSE]: { className: 'WarningInverseIcon', src: WarningInverse },
    [IconType.WARNING_TRIANGLE]: { className: 'WarningTriangleIcon', src: WarningTriangle },
    [IconType.EXTENSION]: { className: 'ExtensionIcon', src: Extension },
    [IconType.CARET]: { className: 'CaretIcon', src: Caret },
    [IconType.X]: { className: 'XIcon', src: X },
    [IconType.NEW]: { className: 'NewTagIcon', src: New },
    [IconType.DROPDOWN]: { className: 'DropdownIcon', src: Dropdown },
    [IconType.SETTINGS]: { className: 'SettingsIcon', src: Settings },
    [IconType.LOCK]: { className: 'LockIcon', src: Lock },
    [IconType.STATUS_ALERT]: { className: 'StatusAlertIcon', src: StatusAlert },
    [IconType.STATUS_WARNING]: { className: 'StatusWarningIcon', src: StatusWarning },
    [IconType.STATUS_OK]: { className: 'StatusOkIcon', src: StatusOk },
    [IconType.STATUS_BOLT]: {
        className: 'StatusBoltIcon',
        src: StatusBolt,
    },
    [IconType.STATUS_NO_PASS]: { className: 'NotSecurityLevelIcon', src: StatusNoPass },
    [IconType.TRASH]: { className: 'TrashIcon', src: Trash },
    [IconType.DOWNLOAD]: { className: 'DownloadIcon', src: Download },
    [IconType.SHARE]: { className: 'ShareIcon', src: Share },
    [IconType.FAQ]: { className: 'FAQIcon', src: FAQ },
    [IconType.NEWS]: { className: 'NewsIcon', src: News },
    [IconType.GUIDE]: { className: 'GuideIcon', src: Guide },
    [IconType.NO_REPORT]: { className: 'NoReportIcon', src: NoReport },
    [IconType.TOTP]: { className: 'TOTPIcon', src: TOTP },
    [IconType.CHAT]: { className: 'ChatIcon', src: Chat },
    [IconType.INFO]: { className: 'InfoIcon', src: Info },
    [IconType.GROUP]: { className: 'GroupIcon', src: Group },
    [IconType.ADMIN]: { className: 'AdminIcon', src: Admin },
    [IconType.HASH]: { className: 'HashIcon', src: Hash },
    [IconType.QR_CODE]: { className: 'QRCodeIcon', src: QRCode },
};

export { IconType };

export interface IconProps
    extends Pick<SVGProps<SVGSVGElement>, 'onClick' | 'width' | 'height' | 'aria-label' | 'className'> {
    type: `${IconType}`;
    color?: keyof typeof theme.colors | 'inherit';
    customStyle?: typeof container;
}

export const Icon = ({
    type,
    color,
    onClick,
    width,
    height,
    'aria-label': label,
    customStyle,
    className,
}: IconProps) => {
    const { css } = useFela({
        color,
    });

    const { src: CurrentIcon, className: iconClassName } = ICONS[type];

    const props: SVGProps<SVGSVGElement> = {
        onClick,
        className: `Icon ${iconClassName} ${className} ${css(container, customStyle)}`,
        'aria-label': label ?? `icon: ${type}`,
    };

    if (width) {
        props.width = width;
    }
    if (height) {
        props.height = height;
    }

    return <CurrentIcon {...props} />;
};
