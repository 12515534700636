import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    marginTop: '1.25rem',
    marginBottom: '1.25rem',

    paddingTop: '0.75rem',
    paddingLeft: '1rem',
    paddingBottom: '0.75rem',
    paddingRight: '1rem',

    borderRadius: '1rem',

    backgroundColor: theme.colors.lightGrey2,
});
export const buttons: TRuleWithTheme = () => ({
    textAlign: 'right',
});
