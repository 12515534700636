import { put } from 'redux-saga/effects';

import { BACKEND_VERSION_HEADER } from './constants';
import { setBackendVersion } from './services/slices';

export function* backendVersionIncerceptor(response: Response) {
    const backendVersion = response.headers.get(BACKEND_VERSION_HEADER);

    if (backendVersion) {
        yield put(setBackendVersion(backendVersion));
    }

    return response;
}
