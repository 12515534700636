import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    position: 'relative',
});

export const indicator: TRuleWithTheme<{
    shareable: boolean;
}> = ({ theme, shareable }) => ({
    position: 'absolute',
    top: 0,
    right: 0,

    display: shareable ? 'block' : 'none',

    width: 12,
    height: 12,

    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.colors.black,
    borderRadius: '50%',

    backgroundColor: theme.colors.green,
});
