import type { TRuleWithTheme } from 'styles/theme';

export const input: TRuleWithTheme = ({ theme }) => ({
    '&.ant-input-affix-wrapper': {
        height: '2.5rem',
        padding: '.6rem 1rem .4rem 1rem',

        color: theme.colors.black,
        backgroundColor: theme.colors.bgGrey,
        borderColor: theme.colors.bgGrey,
        outline: 'none',

        ':hover': {
            backgroundColor: theme.colors.white,
            boxShadow: 'none',
        },
        ':focus-within': {
            backgroundColor: theme.colors.white,
            boxShadow: 'none',
        },

        '& > .ant-input::placeholder': {
            color: theme.colors.brownGrey2,
        },
    },
});

export const clear: TRuleWithTheme = () => ({
    height: '1.5rem',
    padding: 0,
    background: 'none',
    border: 'none',
    cursor: 'pointer',
});
