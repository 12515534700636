import type { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import config from 'config';
import { Label, InfoLabel } from 'modules/forms';
import { createGroupLabel, FormFieldGroups, useGroups } from 'modules/groups';
import type { Group } from 'modules/groups';
import { Link } from 'modules/ui';

import { Fields } from '../../../constants';

import { SelectField } from '../SelectField';
import type { SelectFieldProps } from '../SelectField';

const name = Fields.GROUPS;

export const GroupsField = ({ onChange }: Pick<SelectFieldProps<Group>, 'onChange'>) => {
    const label = (
        <>
            <Label>
                <FormattedMessage id="secret.groups.label" />
            </Label>
            <InfoLabel>
                <FormattedMessage
                    id="secret.groups.help.workspace"
                    values={{
                        a: (parts: ReactNode[]) => (
                            <Link type="native" href={config.links.workspace}>
                                {parts}
                            </Link>
                        ),
                    }}
                />
            </InfoLabel>
            <InfoLabel>
                <FormattedMessage
                    id="secret.help.roles"
                    values={{
                        a: (parts: ReactNode[]) => (
                            <Link type="native" href={config.links.roles}>
                                {parts}
                            </Link>
                        ),
                    }}
                />
            </InfoLabel>
        </>
    );

    const intl = useIntl();

    const placeholder = intl.formatMessage({ id: 'secret.groups.placeholder' });

    const { api, dataWithLocalizedAllUsersGroup, handleLoadMore, handleSearch } = useGroups();

    return (
        <SelectField<Group>
            id={name}
            name={name}
            label={label}
            placeholder={placeholder}
            options={dataWithLocalizedAllUsersGroup
                .filter(group => group.visible !== false)
                .map(group => ({
                    value: group.id,
                    entity: group,
                    key: group.id,
                    label: createGroupLabel(group),
                }))}
            onChange={onChange}
            onSearch={handleSearch}
            loadingMore={api.inProgressNext}
            onLoadMore={handleLoadMore}
            hasMore={Boolean(api.nextPageToken)}
        >
            {(value, actions) => (
                <FormFieldGroups value={value} onUpdate={actions.onUpdate} onRemove={actions.onRemove} />
            )}
        </SelectField>
    );
};
