import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';

import { Button } from 'modules/ui';
import { useModal } from 'modules/modals';

import { REMOVE_SECRET_MODAL_KEY } from '../../constants';

import { style } from './RemoveSecretButton.rules';

export const RemoveSecretButton = () => {
    const { css } = useFela();

    const modal = useModal(REMOVE_SECRET_MODAL_KEY);

    return (
        <div className={css(style)}>
            <Button
                onClick={() => {
                    modal.updateModal(true);
                }}
                htmlType="button"
                danger
            >
                <FormattedMessage id="secret.remove" />
            </Button>
        </div>
    );
};
