import type { TRuleWithTheme } from 'styles/theme';

export const style: TRuleWithTheme = () => ({
    marginTop: '4rem',
    textAlign: 'center',

    min768: {
        marginTop: '2rem',
        textAlign: 'left',
    },
});
