import { useFela } from 'react-fela';
import { useRouteMatch, useHistory, generatePath } from 'react-router-dom';

import { config } from 'config/config';

import type { Secret } from '../../../types';

import { SecretItemInfo } from './SecretIItemInfo';
import { SecretItemTags } from './SecretItemTags';

import { container } from './SecretItem.rules';

const routeConfig = {
    path: config.routes.secret,
    exact: true,
};

export interface SecretItemProps extends Secret {}

export const SecretItem = (props: SecretItemProps) => {
    const match = useRouteMatch<{
        id: string;
    }>(routeConfig);
    const { push } = useHistory();

    const isActive = Boolean(match) && match.params.id === String(props.id);

    const { css } = useFela({ isActive });

    return (
        <div
            className={css(container)}
            role="link"
            tabIndex={0}
            onClick={() => {
                push(
                    generatePath(config.routes.secret, {
                        id: props.id,
                    }),
                );
            }}
            data-testid="secrets-list-item"
        >
            <SecretItemInfo isActive={isActive} {...props} />
            <SecretItemTags tags={props.tags} />
        </div>
    );
};
