import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    '> li': {
        lineHeight: '1.25rem',

        overflowX: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },

    '> li:not(:last-child)': {
        marginBottom: '0.62rem',
    },
});

export const groupButton: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',

    '&.ant-btn-link': {
        textDecoration: 'none',
        fontWeight: 400,
    },
});

export const chevronRightIcon: TRuleWithTheme = ({ theme }) => ({
    rotate: '270deg',
    width: '1rem',
});
