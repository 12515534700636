import { useEffect, useRef, useState } from 'react';
import { useFela } from 'react-fela';

import { Button, Icon } from 'modules/ui';

import {
    ANIMATION_DURATION_MS,
    chevronLeftIcon,
    closeButton,
    container,
    header,
    members,
    AnimationDirection,
} from './NestedGroupOverlay.rules';

import type { GroupMember } from '../../types';
import { GroupMembers } from '../GroupMembers';
import { useNestedGroup } from '../../hooks';

export interface NestedGroupOverlayProps {
    group: GroupMember | null;
    handleClose: () => void;
}

export const NestedGroupOverlay = ({ group, handleClose }: NestedGroupOverlayProps) => {
    const [animationDirection, setAnimationDirection] = useState<AnimationDirection>(AnimationDirection.OUT);
    const { openedNestedGroup, openNestedGroup, closeNestedGroup } = useNestedGroup();
    const { css } = useFela({ animationDirection });
    const timeoutId = useRef<NodeJS.Timeout>(null);

    useEffect(() => {
        if (group !== null) {
            setAnimationDirection(AnimationDirection.IN);
        }
    }, [group]);

    useEffect(
        () => () => {
            if (timeoutId.current) {
                clearTimeout(timeoutId.current);
            }
        },
        [],
    );

    const handleDelayedClose = () => {
        setAnimationDirection(AnimationDirection.OUT);

        timeoutId.current = setTimeout(() => {
            handleClose();
        }, ANIMATION_DURATION_MS);
    };

    if (group === null) {
        return null;
    }

    return (
        <>
            <section className={css(container)}>
                <div className={css(header)}>
                    <Button type="link" onClick={handleDelayedClose} className={css(closeButton)}>
                        <Icon type="dropdown" className={css(chevronLeftIcon)} />
                    </Button>
                    {group.email}
                </div>
                <div className={css(members)}>
                    <GroupMembers id={group.id} openNestedGroup={openNestedGroup} />
                </div>
            </section>
            {/* Nested groups can be many layers deep. Therefore, they are rendered recursively.*/}
            <NestedGroupOverlay group={openedNestedGroup} handleClose={closeNestedGroup} />
        </>
    );
};
