import type { TRuleWithTheme } from 'styles/theme';

import type { SecurityAuditGraphColumnProps } from './SecurityAuditGraphColumn';

export const COLUMN_SPACE = 1;

export const wrapper: TRuleWithTheme<SecurityAuditGraphColumnProps> = ({ columnsCount }) => ({
    width: `${100 / columnsCount}%`,
});

export const columnContainer: TRuleWithTheme<SecurityAuditGraphColumnProps> = ({
    theme,
    isFirstColumn,
    isLastColumn,
}) => ({
    display: 'inline-flex',
    alignItems: 'flex-end',

    width: '100%',
    height: '10.75rem',

    paddingLeft: isFirstColumn ? '1.25rem' : '0.5rem',
    paddingRight: isLastColumn ? '1.25rem' : '0.5rem',

    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.colors.lightGrey3,
});

export const column: TRuleWithTheme<SecurityAuditGraphColumnProps> = ({ theme, color, percentage, maxPercentage }) => ({
    position: 'relative',

    width: '100%',
    height: '0.25rem',
    minHeight: '0.1rem',

    backgroundColor: color ?? theme.colors.brownGrey2,

    transition: 'background 0.3s ease-in-out',

    ...(percentage && {
        height: `${(percentage * 100) / maxPercentage}%`,
    }),
});

export const columnLabel: TRuleWithTheme<SecurityAuditGraphColumnProps> = ({ percentage }) => ({
    position: 'absolute',
    left: 0,
    right: 0,

    zIndex: '99',

    display: percentage ? 'block' : 'none',

    marginTop: '-0.35rem',
    marginLeft: 'auto',
    marginRight: 'auto',

    transform: 'translateY(-100%)',

    textAlign: 'center',
});

export const columnPercentage: TRuleWithTheme = () => ({
    whiteSpace: 'nowrap',

    fontWeight: 400,
});

export const columnTotal: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.brownGrey2,

    max768: {
        display: 'block',
    },
});

export const labelContainer: TRuleWithTheme<SecurityAuditGraphColumnProps> = ({ isFirstColumn, isLastColumn }) => ({
    marginTop: '0.5rem',

    marginLeft: isFirstColumn ? '1rem' : '0.25rem',
    marginRight: isLastColumn ? '1rem' : '0.25rem',

    marginBottom: '1rem',

    textAlign: 'center',
});

export const label: TRuleWithTheme = () => ({
    max768: {
        display: 'flex',

        margin: 'auto',

        lineHeight: 'normal',

        textAlign: 'right',
        writingMode: 'vertical-rl',

        transform: 'rotate(-180deg)',
    },
});
