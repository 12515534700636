import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFela } from 'react-fela';

import { Heading } from 'modules/ui';

import type { MessageKey } from 'modules/localizations/translations/types';

import { container, heading } from './RecordHeading.rules';

export interface RecordHeadingProps {
    name?: string;
    locKey?: MessageKey;
    additional?: ReactNode;
}

export const RecordHeading = ({ name = '', locKey = 'secret.new', additional = null }: RecordHeadingProps) => {
    const { css } = useFela();

    return (
        <div className={css(container)}>
            <Heading customStyle={heading}>{name || <FormattedMessage id={locKey} />}</Heading>
            {additional}
        </div>
    );
};
