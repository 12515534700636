import type { PetrusConfig } from '@ackee/petrus';

import jwtDecode from 'jwt-decode';

import { replace } from 'redux-first-history';

import { put } from 'redux-saga/effects';

import config from 'config';
import { api } from 'config/antonio';
import { ErrorCode, isConfiguredInvalidAdminError } from 'services/utilities/isApiError';
import type { User } from 'types';

import type { GoogleIdToken } from '../../types';
import { getDemoUser } from '../helpers/getDemoUser';
import { isDemoAccessToken } from '../utils';

function* fetchUserInfo(email: string) {
    try {
        const {
            data: [{ isAdmin }],
        } = yield* api.get<User[]>(config.api.contacts, {
            params: { query: email },
        });

        return isAdmin;
    } catch (error) {
        if (isConfiguredInvalidAdminError(error)) {
            yield put(
                replace(
                    `${config.routes.home}?${new URLSearchParams({
                        errorCode: ErrorCode.CONFIGURED_SUPER_ADMIN_IS_NOT_AN_ADMIN,
                    })}`,
                ),
            );
        }

        throw error;
    }
}

export const getAuthUser: PetrusConfig['handlers']['getAuthUser'] = function* getAuthUser(tokens) {
    if (isDemoAccessToken(tokens.accessToken)) {
        const { user } = getDemoUser(tokens.accessToken.userId);

        return user;
    }

    const idToken = jwtDecode<GoogleIdToken>(tokens.accessToken.idToken);
    const isAdmin = yield* fetchUserInfo(idToken.email);

    return {
        id: idToken.sub,
        name: idToken.name,
        email: idToken.email,
        isAdmin,
    };
};
