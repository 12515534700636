import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    display: 'flex',
    flexDirection: 'column-reverse',
    width: '100%',

    min768: {
        alignItems: 'center',
        justifyContent: 'space-between',

        flexDirection: 'row',
    },
});

export const heading: TRuleWithTheme = () => ({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});
