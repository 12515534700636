import { useFela } from 'react-fela';

import { Alert as AntAlert } from 'antd';
import type { AlertProps as AntAlertProps } from 'antd/es/alert';

import * as felaRules from './Alert.rules';

export interface AlertProps extends Omit<AntAlertProps, 'className'> {
    customStyle?: typeof felaRules.container;
}

export const Alert = ({ customStyle, ...props }: AlertProps) => {
    const { css } = useFela();

    return <AntAlert {...props} className={css(felaRules.container, customStyle)} />;
};
