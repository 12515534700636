import { useDispatch } from 'react-redux';

import { useAppSelector } from 'hooks/useAppSelector';

import { copySecret } from '../services/actions';
import { selectCopySecretPropertyApi, selectCopySecret } from '../services/selectors';

import type { CopyableSecretKeys, Secret } from '../types';

export function useCopySecretValue<V>(id: Secret['id'], property: CopyableSecretKeys) {
    const { inProgress, success } = useAppSelector(state =>
        selectCopySecretPropertyApi(state, {
            id,
            property,
        }),
    );

    const value: V = useAppSelector(state =>
        selectCopySecret(state, {
            id,
            property,
        }),
    );

    const dispatch = useDispatch();

    const handleCopy = () => {
        dispatch(copySecret(id, property));
    };

    return {
        inProgress,
        success,
        handleCopy,
        value,
    };
}
