import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    flex: 1,
    overflow: 'hidden',

    '& .Heading': {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },

    '& .Text': {
        display: 'flex',
        alignItems: 'center',

        marginBottom: '.5rem',
    },

    '& .Text > .Icon': {
        flexShrink: '0',
        marginRight: '.5rem',
    },

    '& .Text > span': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },

    '& .Text > .CopyButton': {
        flexShrink: '0',
        marginLeft: '1rem',
    },

    min768: {
        '& .Text > .CopyButton': {
            display: 'none',

            marginLeft: '1rem',
        },

        ':hover > .Text > .CopyButton': {
            display: 'inline-block',
        },
    },
});

export const share: TRuleWithTheme = ({ theme }) => ({
    position: 'relative',

    display: 'inline-flex',
    alignItems: 'center',

    marginLeft: '1rem',

    verticalAlign: 'middle',

    color: theme.colors.black,

    '&::before': {
        content: '""',

        position: 'absolute',
        top: -4,
        right: -8,

        display: 'block',

        width: 8,
        height: 8,

        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.colors.black,
        borderRadius: '50%',

        backgroundColor: theme.colors.green,
    },
});

export const secretsWrapper: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'center',
    gap: '2rem',
});

export const secretFavicon: TRuleWithTheme = ({ theme }) => ({
    height: 24,
    width: 24,
});

export const titleWithFavicon: TRuleWithTheme = () => ({
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    marginBottom: '1rem',
});
