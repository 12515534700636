import { ClosablePage, Loadable } from 'modules/ui';

import { useNewSecretPage } from '../../hooks/useNewSecretPage';

import { RecordHeading } from '../SecretForm/RecordHeading';

import { NewSecretFormProvider } from '../NewSecretFormProvider';
import { NewSecretForm } from '../NewSecretForm';
import { NewSecretFormModal } from '../NewSecretFormModal';
import { MaxSaasSecretsFirewall } from '../MaxSaasSecretsFirewall';

export const NewSecretPage = () => {
    const { showLoader, handleOpenModal } = useNewSecretPage();

    if (showLoader) {
        return (
            <ClosablePage>
                <Loadable showLoader />
            </ClosablePage>
        );
    }

    return (
        <MaxSaasSecretsFirewall>
            <NewSecretFormProvider>
                {({ dirty, submitting }) => (
                    <ClosablePage
                        header={<RecordHeading />}
                        closeButton={{
                            close: dirty || submitting ? handleOpenModal : undefined,
                        }}
                    >
                        <NewSecretForm />
                        <NewSecretFormModal />
                    </ClosablePage>
                )}
            </NewSecretFormProvider>
        </MaxSaasSecretsFirewall>
    );
};
