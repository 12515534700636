import { FormattedMessage } from 'react-intl';

import { InfoLabel } from 'modules/forms';

import { Fields } from '../../../constants';

import { TextField } from '../TextField';
import type { TextFieldProps } from '../TextField';

const name = Fields.WEB;

export const WebField = (props: Pick<TextFieldProps, 'onChange'>) => (
    <TextField
        name={name}
        help={
            <InfoLabel>
                <FormattedMessage id={`secret.${name}.help`} />
            </InfoLabel>
        }
        {...props}
    />
);
