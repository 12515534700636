import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';

import { hasReadPermission } from 'utilities/permission';

import { TextTag, Text, CopyButton, Empty, PasswordVisualisation } from 'modules/ui';
import { Groups } from 'modules/groups';
import { Whitelist } from 'modules/whitelist';

import { useSecretDetail } from '../../hooks/useSecretDetail';

import type { Secret } from '../../types';

import { SecretSection } from '../SecretSection';
import { SecretName } from '../SecretName';
import { SecretFile } from '../SecretFile';
import { SecretUrl } from '../SecretUrl';

import { DetailButtons } from './DetailButtons';
import { CreatorTag } from './CreatorTag';
import { AutofillOnlyInfo } from './AutofillOnlyInfo';
import { TOTP } from './TOTP';

import * as felaRules from './SecretDetail.rules';

export interface SecretDetailProps {
    id: Secret['id'];
}

export const SecretDetail = ({ id }: SecretDetailProps) => {
    const secret = useSecretDetail(id);

    if (!secret) {
        return <Empty text={<FormattedMessage id="secret.detail.empty" />} />;
    }

    const {
        name,
        tags,
        userPermissions,
        createdBy,
        web,
        username,
        password,
        note,
        groups,
        whitelistUsers,
        file,
        share,
        hasTOTP,
    } = secret;

    const hasRead = hasReadPermission(userPermissions);

    return (
        <>
            <SecretSection>
                <SecretName name={name} customStyle={felaRules.heading} />
                {!isEmpty(tags) ? tags.map(tag => <TextTag key={tag}>{tag}</TextTag>) : null}
                <DetailButtons id={id} share={share} userPermissions={userPermissions} />
                {createdBy ? <CreatorTag creator={createdBy} /> : null}
            </SecretSection>
            {web ? (
                <SecretSection heading={<FormattedMessage id="secret.web" />}>
                    <SecretUrl web={web} />
                </SecretSection>
            ) : null}
            {username ? (
                <SecretSection heading={<FormattedMessage id="secret.username" />}>
                    <CopyButton key={id} text={username} />
                </SecretSection>
            ) : null}
            {password ? (
                <SecretSection heading={<FormattedMessage id="secret.password" />}>
                    <CopyButton disabled={!hasRead} type="secret" key={id} text={password}>
                        <PasswordVisualisation password={password} />
                    </CopyButton>
                </SecretSection>
            ) : null}
            {hasTOTP ? (
                <SecretSection heading={<FormattedMessage id="secret.totp" />}>
                    <TOTP id={id} />
                </SecretSection>
            ) : null}
            {file && hasRead ? (
                <SecretSection heading={<FormattedMessage id="secret.file" />}>
                    <SecretFile file={file} />
                </SecretSection>
            ) : null}
            {note && hasRead ? (
                <SecretSection heading={<FormattedMessage id="secret.note" />}>
                    <Text preserveNewLines>{note}</Text>
                </SecretSection>
            ) : null}
            {!isEmpty(groups) ? (
                <SecretSection heading={<FormattedMessage id="secret.groups" />}>
                    <Groups groups={groups} />
                </SecretSection>
            ) : null}
            {!isEmpty(whitelistUsers) ? (
                <SecretSection heading={<FormattedMessage id="secret.whitelistUsers" />}>
                    <Whitelist key={id} whitelist={whitelistUsers} />
                </SecretSection>
            ) : null}
            {hasRead ? null : <AutofillOnlyInfo />}
        </>
    );
};
