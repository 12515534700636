import type { TRuleWithTheme } from 'styles/theme';

export const link: TRuleWithTheme = () => ({
    '& .LinkIcon': {
        display: 'inline-block',

        marginLeft: '1rem',
        marginTop: '-0.25rem',

        verticalAlign: 'middle',
    },

    min768: {
        '& .LinkIcon': {
            display: 'none',
        },

        '&[target="_blank"]:hover .LinkIcon': {
            display: 'inline-block',
        },
    },
});
