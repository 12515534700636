import type { TRuleWithTheme } from 'styles/theme';

export const overlay: TRuleWithTheme = ({ theme }) => ({
    position: 'absolute',

    top: 0,
    left: 0,
    zIndex: 999,

    width: '100%',
    height: '100%',

    backgroundColor: theme.colors.lightGrey,
    opacity: 0.7,
});

export const buttons: TRuleWithTheme = () => ({
    display: 'flex',
    flexWrap: 'wrap',

    gap: '1.5rem',
});

export const icon: TRuleWithTheme = () => ({
    marginLeft: 0,
    verticalAlign: 'bottom',
});

export const emphasizedText: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.brightMagenta,
});

export const note: TRuleWithTheme = ({ theme }) => ({
    color: theme.colors.foregroundsTertiary,
    fontStyle: 'italic',
    marginTop: '0.75rem',
});
