import type { ReactNode } from 'react';
import { useFela } from 'react-fela';

import { Icon, IconType, Text, Link } from 'modules/ui';
import type { IconProps, ExternalLinkProps, InternalLinkProps } from 'modules/ui';

import * as felaRules from './MenuItem.rules';

export interface MenuItemProps {
    icon?: `${IconType}`;
    informationIcon?: Pick<IconProps, 'color' | 'type'>;
    link?: ExternalLinkProps | InternalLinkProps;
    children: ReactNode;
}

export const MenuItem = ({ icon, informationIcon, link, children }: MenuItemProps) => {
    const { css } = useFela();

    const content = (
        <>
            {icon ? <Icon type={icon} customStyle={felaRules.icon} /> : null}
            <span className={css(felaRules.children)}>
                {children}
                {informationIcon ? (
                    <Icon {...informationIcon} width={12} height={12} customStyle={felaRules.linkIcon} />
                ) : null}
            </span>
        </>
    );

    if (link) {
        if (link.type === 'router') {
            return (
                <Link {...link} size="regular" color="black" customStyle={felaRules.link} activeClassName="active">
                    {content}
                </Link>
            );
        }
        return (
            <Link {...link} size="regular" color="black" customStyle={felaRules.link}>
                {content}
            </Link>
        );
    }

    return (
        <Text size="medium" customStyle={felaRules.text} elementType="div">
            {content}
        </Text>
    );
};
