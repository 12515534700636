import type { ReactNode } from 'react';
import { useFela } from 'react-fela';

import { useSubscriptionAlerts } from '../../hooks/useSubscriptionAlerts';

import * as felaRules from './SubscriptionFirewall.rules';
import { CanceledSubscriptionAlert } from './CanceledSubscriptionAlert';
import { NotLinkedAlert } from './NotLinkedAlert';

export interface SubscriptionFirewallProps {
    type: 'overlay' | 'alert';
    children: ReactNode;
}

export const SubscriptionFirewall = ({ type, children }: SubscriptionFirewallProps) => {
    const { canceledSubscriptionAlertVisible, notLinkedAlertVisible } = useSubscriptionAlerts();

    const { css } = useFela();

    if (!canceledSubscriptionAlertVisible && !notLinkedAlertVisible) {
        return <>{children}</>;
    }

    if (type === 'overlay') {
        return (
            <>
                <div className={css(felaRules.overlay)} />
                {children}
            </>
        );
    }

    if (canceledSubscriptionAlertVisible) {
        return <CanceledSubscriptionAlert />;
    }

    if (notLinkedAlertVisible) {
        return <NotLinkedAlert />;
    }
};
