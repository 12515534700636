import type { AnchorHTMLAttributes } from 'react';
import { useFela } from 'react-fela';
import { NavLink as RouterLink } from 'react-router-dom';
import type { NavLinkProps as RouteLinkProps } from 'react-router-dom';

import type { Theme } from 'styles/theme';

import { container, LinkType, LinkSize } from './Link.rules';

interface CommonProps {
    size?: `${LinkSize}`;
    color?: keyof Theme['colors'];
    customStyle?: typeof container;
}

export interface InternalLinkProps extends Omit<RouteLinkProps, 'color'>, CommonProps {
    type: 'router';
}

export interface ExternalLinkProps extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'color'>, CommonProps {
    type: 'native';
}

export const Link = (props: InternalLinkProps | ExternalLinkProps) => {
    const { css } = useFela({
        size: props.size,
        color: props.color,
    });

    if (props.type === LinkType.ROUTER) {
        const { customStyle, className, type, size, color, ...rest } = props;
        return <RouterLink className={`Link ${css(container, customStyle)}`} {...rest} />;
    }

    const { customStyle, className, type, size, color, children, ...rest } = props;
    return (
        <a className={`Link ${css(container, customStyle)}`} target="_blank" rel="noopener noreferrer" {...rest}>
            {children}
        </a>
    );
};
