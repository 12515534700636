import type { ReactNode } from 'react';
import { useFela } from 'react-fela';

import { Checkbox as AntdCheckbox } from 'antd';
import type { CheckboxGroupProps as AntCheckboxGroupProps } from 'antd/es/checkbox';

import * as felaRules from './CheckboxGroup.rules';

export interface CheckboxGroupProps extends AntCheckboxGroupProps {
    children: ReactNode;
}

export function CheckboxGroup(props: CheckboxGroupProps) {
    const { css } = useFela();
    return <AntdCheckbox.Group {...props} className={css(felaRules.container)} />;
}
