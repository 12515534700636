import { useFela } from 'react-fela';
import { FormattedMessage, useIntl } from 'react-intl';
import useToggle from 'react-use-toggle';

import { ConfirmationModal } from 'modules/modals';
import { Heading, Icon, IconButton, Text } from 'modules/ui';

import { TOTP_VALUE_PLACEHOLDER } from '../../constants';

import * as felaRules from './SecretTOTP.rules';

export interface SecretTOTPProps {
    onRemove: () => void;
}

export const SecretTOTP = ({ onRemove }: SecretTOTPProps) => {
    const intl = useIntl();
    const { css } = useFela();
    const [open, toggle] = useToggle();

    return (
        <div className={css(felaRules.container)} data-testid="totp-filled">
            <Text customStyle={felaRules.totp}>{TOTP_VALUE_PLACEHOLDER}</Text>
            <IconButton
                type="ghost"
                aria-label={intl.formatMessage({
                    id: 'remove.TOTP',
                })}
                icon={{
                    type: 'trash',
                    color: 'brightMagenta',
                }}
                onClick={toggle}
            />
            <ConfirmationModal
                open={open}
                icon={null}
                width={474}
                okButton={{
                    onClick: () => {
                        onRemove();
                        toggle();
                    },
                    children: <FormattedMessage id="secret.modal.removeTOTP.ok" />,
                }}
                cancelButton={{
                    onClick: toggle,
                    children: <FormattedMessage id="secret.modal.removeTOTP.cancel" />,
                }}
                closable={false}
            >
                <Heading customStyle={felaRules.title} color="black">
                    <Icon type="trash" color="alertsError" />
                    <FormattedMessage id="secret.modal.removeTOTP.title" />
                </Heading>
                <Text size="small" customStyle={felaRules.text} color="brownGrey">
                    <FormattedMessage id="secret.modal.removeTOTP.text" />
                </Text>
            </ConfirmationModal>
        </div>
    );
};
