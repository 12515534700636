import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';

import { permissionToRole, permissionByRole } from 'utilities/permission';

import { Button } from 'modules/ui';
import { RoleDropdown } from 'modules/forms';

import type { Group } from '../../types';

import { AllUsersGroup } from './AllUsersGroup';
import { GroupDetailButton } from '../GroupDetailButton';
import { ALL_USERS_GROUP_ID } from '../../constants';

import { container } from './GroupItem.rules';

export interface GroupItemProps extends Group {
    onRemove?: (id: Group['id']) => void;
    onUpdate?: (item: Group) => void;
    editable?: boolean;
}

export const GroupItem = ({ id, name, email, accessPermissions, editable, onUpdate, onRemove }: GroupItemProps) => {
    const { css } = useFela();

    const accessRole = permissionToRole(accessPermissions);

    const isAllUsersGroup = id === ALL_USERS_GROUP_ID;

    return (
        <li className={css(container)}>
            {!editable && (isAllUsersGroup ? <AllUsersGroup /> : name)}
            <GroupDetailButton id={id} name={editable ? name : undefined} />
            {editable && (
                <>
                    <RoleDropdown
                        value={accessRole}
                        onChange={newRole => {
                            onUpdate({
                                id,
                                name,
                                email,
                                accessPermissions: permissionByRole[newRole],
                            });
                        }}
                    />
                    <Button
                        type="link"
                        size="small"
                        color="brightMagenta"
                        onClick={() => {
                            onRemove(id);
                        }}
                    >
                        <FormattedMessage id="remove" />
                    </Button>
                </>
            )}
        </li>
    );
};
