import type { ReactNode } from 'react';
import { useFela } from 'react-fela';

import { Icon } from 'modules/ui';

import * as felaRules from './InfoLabel.rules';

export interface InfoLabelProps {
    children: ReactNode;
    customStyle?: typeof felaRules.message;
}

export const InfoLabel = ({ children, customStyle }: InfoLabelProps) => {
    const { css } = useFela();

    return (
        <span className={css(felaRules.message, customStyle)}>
            <Icon type="info" width={16} height={16} customStyle={felaRules.icon} />
            <span>{children}</span>
        </span>
    );
};
