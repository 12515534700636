import { Redirect, Route, Switch } from 'react-router-dom';

import { config } from 'config/config';

import { Layout, MainSection, UpgradeNotice } from 'modules/layout';
import {
    SecurityAuditSecretsPage,
    SecretsPage,
    SecretDetailPage,
    NewSecretPage,
    UpdateSecretPage,
} from 'modules/secrets';
import { ImportExportPage } from 'modules/import-export';
import { ChangelogPage } from 'modules/changelog';
import { SettingsPage, SubscriptionFirewall } from 'modules/settings';

import { Sidebar } from '../Sidebar';

export const ResponsiveApplication = () => (
    <Layout>
        <MainSection>
            <SubscriptionFirewall type="alert">
                <Switch>
                    <Route
                        exact
                        path={[
                            config.routes.updateSecret,
                            config.routes.securityAuditUpdateSecret,
                            config.routes.securityAuditLevelUpdateSecret,
                            config.routes.securityAuditAgeUpdateSecret,
                            config.routes.securityAuditLeaksUpdateSecret,
                        ]}
                        render={() => <UpdateSecretPage />}
                    />
                    <Route exact path={config.routes.newSecret} render={() => <NewSecretPage />} />
                    <Route exact path={config.routes.secret} render={() => <SecretDetailPage />} />
                    <Route
                        render={() => (
                            <UpgradeNotice>
                                <Sidebar />
                                <Switch>
                                    <Route
                                        exact
                                        path={config.routes.importExport}
                                        render={() => <ImportExportPage />}
                                    />
                                    <Route exact path={config.routes.changelog} render={() => <ChangelogPage />} />
                                    <Route path={config.routes.settings} render={() => <SettingsPage />} />
                                    <Route
                                        exact
                                        path={[
                                            config.routes.securityAudit,
                                            config.routes.securityAuditLevel,
                                            config.routes.securityAuditAge,
                                            config.routes.securityAuditLeaks,
                                        ]}
                                        render={() => <SecurityAuditSecretsPage />}
                                    />
                                    <Route exact path={config.routes.secrets} render={() => <SecretsPage />} />
                                    <Route exact path={config.googleOAuth.redirectUrl} />
                                    <Redirect to={config.routes.secrets} />
                                </Switch>
                            </UpgradeNotice>
                        )}
                    />
                </Switch>
            </SubscriptionFirewall>
        </MainSection>
    </Layout>
);
