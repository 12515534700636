import type { CSSObject } from 'fela';

import type { Theme, TRuleWithTheme } from 'styles/theme';

export enum LinkType {
    ROUTER = 'router',
    NATIVE = 'native',
}

export enum LinkSize {
    BIG = 'big',
    REGULAR = 'regular',
    SMALL = 'small',
}

export const SIZE_STYLE: {
    [LinkSize.SMALL]: CSSObject;
    [LinkSize.REGULAR]: CSSObject;
    [LinkSize.BIG]: CSSObject;
} = {
    [LinkSize.BIG]: {
        fontSize: '1rem', // 16px
        fontWeight: 500,
        lineHeight: '1.5rem', // 24px
        letterSpacing: '0.05em', // 0.8px
    },
    [LinkSize.REGULAR]: {
        fontSize: '0.875rem', // 14px
        fontWeight: 500,
        lineHeight: '1.5rem', // 24px
        letterSpacing: '0.05em', // 0.7px
    },
    [LinkSize.SMALL]: {
        fontSize: '0.75rem', // 12px
        fontWeight: 500,
        lineHeight: '1.1875rem', // 19px
        letterSpacing: '0.0583em', // ~ 0.7px
    },
};

export const container: TRuleWithTheme<{
    color?: keyof Theme['colors'];
    size: `${LinkSize}`;
}> = ({ theme, size, color }) => ({
    ...SIZE_STYLE[size],

    color: theme.colors[color] || theme.colors.blue,
    textDecoration: 'underline',

    ':hover': {
        textDecoration: 'none',
    },
});
