import { useEffect, useMemo, useState } from 'react';

import { useGroups } from 'modules/groups';

import { useCreateOptionsGroup } from './useCreateOptionsGroup';
import { createFilterOption, createGroupOption, createRecordOption, createTagOption } from '../utilities';
import { SearchSecretAttributeType, SearchOptionType } from '../../../types';
import { useSecretsNameFilter } from '../../../hooks/useSecretsNameFilter';
import { useSecretsAttributesFilter } from '../../../hooks/useSecretsAttributesFilter';
import { useSearchHistory } from '../../../hooks/useSearchHistory';
import { useTagsList } from '../../../modules/tags/hooks/useTagsList';

import type { Option } from '../types';
import { useHandleSearchSelect } from './useHandleSearchSelect';

const DEFAULT_VALUE = '';
const MIN_SEARCH_LENGTH = 3;

export const useSecretsSearch = (locale: string) => {
    const { searchHistory } = useSearchHistory();

    const secretsNameFilter = useSecretsNameFilter();
    const secretsAttributesFilter = useSecretsAttributesFilter();

    const tags = useTagsList();
    const { data: groups, handleSearch: handleGroupsSearch } = useGroups();

    const [searchTerm, setSearchTerm] = useState(secretsNameFilter.filter ?? DEFAULT_VALUE);

    const { createOptionsGroup } = useCreateOptionsGroup(searchTerm, locale);
    const handleSelect = useHandleSearchSelect(setSearchTerm);

    useEffect(() => {
        setSearchTerm(secretsNameFilter.filter);
    }, [secretsNameFilter.filter]);

    useEffect(() => {
        handleGroupsSearch(searchTerm);
    }, [searchTerm, handleGroupsSearch]);

    const options = useMemo(() => {
        if ((searchTerm?.length ?? 0) < MIN_SEARCH_LENGTH) {
            if (searchHistory.length > 0) {
                return [
                    {
                        label: 'secrets.search.history',
                        options: searchHistory.map<Option>(item => {
                            switch (item.type) {
                                case SearchOptionType.TAG: {
                                    return createTagOption(item.value);
                                }
                                case SearchOptionType.FILTER: {
                                    return createFilterOption(item.value);
                                }
                                case SearchOptionType.GROUP: {
                                    return createGroupOption(item.value, item.label);
                                }
                                default: {
                                    return createRecordOption(item.value, item.label);
                                }
                            }
                        }),
                    },
                ];
            }
            return [];
        }

        const currentSearchTermGroup = secretsNameFilter.filter !== searchTerm ? createFilterOption(searchTerm) : null;

        const secretRecordsGroup = createOptionsGroup<(typeof secretsNameFilter.secrets)[number]>({
            inputEntities: secretsNameFilter.secrets,
            createOptionMapFn: ({ id, name }) => createRecordOption(id, name),
            groupLabel: 'secrets.search.records',
            searchBy: ['name', 'username'],
        });

        const tagsGroup = createOptionsGroup<(typeof tags)[number]>({
            inputEntities: tags,
            customFilter: ({ name }) =>
                !secretsAttributesFilter.filter.some(
                    attribute => attribute.type === SearchSecretAttributeType.TAG && attribute.label.includes(name),
                ),
            createOptionMapFn: ({ name }) => createTagOption(name),
            groupLabel: 'secrets.search.tags',
            searchBy: ['name'],
        });

        const groupsGroup = createOptionsGroup<(typeof groups)[number]>({
            inputEntities: groups,
            customFilter: ({ id }) =>
                !secretsAttributesFilter.filter.some(
                    attribute => attribute.type === SearchSecretAttributeType.GROUP && attribute.value === id,
                ),
            createOptionMapFn: ({ id, name }) => createGroupOption(id, name),
            groupLabel: 'secrets.search.groups',
            searchBy: ['name'],
        });

        return [currentSearchTermGroup, secretRecordsGroup, tagsGroup, groupsGroup].filter(Boolean);
    }, [
        searchTerm,
        secretsNameFilter,
        createOptionsGroup,
        tags,
        groups,
        searchHistory,
        secretsAttributesFilter.filter,
    ]);

    return {
        value: searchTerm,
        fulltextSearch: secretsNameFilter.filter,
        filteredSecretsAttributes: secretsAttributesFilter.filter,
        results: secretsNameFilter.count,
        options,
        handleSelect,
        handleSearch: setSearchTerm,
        handleClearFulltext: () => {
            secretsNameFilter.updateFilter(DEFAULT_VALUE);
            setSearchTerm(DEFAULT_VALUE);
        },
        handleClearAttributeFilter: (value: string, type: SearchSecretAttributeType) => {
            secretsAttributesFilter.toggleFilter(value, '', type, false);
        },
    };
};
