import type { TRuleWithTheme } from 'styles/theme';

export const alert: TRuleWithTheme = () => ({
    display: 'flex',
    flexDirection: 'column',

    alignItems: 'flex-start',
    justifyContent: 'center',

    height: '100%',

    padding: '3rem',
});

export const wrapper: TRuleWithTheme = () => ({
    paddingLeft: '1.25rem',

    marginBottom: '3rem',
});

export const title: TRuleWithTheme = () => ({
    marginBottom: '1.25rem',
});

export const text: TRuleWithTheme = () => ({
    marginBottom: '2rem',
    whiteSpace: 'pre-wrap',
});
