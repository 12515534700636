import { useCallback } from 'react';
import { useFela } from 'react-fela';

import { TagManager } from 'services/utilities';

import { container } from './SecretGroupsItem.rules';

export interface SecretGroupsItemProps {
    group: string;
    isActive: boolean;
    handleActiveGroup: (group: string) => void;
    disabled?: boolean;
}

export const SecretGroupsItem = ({ isActive, handleActiveGroup, disabled = false, group }: SecretGroupsItemProps) => {
    const { css } = useFela({ isActive });

    const handleClick = useCallback(() => {
        TagManager.push('used_alphabet_nav');
        handleActiveGroup(group);
    }, [handleActiveGroup, group]);

    return (
        <button disabled={disabled} type="button" className={css(container)} onClick={handleClick}>
            <span>{group}</span>
        </button>
    );
};
