import { FormattedMessage } from 'react-intl';

import { Alert, Spacer } from 'modules/ui';

import { useUpdateSecretForm } from '../../hooks/useUpdateSecretForm';

import { FileField } from '../SecretForm/FileField';
import { GroupsField } from '../SecretForm/GroupsField';
import { NameField } from '../SecretForm/NameField';
import { NoteField } from '../SecretForm/NoteField';
import { PasswordField } from '../SecretForm/PasswordField';
import { RevertChangesButton } from '../SecretForm/RevertChangesButton';
import { TOTPField } from '../SecretForm/TOTPField';
import { TagsField } from '../SecretForm/TagsField';
import { UsernameField } from '../SecretForm/UsernameField';
import { WebField } from '../SecretForm/WebField';
import { WhitelistField } from '../SecretForm/WhitelistField';

export const UpdateSecretForm = () => {
    const { dirty, submitting, submitError, handleRevert, handleSubmit } = useUpdateSecretForm();

    return (
        <div className="ant-form ant-form-vertical">
            {!submitting && dirty && <RevertChangesButton onClick={handleRevert} />}
            {submitError && (
                <Alert
                    message={typeof submitError === 'object' ? <FormattedMessage {...submitError} /> : submitError}
                    type="error"
                />
            )}
            <NameField onChange={handleSubmit} />
            <Spacer space={1.5} />
            <TagsField onChange={handleSubmit} />
            <Spacer space={-1} />
            <WebField onChange={handleSubmit} />
            <UsernameField onChange={handleSubmit} />
            <PasswordField onChange={handleSubmit} />
            <TOTPField onChange={handleSubmit} />
            <FileField onChange={handleSubmit} />
            <NoteField onChange={handleSubmit} />
            <GroupsField onChange={handleSubmit} />
            <WhitelistField onChange={handleSubmit} />
        </div>
    );
};
