import type { ReactNode } from 'react';

import { useFela } from 'react-fela';

import { Label } from 'modules/forms';
import { Text } from 'modules/ui';

import * as felaRules from './SecurityAuditGraphColumn.rules';

export interface SecurityAuditGraphColumnProps {
    total: number;
    percentage: number;
    label: ReactNode;
    color?: string;
    columnsCount: number;
    maxPercentage: number;
    isFirstColumn: boolean;
    isLastColumn: boolean;
}

function roundToTwoDecimals(percentage: number) {
    return Math.round(percentage * 100) / 100;
}

export const SecurityAuditGraphColumn = ({
    percentage,
    total,
    label,
    color,
    columnsCount,
    maxPercentage,
    isFirstColumn,
    isLastColumn,
}: SecurityAuditGraphColumnProps) => {
    const { css } = useFela({ percentage, maxPercentage, color, columnsCount, isFirstColumn, isLastColumn });

    return (
        <div className={css(felaRules.wrapper)}>
            <div className={css(felaRules.columnContainer)}>
                <div className={css(felaRules.column)}>
                    <div className={css(felaRules.columnLabel)}>
                        <Text elementType="div" size="small" color="black" customStyle={felaRules.columnPercentage}>
                            {roundToTwoDecimals(percentage)}%
                            <span className={css(felaRules.columnTotal)}>({total})</span>
                        </Text>
                    </div>
                </div>
            </div>
            <div className={css(felaRules.labelContainer)}>
                <Label customStyle={felaRules.label}>{label}</Label>
            </div>
        </div>
    );
};
