import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = () => ({
    overflow: 'hidden',

    display: 'inline-flex',
    alignItems: 'center',

    padding: '0.5rem',
    margin: '-0.5rem',

    max768: {
        width: '100%',
    },
    min768: {
        maxWidth: '100%',
    },
});

export const button: TRuleWithTheme = () => ({
    '&.ant-btn': {
        max768: {
            width: '100%',

            justifyContent: 'space-between',
        },
        min768: {
            maxWidth: '100%',
        },
    },
});

export const text: TRuleWithTheme = () => ({
    flexShrink: '1',

    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});
