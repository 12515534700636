import type { TRuleWithTheme } from 'styles/theme';

export const wrapper: TRuleWithTheme = () => ({
    max768: {
        position: 'relative',

        marginBottom: '1.5rem',
    },

    min768: {
        display: 'flex',
        alignItems: 'center',

        flexDirection: 'row',
    },
});

export const button: TRuleWithTheme = () => ({
    max768: {
        position: 'absolute',
        top: '4.75rem',
        right: 0,
    },

    min768: {
        position: 'relative',
        top: '-0.75rem',

        marginLeft: '2.5rem',
    },
});

export const textInput: TRuleWithTheme = () => ({
    position: 'relative',
    zIndex: 2,

    '& .ant-input-suffix': {
        paddingRight: '0 !important',
    },

    min768: {
        flex: 1,

        '& svg': {
            cursor: 'pointer',
        },
    },
});

export const formItem: TRuleWithTheme = () => ({
    '&.ant-form-item': {
        marginBottom: 0,
    },

    min768: {
        '&.ant-form-item': {
            marginBottom: 0,
        },
    },
});

export const container: TRuleWithTheme = () => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',

    overflow: 'auto',

    max768: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },

    min768: {
        display: 'flex',
        flex: '1 1 0%',

        marginBottom: '1.5rem',
    },
});
