import { useMemo } from 'react';
import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { Alert } from 'modules/ui';

import { ErrorCode } from 'services/utilities/isApiError';

import * as felaRules from './LoginError.rules';

export const LoginError = () => {
    const { css } = useFela(felaRules);
    const { search } = useLocation();
    const errorCode = useMemo(() => new URLSearchParams(search).get('errorCode'), [search]);

    return (
        errorCode === ErrorCode.CONFIGURED_SUPER_ADMIN_IS_NOT_AN_ADMIN && (
            <Alert
                type="error"
                message={<FormattedMessage id="error.auth.invalid-admin" />}
                rootClassName={css(felaRules.root)}
            />
        )
    );
};
