import type { TRuleWithTheme } from 'styles/theme';

import type { IconProps } from './Icon';

export enum IconType {
    MENU = 'menu',
    CLOSE = 'close',
    USER = 'user',
    LOGOUT = 'logout',
    COPY = 'copy',
    COPY_BIG = 'copy-big',
    ADD = 'add',
    PASSWORD = 'password',
    SEARCH = 'search',
    LINK = 'link',
    EYE = 'eye',
    EYE_CLOSE = 'eye-close',
    EDIT = 'edit',
    SPINNER = 'spinner',
    URL = 'url',
    RELOADING = 'reloading',
    EXPORT = 'export',
    IMPORT_SMALL = 'import-small',
    DELETE = 'delete',
    SECURITY_AUDIT = 'security-audit',
    GROUP_VISIBLE = 'group-visible',
    REVERT_CHANGES = 'revert-changes',
    WARNING = 'warning',
    WARNING_INVERSE = 'warning-inverse',
    WARNING_TRIANGLE = 'warning-triangle',
    EXTENSION = 'extension',
    CARET = 'caret',
    X = 'x',
    SETTINGS = 'settings',
    NEW = 'new',
    DROPDOWN = 'dropdown',
    LOCK = 'lock',
    STATUS_ALERT = 'status-alert',
    STATUS_WARNING = 'status-warning',
    STATUS_OK = 'status-ok',
    STATUS_BOLT = 'status-bolt',
    STATUS_NO_PASS = 'status-no-pass',
    TRASH = 'trash',
    DOWNLOAD = 'download',
    SHARE = 'share',
    FAQ = 'faq',
    NEWS = 'news',
    GUIDE = 'guide',
    NO_REPORT = 'no-report',
    TOTP = 'totp',
    CHAT = 'chat',
    INFO = 'info',
    GROUP = 'group',
    ADMIN = 'admin',
    HASH = 'hash',
    QR_CODE = 'qrcode',
}

export const container: TRuleWithTheme<{
    color: IconProps['color'];
}> = ({ theme, color }) => ({
    color: theme.colors[color] || color,
});
