import { Redirect, Route, Switch } from 'react-router-dom';

import { config } from 'config/config';

import { Layout, MainSection, SideSection, UpgradeNotice } from 'modules/layout';
import { Overlay } from 'modules/ui';
import {
    SecurityAuditSecretsPage,
    SecretsPage,
    SecretDetailPage,
    NewSecretPage,
    UpdateSecretPage,
} from 'modules/secrets';
import { ImportExportPage } from 'modules/import-export';
import { SecurityAuditPage } from 'modules/security-audit';
import { ChangelogPage } from 'modules/changelog';
import { SettingsPage, SubscriptionFirewall } from 'modules/settings';

import { Sidebar } from '../Sidebar';

export const DesktopApplication = () => (
    <Layout>
        <MainSection>
            <UpgradeNotice>
                <SubscriptionFirewall type="overlay">
                    <Sidebar />
                    <Switch>
                        <Route exact path={config.routes.importExport} render={() => <ImportExportPage />} />
                        <Route path={config.routes.settings} render={() => <SettingsPage />} />
                        <Route exact path={config.routes.changelog} render={() => <ChangelogPage />} />
                        <Route
                            path={[
                                config.routes.securityAudit,
                                config.routes.securityAuditAge,
                                config.routes.securityAuditLevel,
                                config.routes.securityAuditLeaks,
                            ]}
                            render={() => <SecurityAuditSecretsPage />}
                        />
                        <Route path={config.routes.secrets} render={() => <SecretsPage />} />
                        <Route exact path={config.googleOAuth.redirectUrl} />
                        <Redirect to={config.routes.secrets} />
                    </Switch>
                    <Route
                        exact
                        path={[
                            config.routes.newSecret,
                            config.routes.updateSecret,
                            config.routes.securityAuditUpdateSecret,
                            config.routes.securityAuditAgeUpdateSecret,
                            config.routes.securityAuditLevelUpdateSecret,
                            config.routes.securityAuditLeaksUpdateSecret,
                        ]}
                        render={() => <Overlay />}
                    />
                </SubscriptionFirewall>
            </UpgradeNotice>
        </MainSection>
        <SideSection>
            <SubscriptionFirewall type="alert">
                <Switch>
                    <Route
                        exact
                        path={[
                            config.routes.updateSecret,
                            config.routes.securityAuditUpdateSecret,
                            config.routes.securityAuditAgeUpdateSecret,
                            config.routes.securityAuditLevelUpdateSecret,
                            config.routes.securityAuditLeaksUpdateSecret,
                        ]}
                        render={() => <UpdateSecretPage />}
                    />
                    <Route exact path={config.routes.newSecret} render={() => <NewSecretPage />} />
                    <Route exact path={config.routes.secret} render={() => <SecretDetailPage />} />
                    <Route
                        exact
                        path={[
                            config.routes.securityAuditLevel,
                            config.routes.securityAuditAge,
                            config.routes.securityAuditLeaks,
                        ]}
                        render={() => <SecurityAuditPage />}
                    />
                </Switch>
            </SubscriptionFirewall>
        </SideSection>
    </Layout>
);
