import type { TRuleWithTheme } from 'styles/theme';

export const message: TRuleWithTheme = ({ theme }) => ({
    display: 'flex',
    aligItems: 'flex-start',

    marginTop: '0.5rem',
    marginBottom: '0.5rem',

    fontSize: '0.75rem',
    lineHeight: '1.33em',
    letterSpacing: '0.01677em',

    color: theme.colors.black,

    ':last-child': {
        marginBottom: '0.75rem',
    },
});

export const icon: TRuleWithTheme = ({ theme }) => ({
    flexShrink: 0,

    marginRight: '0.25rem',

    color: theme.colors.alertsWarning,
});
