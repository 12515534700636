import type { TRuleWithTheme } from 'styles/theme';

export const header: TRuleWithTheme = () => ({
    display: 'flex',

    alignItems: 'center',
    justifyContent: 'space-between',

    marginBottom: '1rem',
});

export const tag: TRuleWithTheme = () => ({
    '&.ant-tag': {
        display: 'flex',

        width: 'fit-content',

        marginBottom: '0.75rem',
    },
});

export const empty: TRuleWithTheme = ({ theme }) => ({
    marginTop: '2.5rem',
    marginBottom: '2.5rem',
});
