import { getAccessToken } from '@ackee/petrus';
import { Antonio } from '@ackee/antonio-core';
import { requestAuthHeaderInterceptor } from '@ackee/antonio-auth';
import { call } from 'redux-saga/effects';

import { IS_DEMO } from 'constants/index';

import { config } from 'config/config';

import type { AccessToken, DemoAccessToken } from 'modules/auth/types';
import { isDemoAccessToken } from 'modules/auth/services/utils';
import { backendVersionIncerceptor } from 'modules/backend-version/utils';
import { totalCountIncerceptor } from 'modules/secrets/utilities/interceptors';

const antonioConfig = {
    baseURL: config.api.base,
};

export const api = new Antonio(antonioConfig);
export const publicApi = new Antonio(antonioConfig);

const versionInterceptor = (request: Request) => {
    request.headers.set('version', config.version);
    return request;
};

api.interceptors.request.use(null, requestAuthHeaderInterceptor);
api.interceptors.request.use(null, versionInterceptor);
api.interceptors.response.use(backendVersionIncerceptor);

api.interceptors.response.use(totalCountIncerceptor);

if (IS_DEMO) {
    function* demoUserIncerceptor(request: Request) {
        // TODO: remove this when Petrus propagates customly typed access token
        const accessToken: AccessToken | DemoAccessToken = yield call(getAccessToken);

        if (isDemoAccessToken(accessToken)) {
            request.headers.set('x-user-email', accessToken.googleUser.email);
        }

        return request;
    }

    api.interceptors.request.use(null, demoUserIncerceptor);
}

publicApi.interceptors.request.use(null, versionInterceptor);
publicApi.interceptors.response.use(backendVersionIncerceptor);
