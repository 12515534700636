import { createElement } from 'react';
import type { ReactNode } from 'react';
import { useFela } from 'react-fela';

import { container } from './TextTag.rules';

export interface TextTagProps {
    children: ReactNode;
    elementType?: keyof JSX.IntrinsicElements;
}

export const TextTag = ({ children, elementType = 'span' }: TextTagProps) => {
    const { css } = useFela();

    return createElement(elementType, { className: `TextTag ${css(container)}` }, `#${children}`);
};
