import { IS_DEVELOPMENT } from 'constants/index';

import { default as packageJson } from '../../package.json';

const { version } = packageJson;

const { REACT_APP_NAME, REACT_APP_API, REACT_APP_CLIENT_ID, REACT_APP_DEMO, REACT_APP_EXTENSION_URL } = process.env;

const demoEnabled = REACT_APP_DEMO && JSON.parse(REACT_APP_DEMO);
const extensionUrl = REACT_APP_EXTENSION_URL ?? '';

const apiVersions = {
    v1: '/v1',
    v2: '/v2',
};

export const config = {
    appName: REACT_APP_NAME,
    devTools: IS_DEVELOPMENT,
    api: {
        base: REACT_APP_API + (demoEnabled ? apiVersions.v1 : apiVersions.v2),
        root: '/',
        oauthCallback: '/oauth/callback',
        oauthRefreshToken: '/oauth/refresh-token',
        secrets: '/secrets',
        secret: '/secrets/:id',
        totp: '/secrets/:id/totp',
        ignoreReport: '/secrets/:id/reportDismiss',
        share: '/secrets/:id/share',
        sharedSecret: '/share/:id',
        groupsQuery: '/groups-query',
        groupMembers: '/groups/:id/members',
        hideGroup: '/groups/:id/hide',
        showGroup: '/groups/:id/show',
        contacts: '/contacts',
        contactsQuery: '/contacts-query',
        demoUsers: '/sandbox-contacts',
        export: '/secret-exports',
        importValidation: '/secret-validations',
        securityAudit: '/password-reports',
        feedback: '/feedback',
        settings: '/settings',
        settingsAdmin: '/settings/admin',
        tag: '/tags/:tag',
    },
    googleOAuth: {
        origin: 'https://accounts.google.com/o/oauth2/v2/auth',
        cliendId: REACT_APP_CLIENT_ID,
        redirectUrl: '/oauth/redirect',
    },
    googleTagManager: {
        trackingCode: 'GTM-NVV37XS',
    },
    sentry: {
        dsn: 'https://d6779e9ca5af48b8b5f27fe633e2932d@o4503936302120960.ingest.sentry.io/4504197311037440',
    },
    routes: {
        home: '/',
        logout: '/logout',
        secrets: '/secrets',
        newSecret: '/secrets/new',
        secret: '/secrets/:id',
        share: '/share/:id',
        updateSecret: '/secrets/:id/update',
        importExport: '/import-export',
        securityAudit: '/security-audit',
        securityAuditUpdateSecret: '/security-audit/:id',
        securityAuditLevel: '/security-audit-level',
        securityAuditLevelUpdateSecret: '/security-audit-level/:id',
        securityAuditAge: '/security-audit-age',
        securityAuditAgeUpdateSecret: '/security-audit-age/:id',
        securityAuditLeaks: '/security-audit-leaks',
        securityAuditLeaksUpdateSecret: '/security-audit-leaks/:id',
        changelog: '/changelog',
        settings: '/settings',
        tagsSettings: '/settings/tags',
        groupsVisibilitySettings: '/settings/groups-visibility',
    },
    links: {
        emergency: 'https://passwd.team/blog/emergency-troubleshooting-guide/',
        roles: 'https://passwd.team/blog/autofill-only-role/',
        faq: 'https://passwd.team/blog/frequently-asked-questions/',
        guide: 'https://passwd.team/blog/advanced-features/',
        whatIsNew: 'https://passwd.team/blog/tag/whats-new/',
        leaks: 'https://passwd.team/blog/password-security-report-and-leaks/',
        import: 'https://passwd.team/blog/records-import/',
        payment: 'https://passwd.team/payment/',
        stripe: process.env.REACT_APP_STRIPE_LINK,
        workspace: 'https://workspace.google.com',
    },
    demo: {
        enable: demoEnabled,
        gdpr: 'https://app.termly.io/document/privacy-policy/bc4c9b64-efcb-4b9d-8475-1f4792282438',
        buyPageUrl: 'https://passwd.team/#pricing',
    },
    extensionPromo: {
        enable: Boolean(extensionUrl),
        url: extensionUrl,
    },
    tidioKey: 'xaap9ovqpyvlrvkeigrakddvogsobtlf',
    serviceWorker: !demoEnabled && !Boolean(window.Cypress),
    publicChangelog: `/CHANGELOG.md?version=${version}`,
    version,
    isLocalhost: window.location.href.includes('localhost'),
} as const;
