import { createSelector } from '@reduxjs/toolkit';

import { selectIdProp } from 'services/selectors';
import type { AppState } from 'types';

import type { CopyableSecretKeys } from '../../types';

import { selectSecretsEntities } from './entities';

export const selectPropertyProp = (_: AppState, { property }: { property: CopyableSecretKeys }) => property;

const selectCopySecrets = createSelector([selectSecretsEntities], entities => entities.copy);

export const selectCopySecret = createSelector(
    [selectCopySecrets, selectIdProp, selectPropertyProp],
    (secrets, id, property) => secrets[id + property],
);
