import { useRef } from 'react';
import { useFela } from 'react-fela';

import { useGeneratePassword } from '../../hooks/useGeneratePassword';

import { Readability, Options } from '../../constants';

import { GeneratePasswordLength } from './GeneratePasswordLength';
import { GeneratePasswordReadability } from './GeneratePasswordReadability';
import { GeneratePasswordOptions } from './GeneratePasswordOptions';
import { GeneratePasswordCloseButton } from './GeneratePasswordCloseButton';
import { GeneratePasswordRevertButton } from './GeneratePasswordRevertButton';

import * as felaRules from './GeneratePasswordWidget.rules';

export interface GeneratePasswordWidgetProps {
    password: string;
    onClose: () => void;
    onSave: (password: string) => void;
}

export const GeneratePasswordWidget = ({ password, onClose, onSave }: GeneratePasswordWidgetProps) => {
    const { data, setData } = useGeneratePassword(onSave);

    const { css } = useFela();

    const passwordRef = useRef(password);

    return (
        <div className={css(felaRules.container)}>
            <GeneratePasswordRevertButton
                onClick={() => {
                    onSave(passwordRef.current);
                    onClose();
                }}
            />
            <GeneratePasswordLength
                onChange={value =>
                    setData(state => ({
                        ...state,
                        length: value,
                    }))
                }
                value={data.length}
            />
            <div className={css(felaRules.options)}>
                <GeneratePasswordReadability
                    value={data.readability}
                    onChange={value => {
                        setData(state => ({
                            ...state,
                            readability: value,
                            options:
                                value === Readability.EASY_TO_SAY
                                    ? [Options.LOWERCASE, Options.UPPERCASE]
                                    : [Options.LOWERCASE, Options.UPPERCASE, Options.SYMBOLS, Options.NUMBERS],
                        }));
                    }}
                />
                <GeneratePasswordOptions
                    value={data.options}
                    readability={data.readability}
                    onChange={value => {
                        setData(state => ({
                            ...state,
                            options: value,
                        }));
                    }}
                />
            </div>
            <GeneratePasswordCloseButton onClick={onClose} />
        </div>
    );
};
