import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => ({
    '&.ant-radio-wrapper': {
        alignItems: 'center',
    },
    '&.ant-radio-wrapper .ant-radio': {
        top: 0,

        height: 20,
        width: 20,
    },
    '&.ant-radio-wrapper .ant-radio .ant-radio-inner': {
        height: 20,
        width: 20,

        borderColor: theme.colors.black,
    },

    '&.ant-radio-wrapper .ant-radio.ant-radio-checked .ant-radio-inner': {
        backgroundColor: theme.colors.white,
    },

    '&.ant-radio-wrapper:hover .ant-radio .ant-radio-inner': {
        borderColor: theme.colors.black,
    },

    '&.ant-radio-wrapper:hover .ant-radio.ant-radio-checked .ant-radio-inner': {
        backgroundColor: theme.colors.white,
    },

    '&.ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after': {
        top: '50%',
        left: '50%',

        width: 20,
        height: 20,
        marginTop: -10,
        marginLeft: -10,

        backgroundColor: theme.colors.brightMagenta,

        transform: 'scale(0.5)',
    },
});
