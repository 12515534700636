import { useForm, useFormState } from 'react-final-form';
import { FormattedMessage } from 'react-intl';

import { Alert, Spacer } from 'modules/ui';

import type { SecretFormValues } from '../../types';

import { FileField } from '../SecretForm/FileField';
import { GroupsField } from '../SecretForm/GroupsField';
import { NameField } from '../SecretForm/NameField';
import { NoteField } from '../SecretForm/NoteField';
import { PasswordField } from '../SecretForm/PasswordField';
import { SubmitButton } from '../SecretForm/SubmitButton';
import { TOTPField } from '../SecretForm/TOTPField';
import { TagsField } from '../SecretForm/TagsField';
import { UsernameField } from '../SecretForm/UsernameField';
import { WebField } from '../SecretForm/WebField';
import { WhitelistField } from '../SecretForm/WhitelistField';

const subscription = {
    submitting: true,
    submitError: true,
    valid: true,
    pristine: true,
};

export const NewSecretForm = () => {
    const { submit } = useForm();
    const { submitting, submitError, valid, pristine } = useFormState<SecretFormValues>({
        subscription,
    });

    return (
        <form
            className="ant-form ant-form-vertical"
            onSubmit={e => {
                e.preventDefault();
                e.stopPropagation();

                submit();
            }}
        >
            {submitError && (
                <Alert
                    message={typeof submitError === 'object' ? <FormattedMessage {...submitError} /> : submitError}
                    type="error"
                />
            )}
            <NameField />
            <Spacer space={1.5} />
            <TagsField />
            <Spacer space={-1} />
            <WebField />
            <UsernameField />
            <PasswordField />
            <TOTPField />
            <FileField />
            <NoteField />
            <GroupsField />
            <WhitelistField />
            <SubmitButton
                type="primary"
                htmlType="submit"
                loading={submitting}
                disabled={pristine || submitting || !valid}
            >
                <FormattedMessage id="secret.add" />
            </SubmitButton>
        </form>
    );
};
