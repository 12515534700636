import type { TRuleWithTheme } from 'styles/theme';

export const form: TRuleWithTheme = () => ({
    position: 'relative',
});

export const input: TRuleWithTheme = () => ({
    position: 'absolute',
    top: 0,
    right: 0,
});

export const description: TRuleWithTheme = () => ({
    marginTop: '0.125rem',
});
