import { useFela } from 'react-fela';
import { useFormState } from 'react-final-form';
import { FormattedMessage } from 'react-intl';

import type { SecretFormValues } from '../../types';

import { RecordHeading } from '../SecretForm/RecordHeading';

import { style } from './UpdateSecretPageHeading.rules';

const subscription = {
    values: true,
    submitting: true,
};

export const UpdateSecretPageHeading = () => {
    const { values, submitting } = useFormState<SecretFormValues>({
        subscription,
    });

    const { css } = useFela();

    return (
        <RecordHeading
            locKey="secret.update"
            name={values.name}
            additional={
                <div className={css(style)}>
                    {submitting && <FormattedMessage tagName="span" id="secret.form.update.status.saving" />}
                </div>
            }
        />
    );
};
