import type { SVGProps } from 'react';

export const SecurityAudit = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
        <path
            fillRule="evenodd"
            d="M2 19v-4a1 1 0 1 0-2 0v4h2zm4 0v-6a1 1 0 1 0-2 0v6h2zm4 0v-4a1 1 0 1 0-2 0v4h2zm4 0v-8a1 1 0 1 0-2 0v8h2zm4 0V9a1 1 0 1 0-2 0v10h2zM17 0h-5v2h2.586L9 7.586 6.707 5.293a.999.999 0 0 0-1.414 0L.004 10.582l1.414 1.414L6 7.414l2.293 2.293a.999.999 0 0 0 1.414 0L16 3.414V6h2V1a1 1 0 0 0-1-1"
        />
    </svg>
);
