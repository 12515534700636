import axios from 'axios';
import { call } from 'redux-saga/effects';
import type { PetrusConfig } from '@ackee/petrus';

import { config } from 'config/config';

export type OauthRefreshTokenResponse = {
    data: {
        accessToken: string;
        expiration: string;
    };
};

export const refreshTokens: PetrusConfig['handlers']['refreshTokens'] = function* refreshTokens(tokens) {
    if (config.demo.enable) {
        throw new Error(
            "Petrus:refreshTokens method shouldn't be called in the demo application. Check the token expiration!",
        );
    }

    const { data }: OauthRefreshTokenResponse = yield call(
        [axios, axios.post],
        config.api.oauthRefreshToken,
        {
            refreshToken: tokens.refreshToken.token,
        },
        {
            baseURL: config.api.base,
            headers: { 'x-islocalhost': config.isLocalhost },
        },
    );

    return {
        accessToken: {
            ...tokens.accessToken,
            token: data.accessToken,
            expiration: new Date(data.expiration).toISOString(),
        },
        refreshToken: tokens.refreshToken,
    };
};
