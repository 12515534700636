import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useAppSelector } from 'hooks/useAppSelector';

import { selectSettingsApi } from 'modules/settings';

import type { Secret } from '../types';

import { readSecret } from '../services/actions';
import { selectReadSecretApi, selectHasSecret, selectHasSecretPermissions } from '../services/selectors';

export const useFetchSecretUpdate = (id: Secret['id']) => {
    const secretApi = useAppSelector(state => selectReadSecretApi(state, id));
    const settingsApi = useAppSelector(selectSettingsApi);
    const hasSecret = useAppSelector(state => selectHasSecret(state, { id }));
    const hasPermissions = useAppSelector(state => selectHasSecretPermissions(state, { id }));

    const showLoader = !secretApi.lastSuccessAt || !settingsApi.lastSuccessAt;

    const dispatch = useDispatch();

    useEffect(() => {
        if (!hasSecret) {
            dispatch(readSecret(id));
        }
    }, [id, hasSecret, dispatch]);

    return {
        showLoader,
        hasSecret,
        hasPermissions,
    };
};
