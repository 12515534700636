import { useAppSelector } from 'hooks/useAppSelector';

import { useModal } from 'modules/modals';

import { selectSettingsApi } from 'modules/settings';

import { selectLoadSecretsApi } from '../services/selectors';

import { NEW_SECRET_MODAL_KEY } from '../constants';

export const useNewSecretPage = () => {
    const { updateModal } = useModal(NEW_SECRET_MODAL_KEY);

    const secretsApi = useAppSelector(selectLoadSecretsApi);
    const settingsApi = useAppSelector(selectSettingsApi);

    return {
        showLoader: !settingsApi.lastSuccessAt || !secretsApi,
        handleOpenModal: () => {
            updateModal(true);
        },
    };
};
