import { FormattedMessage } from 'react-intl';

import { Text, CopyButton, Empty, PasswordVisualisation } from 'modules/ui';

import { useSharedSecretDetail } from '../../hooks/useSharedSecretDetail';

import type { Secret } from '../../types';

import { SecretSection } from '../SecretSection';
import { SecretName } from '../SecretName';
import { SecretFile } from '../SecretFile';
import { SecretUrl } from '../SecretUrl';

export interface SecretDetailProps {
    id: Secret['share']['id'];
}

export const ShareSecretDetail = ({ id }: SecretDetailProps) => {
    const secret = useSharedSecretDetail(id);

    if (!secret) {
        return <Empty data-testid="shared-secret-empty" text={<FormattedMessage id="secret.share.empty" />} />;
    }

    const { name, web, username, password, note, file } = secret;

    return (
        <>
            <SecretSection>
                <SecretName name={name} />
            </SecretSection>
            {web ? (
                <SecretSection heading={<FormattedMessage id="secret.web" />}>
                    <SecretUrl web={web} />
                </SecretSection>
            ) : null}
            {username ? (
                <SecretSection heading={<FormattedMessage id="secret.username" />}>
                    <CopyButton text={username} />
                </SecretSection>
            ) : null}
            {password ? (
                <SecretSection heading={<FormattedMessage id="secret.password" />}>
                    <CopyButton type="secret" text={password}>
                        <PasswordVisualisation password={password} />
                    </CopyButton>
                </SecretSection>
            ) : null}
            {file ? (
                <SecretSection heading={<FormattedMessage id="secret.file" />}>
                    <SecretFile file={file} />
                </SecretSection>
            ) : null}
            {note ? (
                <SecretSection heading={<FormattedMessage id="secret.note" />}>
                    <Text preserveNewLines>{note}</Text>
                </SecretSection>
            ) : null}
        </>
    );
};
