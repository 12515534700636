import { combineRules } from 'fela';

import type { TRuleWithTheme } from 'styles/theme';

export const container: TRuleWithTheme = ({ theme }) => {
    const activeStyle = {
        backgroundColor: theme.colors.white,
        boxShadow: 'none',
    };

    return {
        display: 'flex',
        flexWrap: 'wrap',

        '& .react-select-container': {
            display: 'inline-block',

            width: '12.5rem',

            marginRight: '0.75rem',
            marginBottom: '0.75rem',

            zIndex: 9,
            position: 'relative',

            verticalAlign: 'top',

            '> .react-select__control': {
                height: `1.375rem`,
                minHeight: 'unset',

                fontFamily: theme.fontFamily,
                fontSize: '.875rem',
                lineHeight: 1,
                letterSpacing: '0.0435em',

                borderColor: theme.colors.black,

                '&.react-select__control--is-focused': activeStyle,

                '> .react-select__indicators': {
                    position: 'relative',
                    top: '-1px',

                    '> .react-select__indicator-separator': {
                        display: 'none',
                    },

                    '> .react-select__indicator': {
                        padding: 0,
                        margin: '0 0.5rem',
                    },
                },
                '> .react-select__value-container': {
                    padding: '0 1rem',
                    '> *': {
                        margin: 0,
                    },

                    '> .react-select__input-container': {
                        paddingTop: 0,
                        paddingBottom: 0,
                    },
                },
            },
            '> .react-select__menu': {
                '> .react-select__menu-list': {
                    '> .react-select__option': {
                        color: theme.colors.black,
                    },
                    '> .react-select__option--is-focused': {
                        backgroundColor: theme.colors.lightGrey2,
                    },
                },
            },
        },
    };
};

export const tag: TRuleWithTheme = () => ({
    '&.ant-tag': {
        marginRight: '0.75rem',
        marginBottom: '0.75rem',
    },
});

export const newTag: TRuleWithTheme = combineRules(tag, (({ theme }) => ({
    '&.ant-tag': {
        borderStyle: 'dashed',
        borderColor: theme.colors.lightGrey3,
        color: theme.colors.brownGrey4,
    },
})) as TRuleWithTheme);

export const newIcon: TRuleWithTheme = () => ({
    marginRight: '0.25rem',
});
