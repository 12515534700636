import { Redirect, Route, Switch } from 'react-router-dom';

import { config } from 'config/config';

import { AdminFirewall } from 'modules/auth';

import { SecurityAuditLevelGraph } from '../SecurityAuditLevelGraph';
import { SecurityAuditAgeGraph } from '../SecurityAuditAgeGraph';
import { SecurityAuditLeaksGraph } from '../SecurityAuditLeaksGraph';

export const SecurityAuditPage = () => (
    <AdminFirewall firewall={<Redirect to={config.routes.home} />}>
        <Switch>
            <Route exact path={config.routes.securityAuditAge} render={() => <SecurityAuditAgeGraph />} />
            <Route exact path={config.routes.securityAuditLevel} render={() => <SecurityAuditLevelGraph />} />
            <Route exact path={config.routes.securityAuditLeaks} render={() => <SecurityAuditLeaksGraph />} />
        </Switch>
    </AdminFirewall>
);
