import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Heading, Text } from 'modules/ui';
import { Modal } from 'modules/modals';

import { FeedbackType } from '../../constants';

import { useUserFeedback } from '../../hooks/useUserFeedback';

import { heading, headingSent, text, buttons } from './UserFeedbackPrompt.rules';

export const UserFeedbackPrompt = () => {
    const { modal, updateModal, sendFeedback } = useUserFeedback();

    const [sent, setSent] = useState(false);

    return (
        <Modal
            open={modal?.visibility}
            width={400}
            closable
            onClose={
                sent
                    ? undefined
                    : () => {
                          sendFeedback(FeedbackType.NO_RESPONSE);
                          updateModal(false);
                      }
            }
            okButton={
                sent
                    ? undefined
                    : {
                          onClick: () => {
                              sendFeedback(FeedbackType.SATISFIED);
                              setSent(true);
                          },
                          children: <FormattedMessage id="user-feedback.prompt.ok" />,
                      }
            }
            cancelButton={
                sent
                    ? {
                          onClick: () => {
                              updateModal(false);
                          },
                          children: <FormattedMessage id="user-feedback.prompt.sent.close" />,
                      }
                    : {
                          type: 'primary',
                          onClick: () => {
                              sendFeedback(FeedbackType.UNSATISFIED);
                              setSent(true);
                          },
                          children: <FormattedMessage id="user-feedback.prompt.cancel" />,
                      }
            }
            extend={{
                buttons,
            }}
        >
            <Heading elementType="h2" color="black" customStyle={sent ? headingSent : heading}>
                {sent ? (
                    <FormattedMessage id="user-feedback.prompt.sent.title" />
                ) : (
                    <FormattedMessage id="user-feedback.prompt.title" />
                )}
            </Heading>
            <Text color="brownGrey2" customStyle={text}>
                {sent ? (
                    <FormattedMessage id="user-feedback.prompt.sent.text" />
                ) : (
                    <FormattedMessage id="user-feedback.prompt.text" />
                )}
            </Text>
        </Modal>
    );
};
