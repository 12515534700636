import { useFela } from 'react-fela';
import { FormattedMessage } from 'react-intl';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import type { Theme } from 'styles/theme';

import type { Secret, TOTP } from '../../types';

import { CopySecretValue } from '../CopySecretValue';

import * as felaRules from './CopySecretTOTP.rules';

export interface CopySecretTOTPProps {
    id: Secret['id'];
    disabled: boolean;
}

export const CopySecretTOTP = ({ id, disabled }: CopySecretTOTPProps) => {
    const { theme, css } = useFela<Theme>();

    return (
        <CopySecretValue<TOTP>
            id={id}
            property="TOTP"
            disabled={disabled}
            copied={({ value }) => {
                const nowS = Date.now() / 1000;
                return (
                    <>
                        <span className={css(felaRules.copied)}>
                            <FormattedMessage id="copied" />
                        </span>
                        <CountdownCircleTimer
                            key={value.code}
                            isPlaying={value.validityEnd > nowS}
                            duration={30}
                            initialRemainingTime={value.validityEnd - nowS}
                            strokeLinecap="square"
                            strokeWidth={2}
                            size={12}
                            colors={theme.colors.accentTertiary}
                            trailColor={theme.colors.foregroundsSeparator}
                        />
                    </>
                );
            }}
        />
    );
};
