import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { call, put } from 'redux-saga/effects';
import type { PetrusConfig } from '@ackee/petrus';

import { config } from 'config/config';
import { logger } from 'config/logger';

import { ErrorCode, isApiAxiosError } from 'services/utilities/isApiError';

import { actions as messagesActions } from 'modules/messages';

import type {
    GoogleIdToken,
    DemoSearchParams,
    SearchParams,
    AccessTokenResponse,
    DemoAccessTokenResponse,
} from '../../types';
import { defaultDemoUserId } from '../../config';

import { forwardParamsToExtension } from '../helpers/forwardParamsToExtension';

import { isDemoSearchParams, parseState } from '../utils';
import type { UTMParamsTuple } from '../utils/utm';
import { getUtmParams } from '../utils/utm';

export const errorMessageIdMap = {
    [ErrorCode.FIRST_LOGIN_BY_ADMIN]: 'error.auth.first-login',
    [ErrorCode.MISSING_CLIENT_SECRET]: 'error.auth.client-secret',
    [ErrorCode.ACCOUNT_NOT_IN_WORKSPACE]: 'error.auth.account-not-in-workspace',
};

export type ForceReturnType = {
    accessToken: string;
    expiresIn?: number | string;
    refreshToken?: string;
};

export type OauthCallbackResponse = {
    data: AccessTokenResponse;
};

export const fetchAccessToken: PetrusConfig['oAuth']['fetchAccessToken'] = function* fetchAccessToken(
    params: SearchParams | DemoSearchParams,
) {
    if (isDemoSearchParams(params)) {
        const idToken = jwtDecode<GoogleIdToken>(params.idToken);

        const payload: {
            email: string;
            utmParams?: UTMParamsTuple;
        } = { email: idToken.email };

        const utmParams = getUtmParams();

        if (utmParams) {
            payload.utmParams = utmParams;
        }

        try {
            yield call([axios, axios.post], config.api.demoUsers, payload, {
                baseURL: config.api.base,
            });
        } catch (e) {
            logger.error(e);
        }

        const fetchTokenResponse: DemoAccessTokenResponse = {
            userId: defaultDemoUserId,
            googleUser: {
                name: idToken.name,
                email: idToken.email,
            },
        };
        // TODO: remove this when Petrus supports customizing return type of fetchAccessToken
        return fetchTokenResponse as unknown as ForceReturnType;
    }

    try {
        const state = parseState(params.state);

        if (state.extensionId) {
            yield forwardParamsToExtension(params, state);
        } else {
            const payload: {
                utmParams?: UTMParamsTuple;
            } = {};

            const utmParams = getUtmParams();

            if (utmParams) {
                payload.utmParams = utmParams;
            }

            const authResponse: OauthCallbackResponse = yield call(
                [axios, axios.post],
                config.api.oauthCallback,
                payload,
                {
                    baseURL: config.api.base,
                    params: {
                        code: params.code,
                    },
                    headers: { 'x-islocalhost': config.isLocalhost },
                },
            );

            // TODO: remove this when Petrus supports customizing return type of fetchAccessToken
            return authResponse.data as unknown as ForceReturnType;
        }
    } catch (e) {
        if (isApiAxiosError(e)) {
            const errorMessageId = errorMessageIdMap[e.response.data.errorCode];

            if (errorMessageId) {
                yield put(
                    messagesActions.displayErrorMessage({
                        message: { id: errorMessageId },
                        options: {
                            duration: 20,
                        },
                    }),
                );
            }
        }

        logger.error(e);

        throw e;
    }
};
