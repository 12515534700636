import { createReducer } from '@reduxjs/toolkit';

import { getConnectionStatus } from '../../utils/getConnectionStatus';
import { getConnection } from '../../utils/getConnection';

import { Status } from '../../constants';

import { updateNetworkStatus } from '../actions';

const getOnlineState = () => {
    const connection = getConnection();

    if (connection) {
        return getConnectionStatus(connection);
    }

    return navigator.onLine ? Status.ONLINE : Status.OFFLINE;
};

const initialState: {
    status: Status;
} = {
    status: getOnlineState(),
};

export const reducer = createReducer(initialState, builder => {
    builder.addCase(updateNetworkStatus, (state, action) => {
        state.status = action.payload;
    });
});
